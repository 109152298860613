import React, { useEffect, useRef, useState } from 'react'
import { sendHttpCall } from 'shareService/httpService';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { MenuItem, Select } from '@mui/material';
import MaterialTable from 'material-table';
import { Badge, Box, Button, TextField } from '@material-ui/core';
import ToggleFilter from './components/ToggleFilter';
import JobCard from 'components/JobCard/JobCard';
import { GridOn, ViewAgenda, LinkRounded, FilterList } from '@material-ui/icons';
import NoInterviewAvailable from './components/NoInterviewAvailable';
import ReactModal from 'react-modal';
import ReactSelect from 'react-select';
import { Link as MuiLink } from '@material-ui/icons';
import moment from 'moment';

import Tabs from './constants/Tabs';

import styles from './styles.module.scss';
import Loader from 'components/Loader';
import AiInterviewCardList from './components/AiInterviewCardList';
import { useSelector } from 'react-redux';
import { getCompensationFromIdNew } from './constants/converstion';
import ViewOfferCandidatesDetails from 'components/ViewOfferCandidatesDetails/ViewOfferCandidatesDetails';

const OfferTypeSwitch = (props) => {
    const { offerType, status = Tabs.PENDING } = props.match.params;
    const { search } = props.location;

    const [isOpen, setIsOpen] = useState(false);
    const [offerTypeId, setOfferTypeId] = useState(offerType);
    const [activeTab, setActiveTab] = useState(Tabs.PENDING);
    const [viewMode, setViewMode] = useState('grid');
    const [internal, setInternal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [lastPage, setLastPage] = useState(10);
    const [offerDetails, setOfferDetails] = useState({ limit: 10, page: 1 });
    const [selectedAdminId, setSelectedAdminId] = useState('');
    const [salesAdmins, setSalesAdmins] = useState([]);
    const [searchQueryInfo, setSearchQueryInfo] = useState({ field: null, value: "" });
    const [selectedPeriod, setSelectedPeriod] = useState("All");
    const today = moment(new Date()).format('YYYY-MM-DD');
    const [selectedStartDate, setSelectedStartDate] = useState(today);
    const [selectedEndDate, setSelectedEndDate] = useState(today);
    const [yearlyCompensationData, setYearlyCompensationData] = useState(null);

    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState({});
    const [loading2, setLoading2] = useState(false); // Manage loading state
    const [campaignType, setCampaignType] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignLink, setCampaignLink] = useState('');
    const [campaignLinkWeb, setCampaignLinkWeb] = useState('');
    const [campaignTypes, setCampaignTypes] = useState([]);
    const [error, setError] = useState('');

    const [selectedItem, setSelectedItem] = useState(null);

    const [filterApplied, setFilterApplied] = useState(false);

    const viewOfferCandDetailsRef = useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(search);
    
        const initialSelectedAdminId = params.get('selectedAdminId') || '';
        const initialInternal = params.get('internal') === '1';
        const fieldName = params.get('fieldName') || null;
        const searchTerm = params.get('searchTerm') || null;
        const selectedPeriodVal = params.get("selectedPeriod") || "All";
        const initialStartDate = params.get("startDate") || today;
        const initialEndDate = params.get("endDate") || today;
        const initialCompensation = params.get("compensation") || 0;
    
        setSelectedAdminId(initialSelectedAdminId);
        setInternal(initialInternal);
        setSearchQueryInfo(() => ({ field: fieldName || null, value: fieldName ? searchTerm || null : null }));
        setSelectedPeriod(selectedPeriodVal);
        setSelectedStartDate(initialStartDate);
        setSelectedEndDate(initialEndDate);
        setYearlyCompensationData(initialCompensation ? {label: getCompensationFromIdNew(parseInt(initialCompensation, 0)), value: parseInt(initialCompensation) } : { value: parseInt(initialCompensation) });
    }, [search]);

    
    const handleOpenModal = (rowData) => {
        setSelectedJob(rowData);
        setModalIsOpen(true);
    };

    const openJobPage = (offerId) => {
        window.open(`/dashboard#/offer/${offerId}`);
    };

    const openUserPage = (userId) => {
        window.open(`/dashboard#/account/${userId}`, '_blank');
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    // 2. Grab admin data from Redux
    const salesAdminsData = useSelector((state) => state.adminStaticConfig.data);
    
    // 3. Helper to fill `salesAdmins`
    const getSalesAdmins = () => {
        if (!salesAdminsData || !salesAdminsData.loaded) return;
    
        // Always use 'allAdmins' since showInactiveAdmins remains false
        const adminsKey = 'allAdmins';
        if (!salesAdminsData[adminsKey]) return;
    
        const formattedAdmins = salesAdminsData[adminsKey].map((admin) => ({
            label: `${admin.name} (${admin.email_id})`,
            value: admin.admin_id
        }));
    
        setSalesAdmins(formattedAdmins);
    };
    
    // 4. Call getSalesAdmins once data is loaded
    useEffect(() => {
        if (salesAdminsData?.loaded) {
          getSalesAdmins();
        }
    }, [salesAdminsData]);


    useEffect(() => {
        setActiveTab(() => {
            let tab = Tabs.ACTIVE;
            Object.keys(Tabs).forEach((a) => {
                if(Tabs[a] === status) {
                    tab = Tabs[a];
                }
            })
            return tab;
        })
    }, [status]);

    const abortControllerRef = useRef(null);

    const handleChange = (event, value) => {
        setOfferDetails((prev) => ({ ...prev, page: value }));
    };

    const handleTimeChange = (event) => {
        const period = event.target.value;
        setSelectedPeriod(period);
        const { startDate, endDate } = calculateDateRange(period);
    
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const calculateDateRange = (period) => {
        let endDate = moment();
        let startDate = moment();
      
        switch (period) {
          case "Today":
            break;
          case 'Yesterday':
            startDate = moment().subtract(1, 'days').startOf('day');
            endDate = moment().subtract(1, 'days').endOf('day');
            break;
          case 'This Week (Mon - Today)':
            startDate = moment().startOf('isoWeek'); // Monday
            endDate = moment().endOf('day'); // Today
            break;
          case 'Last Week (Mon - Sun)':
            startDate = moment().subtract(1, 'weeks').startOf('isoWeek'); // Last Monday
            endDate = moment().subtract(1, 'weeks').endOf('isoWeek'); // Last Sunday
            break;
          case 'This Month':
            startDate = moment().startOf('month');
            endDate = moment().endOf('day'); // Current date
            break;
          case 'Last Month':
            startDate = moment().subtract(1, 'months').startOf('month');
            endDate = moment().subtract(1, 'months').endOf('month');
            break;
          case 'Last 3 Months':
            startDate = moment().subtract(3, 'months').startOf('day');
            endDate = moment().endOf('day');
            break;
          case 'Last 6 Months':
            startDate = moment().subtract(6, 'months').startOf('day');
            endDate = moment().endOf('day');
            break;
          case 'Last Year':
            startDate = moment().subtract(1, 'year').startOf('day');
            endDate = moment().endOf('day');
            break;
          case 'Custom Date':
            // Assuming Custom Date allows users to set startDate and endDate via date pickers
            // Implement custom date logic if necessary
            break;
          case 'All':
            default:
                startDate = null;
                endDate = null;
                break;
        }
    
        return {
            startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            endDate: endDate ? endDate.format('YYYY-MM-DD') : null
        };
    };

    useEffect(() => {
        const { limit = 10, page = 1 } = new URLSearchParams(search);
        setOfferDetails({ limit, page });
    }, []);

    const [goToPage, setGoToPage] = useState('');

    const handleGoToPageChange = (event) => {
        setGoToPage(event.target.value);
    };

    
    const handleGoToPage = () => {
        let pageNumber = parseInt(goToPage, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
            // setPage(pageNumber);
            setOfferDetails((prev) => ({ ...prev, page: pageNumber }));
            setGoToPage('');
        } else {
            alert('Please enter a valid page number');
        }
    };

    const toggleDrawer = () => {
        setIsOpen((prev) => !prev);
    }

    const handleViewModeChange = () => {
        setViewMode((prev) => {
            if(prev === "list") {
                setOfferDetails((prev) => ({ ...prev, limit: 10 }));
                return "grid";
            }
            return "list";
        });
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setOfferDetails((prev) => ({ ...prev, page: 1 }))
        // setPagination((prev) => ({ ...prev, currentPage: 1 }));
        props.history.push(`/offer-dashboard/${offerType}/${tab}`)
    };

    const showToast = (msg) => {
        setToastMessage(msg);
    };
    const hideToast = () => {
        setToastMessage('');
    };

    const boostJob = (offerId) => {
        let url = `/jobs/boost_job`;
        sendHttpCall('POST', url, { offerId }, props).then((response) => {
            showToast('Successfully boosted job post.');
        });
    };

    // const getData = async () => {
    //     setLoading(true);
    
    //     sendHttpCall("GET", `offer/getOfferDetails?offerType=${offerType}&status=${status}&page=${offerDetails.page}&limit=${offerDetails.limit}`)
    //         .then((res) => {
    //             setData(res?.data);
    //             setTotalCount(res?.totalCount);
    //             setLastPage(Math.ceil(response.totalCount / 10));
    //         })
    //         .catch((err) => {
    //             console.log('Error fetching data:', err);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    const getData = async () => {
        setLoading(true);
        
        // if (abortControllerRef.current) {
        //     abortControllerRef.current.abort();
        // }
        
        // abortControllerRef.current = new AbortController();
        
        let url = `offer/getOfferDetails?status=${status}&page=${offerDetails.page}&limit=${offerDetails.limit}`;

        const filters = {
            offerTypeId: offerType,
        };

        if(selectedAdminId) {
            filters.adminId = selectedAdminId;
        }

        if(internal) {
            filters.internal = internal ? 1 : 0;
        }

        if(searchQueryInfo.field && searchQueryInfo.value) {
            filters.fieldName = searchQueryInfo.field;
            filters.searchTerm = searchQueryInfo.value;
            // url += `&fieldName=${searchQueryInfo.field}&searchTerm=${searchQueryInfo.value}`
        }
        
        if(yearlyCompensationData && parseInt(yearlyCompensationData.value) !== 0) {
            filters.compensation = yearlyCompensationData.value; 
        }

        if (selectedPeriod && selectedPeriod !== 'All') {
            if (selectedStartDate && selectedEndDate) {
                url += `&startDate=${selectedStartDate}&endDate=${selectedEndDate}`;
            }
        }

        url += `&filters=${JSON.stringify(filters)}`

        setFilterApplied(parseInt(selectedAdminId, 10) !== 0 || selectedPeriod !== "ALL" || !!searchQueryInfo.value);

        try {
            const response = await sendHttpCall(
                "GET", 
                url,
                undefined,
                null
                // { signal: abortControllerRef.current.signal }
            );
            
            setData(response?.data || []);
            if(parseInt(offerType, 10) === 1) {
                setTotalCount(response?.totalCount || 0);
                setLastPage(Math.ceil(response?.totalCount / 10));
            } else {
                setTotalCount(response?.pagination?.totalCount);
                setLastPage(response?.pagination?.totalPages);
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Request was cancelled');
            } else {
                console.error('Error fetching data:', err);
            }
        } finally {
            setLoading(false);
        }
    };

    const toggleAutoRecruiter = async (offerId, activate) => {
        let url = `/jobs/toggle_auto_recruiter`;
        const response = await sendHttpCall(
            'POST',
            url,
            {
                offerId,
                activate
            },
            props
        );
        if (activate) showToast('Successfully turned on autorecruiter.');
        else showToast('Successfully turned off autorecruiter.');
    };

    const approveJob = (offerId, isFreeActive = false) => {
        changeJobStatus(offerId, true, isFreeActive);
    };
    
    const rejectJob = (offerId, reason) => {
        changeJobStatus(offerId, false, false, reason, false);
    };
    const deleteJob = (offerId) => changeJobStatus(offerId, null, null,null, true);

    const changeJobStatus = async (
        offerId,
        approved,
        isFreeActive,
        message,
        deleteJob
    ) => {
        if (loading) return;
    
        if (!deleteJob && !approved && !message) {
            showToast('Cannot reject job without reason.');
            return;
        }
        // console.log("delete", deleteJob)
        // console.log("message", message)
        // console.log("approved", approved)
        setLoading(true);
        let url = `/jobs/update_job_post_status`;
    
        // Prepare the data object
        let data = {
          offerId,
          approved,
          message,
          delete: deleteJob
        };
    
        // Conditionally add isFreeActive if it is true
        if (isFreeActive) {
          data.isFreeActive = true;
        //   console.log('review: free active');
        }
    
        sendHttpCall('POST', url, data, props).then((response) => {
          console.log(response);
          setSelectedJob({});
          setSelectedJobForDelete({});
          if (deleteJob) {
            showToast('Successfully deleted job post.');
          } else {
            showToast(
              approved
                ? 'Successfully approved job post.'
                : 'Successfully rejected job post.'
            );
          }
        //   setRejectReason(null);
        //   setRejectReasonCustom('');
        
        setLoading(false);
        setTimeout(getData, 500);
    });
    await getData();
    };

    const handleSearch = async () => {
        const { startDate, endDate } = calculateDateRange(selectedPeriod);
        
        // Update the state with the new dates
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        
        const params = new URLSearchParams(search);
        if(searchQueryInfo.field && searchQueryInfo.value) {
            params.set("fieldName", searchQueryInfo.field);
            params.set("searchTerm", searchQueryInfo.value);
        } else {
            params.delete("fieldName");
            params.delete("searchTerm");
        }

        if (selectedAdminId && parseInt(selectedAdminId, 10) !== 0) {
            params.set('selectedAdminId', selectedAdminId);
        } else {
            params.delete('selectedAdminId');
        }

        if (selectedPeriod && selectedPeriod !== 'All') {
            params.set("selectedPeriod", selectedPeriod);
        } else {
            params.delete("selectedPeriod");
        }

        if (startDate && endDate) {
            params.set("startDate", startDate);
            params.set("endDate", endDate);
        } else {
            params.delete("startDate");
            params.delete("endDate");
        }

        if(yearlyCompensationData && parseInt(yearlyCompensationData.value, 10) !== 0) {
            params.set("compensation", yearlyCompensationData.value);
        } else {
            params.delete("compensation");
        }

        props.history.push({ search: params.toString() });

        setTotalCount(0);
        setLoading(true);
        setOfferDetails((prev) => ({ ...prev, page: 1 }));
        setIsOpen(false);
        getData();
    }

    useEffect(() => {
        if (toastMessage !== '') {
            setTimeout(hideToast, 2000);
        }
    }, [toastMessage]);

    useEffect(() => {
        setOfferDetails({ page: 1, limit: 10 });

        props.history.push({ search: "" });
    }, [offerType, status]);

    useEffect(() => {
        const params = new URLSearchParams(search);

        if (selectedAdminId && parseInt(selectedAdminId, 10) !== 0) {
            params.set('selectedAdminId', selectedAdminId);
        } else {
            params.delete('selectedAdminId');
        }
    
        if(internal) {
            params.set('internal', '1');
        } else {
            params.delete('internal');
        }

        // Update the URL without reloading the page
        props.history.push({ search: params.toString() });

        setData(() => []);
        setOfferTypeId(offerType);
        setTotalCount(0);
        setLoading(true);
        getData();
    }, [offerType, status, offerDetails.limit, offerDetails.page, internal]);

    useEffect(() => {
        setViewMode('grid');
    }, [offerType, status, internal]);

    useEffect(() => {
        getCampaignTypes();
    }, []);

    const addNewCampaign = (jobOfferId, campaignType, campaignName) => {
        if (loading2 || !campaignName || !campaignType) return;
        if (campaignName.search(/^[a-zA-Z0-9-_]+$/) == -1) {
            alert("Can have only 'A-Z','a-z','_' and '-' in campaign name");
            return;
        }
        setLoading2(true);
        let url = '/marketing_campaign/add_campaign';
        sendHttpCall(
            'POST',
            url,
            {
                marketing_campaign_name: `${campaignType}-${campaignName}`,
                marketing_campaign_redirect_link: 'jobs',
                param_id: jobOfferId,
                mode: 'JOBS'
            },
            props
        )
            .then((response) => {
                if (response.state === 200) {
                    setCampaignLink(response.data.campaignLink);
                    setCampaignLinkWeb(response.data.campaignLinkWeb);
                    setLoading2(false);
                    setError('');
                    showToast('Campaign created successfully');
                } else {
                    setLoading2(false);
                    setError(response.message);
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading2(false);
                setError('Failed to create campaign');
            });
    };

    const getCampaignTypes = () => {
        setLoading2(true);
        let url = '/marketing_campaign/get_types';
        sendHttpCall('GET', url, null, props)
            .then((response) => {
                if (response.state === 200) {
                let options = response.data.map((a) => ({
                    value: a.marketing_campaign_type,
                    label: a.marketing_campaign_type
                }));
                setCampaignTypes(options);
                setLoading2(false);
                }
            })
            .catch((err) => {
                console.error('Error fetching campaign types:', err);
                setLoading2(false);
            });
    };
    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
        showToast('Campaign Link Copied');
    };
    const handleCampaignTypeChange = (selectedOption) => {
        if (selectedOption) {
            setCampaignType(selectedOption.value);
        } else {
            setCampaignType('');
        }
    };

    const handleClear = () => {
        setSelectedPeriod("All");
        setSelectedAdminId(0);
        setSearchQueryInfo({ field: null, value: "" });
        setYearlyCompensationData(null);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.TopHeader}>
                <h3>{offerType == "1" ? "Job Post" : "AI Interviewer"}</h3>
                <div className={styles.TabSwitch}>
                    {Object.values(Tabs).map((tab) => (
                        <div
                            key={tab}
                            onClick={() => handleTabChange(tab)}
                            className={`${activeTab === tab ? styles.active : ''}`}
                        >
                            {tab.replace('_', ' ')}
                        </div>
                    ))}
                </div>
                <div className={styles.filters}>
                    { parseInt(offerTypeId, 10) === 1 && (
                        <div className="view-toggle" style={{ margin: 'auto' }}>
                            <GridOn
                                style={{
                                    cursor: 'pointer',
                                    color: viewMode === 'grid' ? '#297bca' : 'grey'
                                }}
                                onClick={() => handleViewModeChange('grid')}
                            />
                            <ViewAgenda
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 20,
                                    color: viewMode === 'list' ? '#297bca' : 'grey'
                                }}
                                onClick={() => handleViewModeChange('list')}
                            />
                        </div>
                    )}
                    <label for="filter" className={styles.switch} aria-label="Toggle Filter">
                        <input type="checkbox" id="filter" checked={internal} onClick={() => setInternal((prev) => !prev)} />
                        <span>External</span>
                        <span>Internal</span>
                    </label>
                    <div onClick={toggleDrawer} style={{ marginLeft: 'auto' }}>
                    <Badge color="secondary" variant="dot" invisible={!filterApplied}>
                        <FilterList color={filterApplied ? "primary" : "inherit"} />
                    </Badge>
                    </div>
                </div>
            </div>
            { loading ? <Loader /> : parseInt(offerTypeId, 10) === 1 ? (
                <>
                    <div className={styles.jobCardLists}>
                        {viewMode === "grid" ? (data || []).map((item) => (
                            <JobCard
                                status={status}
                                data={item}
                                approveJob={approveJob}
                                rejectJob={rejectJob}
                                // currentPage={offerDetails.page}
                                toggleAutoRecruiter={toggleAutoRecruiter}
                                deleteJob={deleteJob}
                                refreshData={getData}
                                showToast={showToast}
                                boostJob={boostJob}
                            />
                        )) : (
                            <>
                                <div className={styles.listView}>
                                    <MaterialTable
                                        columns={[
                                            {
                                            title: 'S No.',
                                            cellStyle: {
                                                position: 'sticky',
                                                left: 0,
                                                backgroundColor: '#FFF'
                                            },
                                            headerStyle: {
                                                position: 'sticky',
                                                left: 0,
                                                backgroundColor: '#FFF'
                                            },
                                            render: (rowData) =>
                                                (offerDetails.page - 1) * 20 + rowData.tableData.id + 1
                                            },
                                            {
                                            title: 'Title',
                                            field: 'title',
                                            render: (rowData) => {
                                                return (
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#297bca',
                                                    }}
                                                    onClick={() => {
                                                        openJobPage(rowData.offerId);
                                                    }}
                                                >
                                                    {rowData.title}
                                                </div>
                                                );
                                            }
                                            },
                                            { title: 'Company', field: 'userInfo[0].organisation' },
                                            {
                                            title: 'Posted By',
                                            field: 'name',
                                            cellStyle: {
                                                position: 'sticky',
                                                left: 40,
                                                backgroundColor: '#FFF'
                                            },
                        
                                            headerStyle: {
                                                position: 'sticky',
                                                left: 40,
                                                backgroundColor: '#FFF'
                                            },
                                            render: (rowData) => {
                                                return (
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        textTransform: 'capitalize',
                                                        fontFamily: 'Roboto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: '#297bca'
                                                    }}
                                                    onClick={() => {
                                                        openUserPage(rowData.userId);
                                                    }}
                                                >
                                                    {rowData.name}
                                                </div>
                                                );
                                            }
                                            },
                                            { title: 'Location', field: 'location_city' },
                                            {
                                                title: 'Applications',
                                                // field: 'applicationsCount',
                                                render: (rowData) => (
                                                    <div 
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: rowData.applicationsCount > 0 ? "#297bca" : "inherit"
                                                        }}
                                                        onClick={() => {
                                                            setSelectedItem(rowData);
                                                            viewOfferCandDetailsRef.current.fetchApplications(rowData.offerId);
                                                        }}
                                                    >
                                                        {rowData.applicationsCount}
                                                    </div>
                                                ),
                                                cellStyle: {
                                                    width: 50
                                                },
                                                headerStyle: {
                                                    width: 50
                                                }
                                            },
                                            {
                                            title: 'NPS',
                                            field: 'nps_remark',
                                            render: (rowData) => {
                                                console.log(rowData);
                                                return (
                                                <div>
                                                    {parseInt(rowData.nps_remark?.split(':')[1].trim()) ||
                                                    '-'}
                                                </div>
                                                );
                                            }
                                            },
                                            { title: 'Shortlisted', field: 'shortlistedCount' },
                                            { title: 'Interviewed', field: 'interviewedCount' },
                                            { title: 'Approved By', field: 'adminName' },
                                            { title: 'Assigned To', field: 'assignedToAdminName' },
                                            {
                                            title: 'Campaign Link',
                                            field: 'internal_action',
                                            sorting: false,
                                            render: (rowData) => (
                                                <LinkRounded
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleOpenModal(rowData)}
                                                />
                                            )
                                            }
                                        ]}
                                        data={data}
                                        title="Active Job List View"
                                        onChangePage={(newPage) => {
                                            setOfferDetails((prev) => ({ ...prev, page: newPage + 1 }));
                                        }}
                                        onChangeRowsPerPage={(pageSize) => {
                                            setOfferDetails((prev) => ({ limit: pageSize, page: 1 }));
                                        }}
                                        totalCount={totalCount}
                                        page={offerDetails.page - 1}
                                        options={{
                                            search: true,
                                            paging: true,
                                            pageSize: offerDetails.limit,
                                            pageSizeOptions: [20, 50, 100, 250],
                                            sorting: true,
                                            exportButton:
                                            localStorage.getItem('user_type') === 'SALES'
                                                ? false
                                                : true,
                                            actionsColumnIndex: -1,
                                            rowStyle: (rowData) => ({
                                            borderLeft:
                                                rowData.shortlistedCount < 30 ? '5px solid red' : 'none'
                                            })
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : <AiInterviewCardList data={data} deleteJob={deleteJob} />
            }
            
            {viewMode === 'grid' && (
                <div className="active_jobs__pagination">
                    <div className="active_jobs__pagination-count">
                        Total Count : {totalCount}
                    </div>
                    <Pagination
                        size="large"
                        color="#297bca"
                        count={lastPage}
                        page={offerDetails.page}
                        onChange={handleChange}
                        style={{ marginTop: 'auto' }}
                    />
                    <Box display="flex" alignItems="center" gap={1}>
                        <TextField
                            size="small"
                            label="Go to page"
                            variant="outlined"
                            value={goToPage}
                            onChange={handleGoToPageChange}
                            inputProps={{ type: 'number', min: 1, max: lastPage }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleGoToPage();
                                }
                            }}
                            style={{ width: '120px' }}
                        />
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#297bca', color: "white" }}
                            onClick={handleGoToPage}
                        >
                        Go
                        </Button>
                    </Box>
                </div>
            )}
            <ToggleFilter handleClear={handleClear} yearlyCompensationData={yearlyCompensationData} handleTimeChange={handleTimeChange} selectedPeriod={selectedPeriod} searchQueryInfo={searchQueryInfo} setSearchQueryInfo={setSearchQueryInfo} isOpen={isOpen} toggleDrawer={toggleDrawer} selectedAdminId={selectedAdminId} salesAdmins={salesAdmins} setSelectedAdminId={setSelectedAdminId} handleSearch={handleSearch} setYearlyCompensationData={setYearlyCompensationData} />
            <ViewOfferCandidatesDetails
                ref={viewOfferCandDetailsRef}
                data={selectedItem}
                showToast={showToast}
            />
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                className="active_jobs__modal-modal"
                overlayClassName="active_jobs__modal-overlay"
            >
                <div className="addNewCampaign_title">Create Campaign</div>
                <div className="addNewCampaign_label">Select Campaign Type</div>
                <ReactSelect
                    isLoading={loading}
                    options={campaignTypes}
                    onChange={handleCampaignTypeChange}
                    style={{ fontFamily: 'Poppins' }}
                    value={campaignTypes.find((ct) => ct.value === campaignType)}
                />
                <input
                    type="text"
                    placeholder="Campaign Name"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                />
                <button
                    onClick={() =>
                        addNewCampaign(selectedJob.offerId, campaignType, campaignName)
                    }
                >
                    Generate Link
                </button>

                {loading2 ? (
                <div className="active_jobs__posts-loading2">
                    <Loader />
                </div>
                ) : (
                campaignLinkWeb && (
                    <>
                    <div className="note-text">
                        Click on the link box to copy the URL.
                    </div>
                    <div
                        className="active_jobs__link-box"
                        onClick={() => copyLink(campaignLink)}
                    >
                        <span className="active_jobs__link-text">{campaignLink}</span>
                        <MuiLink className="active_jobs__link-icon" />
                    </div>
                    <div
                        className="active_jobs__link-box"
                        onClick={() => copyLink(campaignLinkWeb)}
                    >
                        <span className="active_jobs__link-text">
                        {campaignLinkWeb}
                        </span>
                        <MuiLink className="active_jobs__link-icon" />
                    </div>
                    </>
                )
                )}
                {error && <div className="campaign-error">{error}</div>}
            </ReactModal>
        </div>
    )
}

export default OfferTypeSwitch;