import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  IconButton,
  Typography,
  InputBase,
  Snackbar
} from '@material-ui/core';
import { PhotoCamera, Delete } from '@material-ui/icons';
import MuiAlert from '@mui/material/Alert';
import ReactQuill from 'react-quill';
import Loader from 'components/Loader';
import ChipInput from 'material-ui-chip-input';
import 'react-quill/dist/quill.snow.css';
import { sendFileUploadHttpCall, sendHttpCall } from 'shareService/httpService';
import { getFormatedDate } from '../helper';
import styles from './styles.module.scss';
import context from 'react-bootstrap/esm/AccordionContext';
import { ToggleButton } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BlogEdit(props) {
  const [blogData, setBlogData] = useState({
    title: '',
    author: '', 
    menuBar: '',
    category: [],
    tags: [],
    alt_tags: [],
    url: '',
    meta_description: '',
    content: '',
    bannerImageData: {
      url: '',
      altText: '',
    },
    featuredImage: '',
    pdfUrl: '',
    blogDate: '',
    pdfOriginalName: '',
    blogId: props.match.params.blog_id || '',
    contentType: 'BLOG',
  });

  const [imageFile, setImageFile] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [inlineImages, setInlineImages] = useState([]); 
  const [charCount, setCharCount] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const placeholderImage = 'https://via.placeholder.com/496x278';
  const placeholderImage2 = 'https://via.placeholder.com/1440x518';

  const [articleToggle, setArticleToggle] = useState('article');

  const toolbarOptions = [[{ 'header': [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline'], [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }], ['link', 'image'], [{ 'script': 'sub'}, { 'script': 'super' }], [{ 'color': [] }, { 'background': [] }], ['blockquote', 'code-block']];

  const module = {
    toolbar: toolbarOptions,
  }

  useEffect(() => {
    if (blogData.blogId) {
      sendHttpCall(
        'GET',
        `blogs/getById?id=${blogData.blogId}`,
        null,
        props
      ).then((response) => {
        setEdit(true);
        if (response.blogData) {
          setBlogData({
            ...blogData,
            title: response.blogData.title || '',
            author: response.blogData.author || '',
            menuBar:
            response.blogData.menu_bar && response.blogData.menu_bar !== 'null'
            ? response.blogData.menu_bar
            : '',
            tags: response.blogData.tags || [],
            alt_tags: response.blogData.alt_tags || [],
            category: response.blogData.category || [],
            url:  response.blogData.url && response.blogData.url !== 'null'
            ? response.blogData.url
            : '',
            meta_description:
            response.blogData.meta_description &&
            response.blogData.meta_description !== 'null'
              ? response.blogData.meta_description
              : '',
            content: response.blogData.text || '',
            bannerImageData: {
              url: response.blogData.bannerImageData?.url || '',
              altText: response.blogData.bannerImageData?.altText || '',
            },
            featuredImage: response.blogData.featuredImage || '',
            pdfUrl: response.blogData.pdfUrl || '',
            pdfOriginalName: response.blogData.pdfOriginalName || '',
            blogDate: response.blogData.created_at,
            contentType: response.blogData.contentType || 'BLOG',
          });
          setCharCount(response.blogData.title ? response.blogData.title.length : 0);
          setDescriptionCharCount(response.blogData.meta_description 
            ? response.blogData.meta_description.length 
            : 0)
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [blogData.blogId]);

  useEffect(() => {
    // Update image files when blogData changes
    if (blogData.bannerImageData.url) {
      fetch(blogData.bannerImageData.url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'BannerImage', { type: blob.type });
          setImageFile(file);
        });
    }

    if (blogData.featuredImage) {
      fetch(blogData.featuredImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'FeatureImage', { type: blob.type });
          setImageFile2(file);
        });
    }
  }, [blogData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    console.log(value)
    switch (name) {
      case 'title':
        setCharCount(value.length);
        break;
      case 'menuBar':
        break;
      case 'url':
        break;
      case 'meta_description':
        setDescriptionCharCount(value.length);
        break;
      case 'tags':
        break;

    }
    if (name === 'bannerAltText') {
      setBlogData((prev) => ({
        ...prev,
        bannerImageData: {
          ...prev.bannerImageData,
          altText: value,
        }
      }));
    } else {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const validateFields = () => {
    let validationErrors = {};
    if (!blogData.title) validationErrors.title = 'Title is required';
    if (!blogData.content) validationErrors.content = 'Content is required';
    if (blogData.title.length > 60) validationErrors.title = 'Title cannot be more than 60 characters'
    if (blogData.meta_description.length > 160) validationErrors.meta_description = 'Meta Description cannot be more than 160 characters'
    if (blogData.category.length === 0)
      validationErrors.category = 'At least one category is required';
    if (blogData.tags.length === 0)
      validationErrors.tags = 'At least one tag is required';
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleImageUpload = (event, imageType) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file) {
      if (file.size > maxFileSize) {
        setSnackbar({
          open: true,
          message: `File size exceeds 5MB. Current size: ${(
            file.size /
            1024 /
            1024
          ).toFixed(2)}MB`,
          severity: 'warning'
        });
        return;
      }

      if (imageType === 'banner') {
        if (imageFile2 && imageFile2.name === file.name) {
          setSnackbar({
            open: true,
            message: 'Banner and Featured images cannot be the same.',
            severity: 'warning'
          });
          return;
        }
        setImageFile(file);
      } else {
        if (imageFile && imageFile.name === file.name) {
          setSnackbar({
            open: true,
            message: 'Banner and Featured images cannot be the same.',
            severity: 'warning'
          });
          return;
        }
        setImageFile2(file);
      }
    }
  };

  const handleSaveButtonClick = () => {
    if (!validateFields()) {
      setSnackbar({
        open: true,
        message: 'Please fill out all mandatory fields.',
        severity: 'warning'
      });
      return;
    }

    if (blogData.title.length > 100) {
      setSnackbar({
        open: true,
        message: 'Title exceeds maximum length of 100 characters.',
        severity: 'warning'
      });
      return;
    }

    const data = new FormData();
    data.append('status', 'ACTIVE');
    if (imageFile) data.append('files[]', imageFile, 'BannerImage');
    if (imageFile2) data.append('files[]', imageFile2, 'FeatureImage');

    if (pdfFile) {
      data.append('files[]', pdfFile, 'BlogPDF');
    }

    data.append('bannerAltText', blogData.bannerImageData?.altText || '');

    Object.keys(blogData).forEach((key) => {
      if (blogData[key]) {
        data.append(
          key,
          Array.isArray(blogData[key])
            ? JSON.stringify(blogData[key])
            : blogData[key]
        );
      }
    });

    const url = edit ? 'blogs/edit' : 'blogs/add';
    const method = edit ? 'PUT' : 'POST';

    sendFileUploadHttpCall(method, url, data, props)
      .then((response) => {
        if (response.blogData) {
          setSnackbar({
            open: true,
            message: edit
              ? 'Successfully edited the Blog'
              : 'Successfully added the Blog',
            severity: 'success'
          });
          props.history.push('/blog-details');
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Something went wrong. Please try again.',
          severity: 'error'
        });
      });
  };

  const handleSaveAsDraftButtonClick = () => {
    if (!validateFields()) {
      setSnackbar({
        open: true,
        message: 'Please fill out all mandatory fields.',
        severity: 'warning'
      });
      return;
    }

    if (blogData.title.length > 100) {
      setSnackbar({
        open: true,
        message: 'Title exceeds maximum length of 100 characters.',
        severity: 'warning'
      });
      return;
    }
  
    const data = new FormData();
    data.append('status', 'DRAFT');
    if (imageFile) data.append('files[]', imageFile, 'BannerImage');
    if (imageFile2) data.append('files[]', imageFile2, 'FeatureImage');

    if (pdfFile) {
      data.append('files[]', pdfFile, 'BlogPDF');
    }

    // Add all blogData fields
    Object.keys(blogData).forEach((key) => {
      const val = blogData[key];
      if (val) {
        if (Array.isArray(val)) {
          data.append(key, JSON.stringify(val));
        } else if (typeof val === 'object') {
          // If it's our bannerImageData object
          if (key === 'bannerImageData') {
            // We can pass altText specifically
            data.append('bannerAltText', val.altText || '');
            // or pass the entire object if backend handles it
            // data.append('bannerImageData', JSON.stringify(val));
          } else {
            // For other objects, e.g. if we had nested structures
            data.append(key, JSON.stringify(val));
          }
        } else {
          data.append(key, val);
        }
      }
    });
  
    const url = edit ? 'blogs/edit' : 'blogs/add';
    const method = edit ? 'PUT' : 'POST';
  
    sendFileUploadHttpCall(method, url, data, props)
      .then((response) => {
        if (response.blogData) {
          setSnackbar({
            open: true,
            message: edit
              ? 'Successfully saved draft changes'
              : 'Successfully created a new draft',
            severity: 'success'
          });
          props.history.push('/blog-details');
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Something went wrong. Please try again.',
          severity: 'error'
        });
      });
  };
  

  const handleDeleteButtonClick = () => {
    if (edit) {
      sendHttpCall('POST', 'blogs/delete', { id: blogData.blogId }, props)
        .then((response) => {
          if (response.blogData) {
            setSnackbar({
              open: true,
              message: 'Successfully Deleted the Blog',
              severity: 'success'
            });
            props.history.push('/blog-details');
          }
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message: 'Something went wrong. Please try again.',
            severity: 'error'
          });
        });
    }
  };

  const handleChipDelete = (chipToDelete, type) => {
    setBlogData((prevData) => ({
      ...prevData,
      [type]: prevData[type].filter((chip) => chip !== chipToDelete)
    }));
  };

  const handleChipAdd = (chip, type) => {
    if (chip.length > 30) {
      setSnackbar({
        open: true,
        message: 'Tags cannot exceed 30 characters.',
        severity: 'warning'
      });
      return;
    }
    if (blogData[type].includes(chip)) {
      setSnackbar({
        open: true,
        message: 'Duplicate tags are not allowed.',
        severity: 'warning'
      });
      return;
    }
    setBlogData((prevData) => ({
      ...prevData,
      [type]: [...new Set([...prevData[type], chip])]
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  if (loading) {
    return <Typography variant="h2"><Loader /></Typography>;
  }

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        setSnackbar({
          open: true,
          message: 'Only PDF files are allowed.',
          severity: 'warning'
        });
        return;
      }
      setPdfFile(file);
    }
  };

  const handlePreviewPdf = () => {
    // if user has chosen a new PDF, open it from memory
    if (pdfFile) {
      const pdfURL = URL.createObjectURL(pdfFile);
      window.open(pdfURL, '_blank');
    }
    else if (blogData.pdfUrl) {
      window.open(blogData.pdfUrl, '_blank');
    } else {
      setSnackbar({
        open: true,
        message: 'No PDF to preview.',
        severity: 'info'
      });
    }
  };

  const handleToggleChange = (event, newValue) => {
      if (newValue !== null) {
        setArticleToggle(newValue);
    }
  };

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className={styles.buttonGroupTop}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveButtonClick}>
          Save
        </Button>
        <Button
    variant="contained"
    style={{ backgroundColor: '#ff9800', color: '#fff' }}
    onClick={handleSaveAsDraftButtonClick}
  >
    Save As Draft
  </Button>
        <Button
          variant="outlined"
          onClick={() => props.history.push('/blog-details')}>
          Cancel
        </Button>
        {edit && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteButtonClick}>
            Delete
          </Button>
        )}
      </div>
      <Typography variant="h4" className={styles.title}>
        {blogData.contentType === 'ARTICLE' ? 'Create/Edit Article' : 'Create/Edit Blog'}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Card className={styles.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={styles.avatar}>
                  G
                </Avatar>
              }
              title={blogData.author || 'GoodSpace'}
              subheader={edit ? getFormatedDate(blogData.blogDate) : ''}
              action={
                <ToggleButton
                  value="article"
                  selected={blogData.contentType === 'ARTICLE'}
                  onChange={() =>
                    setBlogData((prev) => ({
                      ...prev,
                      contentType: prev.contentType === 'ARTICLE' ? 'BLOG' : 'ARTICLE'
                    }))
                  }
                  sx={{
                    borderRadius: '20px',
                    padding: '0 16px',
                    '&.Mui-selected': {
                      backgroundColor: '#489EE0',
                      color: '#fff'
                    }
                  }}
                >
                  ARTICLE
                </ToggleButton>
              }
            />
            
            <Grid container spacing={3} className={styles.form}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="title"
                  value={blogData.title}
                  onChange={handleInputChange}
                  placeholder="Enter title"
                  inputProps={{ maxLength: 60 }}
                  className={`${styles.inputField}`}
                  error={!!errors.title}
                  helperText={errors.title}
                />
                <Typography variant='caption'
                  color='textSecondary'>
                  {charCount}/60
                </Typography>
              </Grid>
              {/* New Author Field */}
  <Grid item xs={6}>
    <TextField
      fullWidth
      variant="outlined"
      name="author"
      value={blogData.author}
      onChange={handleInputChange}
      placeholder="Enter author name"
      inputProps={{ maxLength: 50 }}
      className={styles.inputField}
      error={!!errors.author}
      helperText={errors.author}
    />
  </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="menuBar"
                  value={blogData.menuBar}
                  onChange={handleInputChange}
                  placeholder="Enter menu bar"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="category"
                  value={blogData.category}
                  onAdd={(chip) => handleChipAdd(chip, 'category')}
                  onDelete={(chip) => handleChipDelete(chip, 'category')}
                  placeholder="Add categories"
                  className={`${styles.inputField}`}
                  error={!!errors.category}
                  helperText={errors.category}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="tags"
                  value={blogData.tags}
                  onAdd={(chip) => handleChipAdd(chip, 'tags')}
                  onDelete={(chip) => handleChipDelete(chip, 'tags')}
                  placeholder="Add tags"
                  className={`${styles.inputField}`}
                  error={!!errors.tags}
                  helperText={errors.tags}
                />
              </Grid>
              <Grid item xs={6}>
                <ChipInput
                  fullWidth
                  name="alt_tags"
                  value={blogData.alt_tags}
                  onAdd={(chip) => handleChipAdd(chip, 'alt_tags')}
                  onDelete={(chip) => handleChipDelete(chip, 'alt_tags')}
                  placeholder="Add alt tags"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="url"
                  value={blogData.url}
                  onChange={handleInputChange}
                  placeholder="Enter URL"
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="meta_description"
                  multiline
                  rows={3}
                  value={blogData.meta_description}
                  onChange={handleInputChange}
                  error={!!errors.meta_description}
                  helperText={errors.meta_description}
                  inputProps={{ maxLength: 160 }}
                  placeholder="Enter meta description"
                  className={styles.inputField}
                />
                <Typography variant='caption'
                  color='textSecondary'>
                  {descriptionCharCount}/160
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="tagsInline"
                  placeholder="Add comma-separated values. These will appear as tags."
                  value={blogData.tags.join(', ')}
                  onChange={(e) => {
                    handleInputChange({
                      target: {
                        name: 'tags',
                        value: e.target.value
                          .split(',')
                          .map((item) => item.trim())
                      }
                    });
                  }}
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12}>
              <ReactQuill
      theme="snow"
      modules = {module}
      value={blogData.content}
      onChange={(content) => setBlogData({ ...blogData, content })}
      className={`${styles.editor} ${styles.inputField}`}
    />
                {errors.content && (
                  <Typography className={styles.errorText}>
                    {errors.content}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={styles.card}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={styles.imageUpload}>
                <Typography variant="body1">
                  Banner Image (496 x 278px)
                </Typography>
                <div className={`${styles.imagePreviewContainer}`}>
                  <img
                    src={
                      imageFile
                        ? URL.createObjectURL(imageFile)
                        : blogData.bannerImageData?.url || placeholderImage
                    }
                    alt="Banner"
                    className={styles.imagePreview}
                  />
                  {imageFile && (
                    <IconButton
                      className={`${styles.deleteIcon} ${styles.outsideDeleteIcon}`}
                      onClick={() => setImageFile(null)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
                <div>
                  {imageFile && (
                    <Typography variant="caption" className={styles.imageSize}>
                      Size: {(imageFile.size / 1024 / 1024).toFixed(2)}MB
                    </Typography>
                  )}
                </div>
                {errors.bannerImage && (
                  <Typography className={styles.errorText}>
                    {errors.bannerImage}
                  </Typography>
                )}
                <InputBase
                  type="file"
                  onChange={(e) => handleImageUpload(e, 'banner')}
                  startAdornment={
                    <IconButton color="primary" component="span">
                      <PhotoCamera />
                    </IconButton>
                  }
                  className={styles.uploadButton}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  name="bannerAltText"
                  value={blogData.bannerImageData.altText}
                  onChange={handleInputChange}
                  placeholder="Enter alt text for Banner Image"
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12} className={styles.imageUpload}>
                <Typography variant="body1">
                  Featured Image (1440 x 518px)
                </Typography>
                <div className={`${styles.imagePreviewContainer} `}>
                  <img
                    src={
                      imageFile2
                        ? URL.createObjectURL(imageFile2)
                        : blogData.featuredImage || placeholderImage2
                    }
                    alt="Featured"
                    className={styles.imagePreview}
                  />

                  {imageFile2 && (
                    <IconButton
                      className={`${styles.deleteIcon} ${styles.outsideDeleteIcon}`}
                      onClick={() => setImageFile2(null)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
                <div>
                  {imageFile2 && (
                    <Typography variant="caption" className={styles.imageSize}>
                      Size: {(imageFile2.size / 1024 / 1024).toFixed(2)}MB
                    </Typography>
                  )}
                </div>
                {errors.featuredImage && (
                  <Typography className={styles.errorText}>
                    {errors.featuredImage}
                  </Typography>
                )}
                <InputBase
                  type="file"
                  onChange={(e) => handleImageUpload(e, 'featured')}
                  startAdornment={
                    <IconButton color="primary" component="span">
                      <PhotoCamera />
                    </IconButton>
                  }
                  className={styles.uploadButton}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', padding: '0 16px 16px' }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#ff9800', color: '#fff' }}
                  component="label"
                >
                  UPLOAD PDF
                  <input
                    type="file"
                    hidden
                    onChange={handlePdfUpload}
                    accept="application/pdf"
                  />
                </Button>
                {pdfFile ? (
                  // If user has selected a new PDF, show that file's name
<Typography variant="body2" style={{ marginLeft: '10px' }}>
    {pdfFile.name}
  </Typography>
) : blogData.pdfOriginalName ? (
  // Else if there's an existing PDF from DB, show its original name
  <Typography variant="body2" style={{ marginLeft: '10px' }}>
    {blogData.pdfOriginalName}
  </Typography>
) : null }
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', padding: '0 16px 16px' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePreviewPdf}
                >
                  Preview PDF
                </Button>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
