import { headers, apiUrl } from '../config';
import { toast } from 'react-toastify';

export const sendHttpCall = async (method, url, data, props) => {
  var userDataString = localStorage.getItem('login_data');
  var userDataParse = JSON.parse(userDataString);
  if (userDataParse === null) {
    localStorage.clear();
    props.history.push('/sign-in');
  } else {
    headers.Authorization = userDataParse.accessToken;
    let response = await fetch(`${apiUrl}/${url}`, {
      method: method,
      headers: headers,
      body: data === null ? null : JSON.stringify(data)
    });
    if (response.status == 401) {
      let response2 = await fetch(`${apiUrl}/auth/refresh_access_token`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ refreshToken: userDataParse.refreshToken })
      });
      const res2 = await response2.json();
      if (res2.refreshToken) {
        localStorage.setItem('login_data', JSON.stringify(res2));
        return sendHttpCall(method, url, data, props);
      } 
        localStorage.clear();
        props.history.push('/sign-in');
        return;
      
    }
    if (!response.ok) {
      let responseJson = await response.json();
      throw responseJson;
    }
    let responseJson = await response.json();
    return responseJson;
  }
};

export const sendHttpCallBlob = async (method, url, data, props) => {
  var userDataString = localStorage.getItem('login_data');
  var userDataParse = JSON.parse(userDataString);
  if (userDataParse === null) {
    localStorage.clear();
    props.history.push('/sign-in');
  } else {
    headers.Authorization = userDataParse.accessToken;
    let response = await fetch(`${apiUrl}/${url}`, {
      method: method,
      headers: headers,
      body: data === null ? null : JSON.stringify(data)
    });
    const blob = await response.blob();
    return blob;
  }
};

export const sendFileUploadHttpCallWithReturnBlob = async (
  method,
  url,
  data,
  props
) => {
  var userDataString = localStorage.getItem('login_data');
  var userDataParse = JSON.parse(userDataString);
  if (userDataParse === null) {
    localStorage.clear();
    props.history.push('/sign-in');
  } else {
    let headers = {
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      Authorization: userDataParse.accessToken
    };
    let response = await fetch(`${apiUrl}/${url}`, {
      method: method,
      headers: headers,
      body: data
    });
    const fileType = response.headers.get('content-type');
    if (fileType === 'application/json; charset=utf-8') {
      const error = await response.json();
      if (error.message) return error;
      console.log('returning error');
    } else {
      const blob = await response.blob();
      console.log('made a blob');
      console.log('returning blob');
      return blob;
    }
  }
};
export const sendFileUploadHttpCall = async (method, url, data, props) => {
  var userDataString = localStorage.getItem('login_data');
  var userDataParse = JSON.parse(userDataString);
  if (userDataParse === null) {
    localStorage.clear();
    props.history.push('/sign-in');
  } else {
    let headers = {
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      Authorization: userDataParse.accessToken
    };
    let response = await fetch(`${apiUrl}/${url}`, {
      method: method,
      headers: headers,
      body: data
    });
    let responseJson = await response.json();
    return responseJson;
  }
};

export const Toast = function (message) {
  return toast.success(message);
};

// export const advancedHttpCalls = async () => {  
//   const mainInstance = axios.create({
//     baseURL: config.baseUrl,
//     headers: {
//       "device-id": uuidv4(),
//       "device-type": "web"
//     }
//   });
//   if (browser) {
//     mainInstance.defaults.headers.userdevice = `${browser.name} ${browser.version} - ${browser.os}`;
//   }

//   mainInstance.interceptors.request.use((instanceConfig) => {
//     const token = userDataParse.accessToken;
//     if (!instanceConfig.headers.Authorization) {
//       // eslint-disable-next-line no-param-reassign
//       instanceConfig.headers.Authorization =
//         token || localStorage.getItem("token") || notLoggedInToken;
//     }
//     return instanceConfig;
//   });
// }