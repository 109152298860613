import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { salesStages, countryOptions, currencyOptions } from '../../config';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { sendHttpCall } from 'shareService/httpService';
import styles from './design.module.scss';
import BotIcon from './bot.png';
import { useSelector } from 'react-redux';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddLeadForm = (props) => {
  const [leadDetails, setLeadDetails] = useState({
    name: '',
    companyName: '',
    email: '',
    assignedToId: '',
    mobileNumber: '',
    expectedRevenue: '',
    remarks: '',
    location: '',
    salesStage: salesStages[0],
    countryCode: countryOptions[0],
    currency: currencyOptions[0]
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [nextFollowUpAt, setNextFollowUpAt] = useState(new Date());
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [soureTypes, setSourceTypes] = useState([]);

  const handleChange = (field) => (e) => {
    setLeadDetails({
      ...leadDetails,
      [field]: e.target ? e.target.value : e
    });
  };

  const adminStaticConfig = useSelector((state) => state.adminStaticConfig.data);

  const getSalesAdmins = (data) => {
    // let url = `admin/users/SALES`;
    // sendHttpCall('GET', url, null, props)
    //   .then((response) => {
    //     const formattedAdmins = [
    //       { label: 'SELF', value: 'self' },
    //       ...response.data.map((admin) => ({
    //         label: `${admin.name} (${admin.email_id})`,
    //         value: admin.admin_id
    //       })),
    //       { label: 'SoniaBot.ai', value: 'soniaBot', icon: './bot.png' },
    //     ];
    //     setSalesAdmin(formattedAdmins);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching sales admins:', error);
    //     showSnackbar('Failed to fetch sales admins', 'error');
    //   });

    const formattedAdmins = [
      { label: 'SELF', value: 'self' },
      ...data.map((admin) => ({
        label: `${admin.name} (${admin.email_id})`,
        value: admin.admin_id
      })),
      { label: 'SoniaBot.ai', value: 'soniaBot', icon: './bot.png' },
    ];
    setSalesAdmin(formattedAdmins);
  };

  const getSourceTypes = () => {
    let url = '/marketing_campaign/get_types';
    sendHttpCall('GET', url, null, props)
      .then((response) => {
        if (response.state === 200) {
          let options = response.data.map((a) => ({
            value: a.marketing_campaign_type,
            label: a.marketing_campaign_type
          }));
          setSourceTypes(options);
        }
      })
      .catch((err) => {
        console.error('Error fetching soure types:', err);
      });
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getSourceTypes();
  }, []);
  
  useEffect(() => {
    if(adminStaticConfig.loaded) getSalesAdmins(adminStaticConfig['allAdmins']);
  }, [adminStaticConfig]);

  const validateForm = () => {
    let hasError = false;
    const now = new Date();
    const minFollowUpDate = new Date(now.getTime() + 15 * 60 * 1000); // 15 minutes from now

    if (!leadDetails.assignedToId && leadDetails.assignedToId !== 'SELF') {
      showSnackbar(
        'Please select an admin or choose "Self" to assign this lead.',
        'error'
      );
      hasError = true;
    }
    if (
      !leadDetails.name ||
      !leadDetails.companyName ||
      (!leadDetails.email && !leadDetails.mobileNumber) ||
      !leadDetails.expectedRevenue ||
      !nextFollowUpAt
    ) {
      showSnackbar('Please fill all the mandatory fields before proceeding.');
      hasError = true;
    }

    if (leadDetails.mobileNumber && leadDetails.mobileNumber.length !== leadDetails.countryCode.maxLength) {
      showSnackbar(
        `Phone number should be exactly ${leadDetails.countryCode.maxLength} digits long.`
      );
      hasError = true;
    }
    if (leadDetails.expectedRevenue <= 2000) {
      showSnackbar(
        'Please choose a value greater than 2000 for the expected revenue.'
      );
      hasError = true;
    }
    if (nextFollowUpAt < minFollowUpDate) {
      showSnackbar(
        'Follow-up date should be at least 15 minutes from now and cannot be in the past.'
      );
      hasError = true;
    }
    return !hasError;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const leadData = {
        ...leadDetails,
        countryCode: leadDetails.countryCode.value,
        currency: leadDetails.currency.value,
        nextFollowUpAt
      };
      console.log('Form Data:', leadData);
      let url = 'sales/add_lead';
      sendHttpCall('POST', url, leadData, props)
        .then((response) => {
          showSnackbar('Lead saved successfully!', 'success');
          props.history.push(`/leads`);
        })
        .catch((error) => {
          showSnackbar('Failed to save lead');
        });
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <h2 className={styles.title}>Add New Lead</h2>
      <div className={styles.formRow}>
        <div className={styles.inputGroup}>
          <label>
            Name<span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            onChange={handleChange('name')}
            value={leadDetails.name}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>
            Company Name<span className={styles.required}>*</span>
          </label>
          <input
            type="text"
            onChange={handleChange('companyName')}
            value={leadDetails.companyName}
          />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.inputGroup}>
          <label>
            Email
          </label>
          <input
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            onChange={handleChange('email')}
            value={leadDetails.email}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>
            Mobile No
          </label>
          <div className={styles.phoneInput}>
            <Select
              options={countryOptions}
              value={leadDetails.countryCode}
              onChange={handleChange('countryCode')}
              className={styles.countrySelect}
            />
            <input
              type="tel"
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={handleChange('mobileNumber')}
              value={leadDetails.mobileNumber}
            />
          </div>
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.inputGroup}>
          <label>
            Expected Revenue<span className={styles.required}>*</span>
          </label>
          <div className={styles.currencyInput}>
            <Select
              options={currencyOptions}
              value={leadDetails.currency}
              onChange={handleChange('currency')}
              className={styles.currencySelect}
            />
            <input
              type="number"
              onChange={handleChange('expectedRevenue')}
              value={leadDetails.expectedRevenue}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <label>
            Assign To<span className={styles.required}>*</span>
          </label>
          <Select
            options={salesAdmins}
            formatOptionLabel={(option) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {option.icon && (
                  <img
                    src={BotIcon}
                    style={{ width: 20, height: 20, marginRight: 10 }}
                    alt="icon"
                  />
                )}
                {option.label}
              </div>
            )}
            value={
              leadDetails.assignedToId === 'SELF'
                ? { label: 'SELF', value: 'self' } // Directly set the value for 'SELF'
                : salesAdmins.find(option => option.value === leadDetails.assignedToId) || {}
            }

            onChange={(option) => {
              if (option.value === 'soniaBot') {
                showSnackbar('Coming Soon!', 'info');
              } else if (option.value === 'self') {
                setLeadDetails(prev => ({
                  ...prev,
                  assignedToId: 'SELF',
                  assignedToName: 'SELF'
                }));
              } else {
                setLeadDetails(prev => ({
                  ...prev,
                  assignedToId: option.value,
                  assignedToName: option.label.split(' (')[0]
                }));
              }
            }}
            isSearchable={false}
            className={styles.select}
            placeholder="Select Admin"
        
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Source</label>
          <Select
            options={soureTypes}
            value={leadDetails.source}
            onChange={handleChange('source')}
            className={styles.select}
          />
        </div>

      </div>

      <div className={styles.inputGroup}>
        <label>Remarks</label>
        <textarea
          onChange={handleChange('remarks')}
          value={leadDetails.remarks}></textarea>
      </div>
      <div className={styles.formRow}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            margin="normal"
            id="follow-up-date-picker"
            label="Next Follow-Up Date & Time"
            format="dd/MM/yyyy HH:mm"
            value={nextFollowUpAt}
            onChange={setNextFollowUpAt}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputLabelProps={{
              className: styles.largeLabel
            }}
            className={styles.dateTimePicker}
          />
        </MuiPickersUtilsProvider>
        <div className={styles.inputGroup}>
          <label>Sales Stage</label>
          <Select
            options={salesStages}
            value={leadDetails.salesStage}
            onChange={handleChange('salesStage')}
            className={styles.select}
          />
        </div>

        <div className={styles.inputGroup}>
          <label>Location</label>
          <input
            type="text"
            onChange={handleChange('location')}
            value={leadDetails.location}
          />
        </div>
      </div>
      <div className={styles.addBtn}>
        <button type="submit" className={styles.submitButton}>
          Add Lead
        </button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AddLeadForm;
