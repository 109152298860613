// file: Sidebar.js
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider } from '@material-ui/core';
import { toast } from 'react-toastify';

import { Profile, SidebarNav } from './components';
import buildNavigation from '../../../../utils/NavbarUtils';
import routes from 'RoutesConfig';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  const userPermissions = JSON.parse(localStorage.getItem('login_data') || '{}')['permitted_routes'] || [];

  
  const data = buildNavigation(routes, userPermissions);

  // // We'll store the grouped routes from localStorage (login_data)
  // const [groupedRoutes, setGroupedRoutes] = useState([]);

  // useEffect(() => {
  //   try {
  //     // Fetching backend’s login response stored as JSON in "login_data"
  //     // which includes "grouped_routes", then parse it:
  //     const loginDataStr = localStorage.getItem('login_data'); 
  //     if (loginDataStr) {
  //       const parsed = JSON.parse(loginDataStr);
  //       if (parsed.grouped_routes) {
  //         setGroupedRoutes(parsed.grouped_routes);
  //       } else {
  //         // In case it's missing or empty
  //         setGroupedRoutes([]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error parsing grouped_routes from localStorage:', error);
  //     toast.error('Failed to load grouped routes from local storage');
  //     setGroupedRoutes([]);
  //   }
  // }, []);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav 
          className={classes.nav}
          navigation={data.navigation}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
