import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Select,
  MenuItem,
  TextField,
  Chip
} from '@material-ui/core';
import moment from 'moment';
import { sendHttpCall, Toast } from 'shareService/httpService';
import Loader from 'components/Loader';
import { salesStages } from '../../config'; // Import salesStages
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontFamily: 'Poppins, sans-serif',
    overflowX: 'auto'
  },
  header: {
    marginBottom: theme.spacing(2),
    color: '#297bca',
    textAlign: 'center'
  },
  filterContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filterSelect: {
    marginRight: theme.spacing(2),
    minWidth: 200
  },
  filterButton: {
    minWidth: 100,
    height: '100%'
  },
  card: {
    marginBottom: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f9f9f9',
    width: 250,
    height: 80
  },
  unassignedCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #6a1b9a', // Dark purple for border
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f3e5f5', // Light purple for background
    width: 250,
    height: 80
  },
  unassignedChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#6a1b9a', // Dark purple for chip
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  revenueCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #155724',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#d4edda',
    width: 250,
    height: 80
  },
  missedCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #d32f2f',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f8d7da',
    width: 250,
    height: 80
  },
  missedChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#d32f2f',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  totalBox: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  totalChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  revenueChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#155724',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  }
}));

const ManagementReports = (props) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState({
    opportunitiesRevenue: 0,
    unassignedOpportunities: 0,
    missedOppFollowUps: 0,
    opportunitiesData: [],
    leadsData: []
  });
  const [salesAdmins, setSalesAdmin] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState(() => {
    const userType = localStorage.getItem('user_type');
    const adminId = localStorage.getItem('admin_id');
    const managementReportAdminId = sessionStorage.getItem(
      'managementReportAdminId'
    );

    return userType === 'SALES' ? adminId : managementReportAdminId || '';
  });

  const [selectedAdminName, setSelectedAdminName] = useState('');
  const [loading, setLoading] = useState(false);
  const today = moment(new Date()).format('YYYY-MM-DD');
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [selectedPeriod, setSelectedPeriod] = useState(
    sessionStorage.getItem('reportTimePeriod') ? sessionStorage.getItem('reportTimePeriod') : 'Today'
  );

  const fetchData = async () => {
    setLoading(true);
    const query = `?start_date=${selectedStartDate}&end_date=${selectedEndDate}&adminId=${selectedAdminId}`;
    try {
      const response = await sendHttpCall(
        'GET',
        `sales/management_report${query}`
      );
      setReportData({
        opportunitiesRevenue: 0, // If needed, update from API
        unassignedOpportunities: 0, // If needed, update from API
        missedOppFollowUps: 0, // If needed, update from API
        opportunitiesData: response.opportunitiesData || [],
        leadsData: response.leadsData || []
      });
    } catch (error) {
      Toast('Error fetching data');
    }
    setLoading(false);
  };

  const adminStaticConfig = useSelector((state) => state.adminStaticConfig.data);

  const getSalesAdmins = async (data) => {
    // If you want to fetch from an API, remove the following lines and call your API
    // let url = `admin/users/SALES/ACTIVE`;
    // try {
    //   const response = await sendHttpCall('GET', url, null);
    //   const formattedAdmins = response.data.map((admin) => ({
    //     label: `${admin.name} (${admin.email_id})`,
    //     value: admin.admin_id
    //   }));
    //   setSalesAdmin([{ label: 'All Admins', value: '' }, ...formattedAdmins]); // Add 'All Admins' option
    // } catch (error) {
    //   console.log('Error fetching sales admins:', error);
    // }

    // Using data from the Redux store:
    const formattedAdmins = data.map((admin) => ({
      label: `${admin.name} (${admin.email_id})`,
      value: admin.admin_id
    }));
    setSalesAdmin([{ label: 'All Admins', value: '' }, ...formattedAdmins]); // Add 'All Admins' option
  };

  useEffect(() => {
    if (adminStaticConfig.loaded) {
      getSalesAdmins(adminStaticConfig['allAdmins']);
    }
  }, [adminStaticConfig]);

  useEffect(() => {
    // Trigger time selection logic
    handleTimeChange({ target: { value: selectedPeriod } }, false);
  }, [selectedPeriod]);

  useEffect(() => {
    fetchData();
  }, [selectedAdminId, selectedStartDate]);

  const handleAdminChange = (event) => {
    const adminId = event.target.value;
    setSelectedAdminId(adminId);
    const selectedAdmin = salesAdmins.find((admin) => admin.value === adminId);
    setSelectedAdminName(selectedAdmin ? selectedAdmin.label : '');

    sessionStorage.setItem('managementReportAdminId', adminId);
  };

  const handleTimeChange = (event, shouldStoreInSession = true) => {
    const period = event.target.value;
    setSelectedPeriod(period);

    let endDate = new Date();
    let startDate = new Date();

    switch (period) {
      case 'Yesterday':
        startDate.setDate(endDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      case 'This Week (Mon - Today)':
        // This Week: From Monday to today
        startDate = moment().startOf('isoWeek'); // Monday
        endDate = moment().endOf('day'); // Today
        break;
      case 'Last Week (Mon - Sun)':
        // Last Week: From Monday to Sunday of the previous week
        startDate = moment().subtract(1, 'weeks').startOf('isoWeek'); // Last Monday
        endDate = moment().subtract(1, 'weeks').endOf('isoWeek'); // Last Sunday
        break;
      case 'This Month':
        startDate = moment().startOf('month');
        endDate = moment().endOf('day'); // current date
        break;
      case 'Last Month':
        startDate = moment().subtract(1, 'months').startOf('month');
        endDate = moment().subtract(1, 'months').endOf('month');
        break;
      case 'Last 3 Months':
        startDate = moment().subtract(3, 'months').startOf('day');
        break;
      case 'Last 6 Months':
        startDate = moment().subtract(6, 'months').startOf('day');
        break;
      case 'Last Year':
        startDate = moment().subtract(1, 'year').startOf('day');
        break;
      default:
        // 'Today'
        break;
    }

    // For all cases except "Custom Date", adjust dates automatically
    if (period !== 'Custom Date') {
      const formattedStart = moment(startDate).format('YYYY-MM-DD');
      const formattedEnd = moment(endDate).format('YYYY-MM-DD');
      setSelectedStartDate(formattedStart);
      setSelectedEndDate(formattedEnd);
    }

    if (shouldStoreInSession) {
      sessionStorage.setItem('reportTimePeriod', period);
    }
  };

  const handleBoxClick = (type, salesDataType = '', stageId = '') => {
    const url = `/data/${type}/Today/${selectedStartDate}/${selectedEndDate}?salesDataType=${salesDataType}${
      stageId ? `&stageId=${stageId}` : ''
    }${selectedAdminId ? `&adminId=${selectedAdminId}` : ''}`;
    props.history.push(url);
  };

  // Renders the opportunities section
  const renderOpportunitiesSection = () => {
    const totalOpportunities = reportData.opportunitiesData.reduce(
      (acc, curr) => acc + curr.user_count,
      0
    );

    return (
      <div>
        <div className={classes.totalBox}>
          Opportunities Updated: {totalOpportunities}
        </div>
        <Grid container spacing={2}>
          {salesStages.map((stage) => {
            const stageData = reportData.opportunitiesData.find(
              (data) => data.sales_stage_id === stage.value
            );
            if (stageData && stageData.user_count > 0) {
              return (
                <Grid
                  item
                  key={stage.value}
                  onClick={() =>
                    handleBoxClick(
                      'opportunities',
                      'management_report',
                      stage.value
                    )
                  }>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle1">{stage.label}</Typography>
                      <Chip
                        label={
                          selectedAdminId && selectedPeriod === 'Today'
                            ? stageData.user_count
                            : stageData.user_count
                        }
                        color="primary"
                        variant="outlined"
                        className={classes.totalChip}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </div>
    );
  };

  // Render leads section
  const renderLeadsSection = () => {
    const totalLeads = reportData.leadsData.reduce(
      (acc, curr) => acc + curr.count,
      0
    );

    return (
      <div>
        <div className={classes.totalBox}>Leads Created: {totalLeads}</div>
        <Grid container spacing={2}>
          {salesStages.map((stage) => {
            const stageData = reportData.leadsData.find(
              (data) => data.salesStage === stage.value
            );
            if (stageData && stageData.count > 0) {
              return (
                <Grid
                  item
                  key={stage.label}
                  onClick={() =>
                    handleBoxClick('leads', 'management_report', stage.value)
                  }>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle1">{stage.label}</Typography>
                      <Chip
                        label={stageData.count}
                        color="primary"
                        variant="outlined"
                        className={classes.totalChip}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Sales Management Reports
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.filterContainer}>
            <TextField
              fullWidth
              margin="dense"
              name="status"
              onChange={(event) => handleTimeChange(event, true)}
              required
              select
              SelectProps={{ native: true }}
              value={selectedPeriod}
              variant="outlined"
              className={classes.filterSelect}
            >
              {[
                'Today',
                'Yesterday',
                'This Week (Mon - Today)',
                'Last Week (Mon - Sun)',
                'This Month',
                'Last Month',
                'Last 3 Months',
                'Last 6 Months',
                'Last Year',
                'Custom Date'
              ].map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <Select
              value={selectedAdminId}
              onChange={handleAdminChange}
              displayEmpty
              className={classes.filterSelect}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {salesAdmins.map((admin) => (
                <MenuItem key={admin.value} value={admin.value}>
                  {admin.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          {renderOpportunitiesSection()}
          {renderLeadsSection()}
        </>
      )}
    </div>
  );
};

export default ManagementReports;
