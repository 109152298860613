import { configureStore } from "@reduxjs/toolkit";
import adminStaticConfigReducer from "./reducer/adminStaticConfigReducer";
import staticConfig from './reducer/staticConfigReducer';
import permissions from "./reducer/permissionsReducer";

const store = configureStore({
    reducer: {
        adminStaticConfig: adminStaticConfigReducer,
        staticConfig: staticConfig,
        permissions: permissions
    }
});

export default store;