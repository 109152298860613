import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { sendHttpCall } from 'shareService/httpService';
// import { UsersToolbar } from '../components';
import './style.css';
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AddCampaign = (props) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [jobOptions, setJobOptions] = useState([]);
  const [productOptions, setproductOptions] = useState([]);
  //   const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('');
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMode, setSelectedMode] =
    useState({ label: 'None', value: 'NONE' });
    const [selectedCampaignRedirectLinkObj, setSelectedCampaignRedirectLinkObj] =
    useState({ label: 'None', value: 'none' });
  const [newCampaignInput, setNewCampaignInput] = useState('');
  //   const [totalCount, setTotalCount] = useState(0);
  //   const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [data2, setData2] = useState('');
  const classes = useStyles();

  useEffect(() => {
    getCampaignTypes();
    getActiveJobs();
    getActiveProducts();
  }, []);
  const getActiveJobs = () => {
    let url = '/jobs/get_all_active_jobs_minimal';
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      let data = response.map((a) => ({
        label: a.title + ' - ' + a.name,
        value: a.user_offer_id
      }));
      setJobOptions(data);
    });
  };
  const getActiveProducts = () => {
    let url = '/products/get_all_premium_products';
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      let data = response.map((a) => ({
        label: a.name,
        value: a.route
      }));
      setproductOptions(data);
    });
  };
  const handleCampaignRedirectChange = (e) =>
    setSelectedCampaignRedirectLinkObj(e);

  const handleModeChange = (e) =>
    {
      setSelectedMode(e);
    }

  const handleCampaignTypeChange = (e) => {
    console.log(e);
    setSelectedCampaignType(e.value);
  };
  const getCampaignTypes = () => {
    setLoading(true);
    let url = '/marketing_campaign/get_types';
    sendHttpCall('GET', url, null, props).then((response) => {
      if (response.state === 200) {
        console.log(response.data);
        let options_ = response.data.map((a) => ({
          value: a.marketing_campaign_type,
          label: a.marketing_campaign_type
        }));
        setOptions(options_);
        setLoading(false);
      }
    });
  };
  const handleCreate = (value) => {
    console.log(value);
    if (!value) return;
    value = value.trim();
    setLoading(true);
    let url = '/marketing_campaign/add_type';
    sendHttpCall('POST', url, { value: value }, props)
      .then((response) => {
        if (response.state === 200) {
          getCampaignTypes();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addNewCampaign = () => {
    if (loading2 || !newCampaignInput || !selectedCampaignType) return;
    if (newCampaignInput.search(/^[a-zA-Z0-9-_]+$/) == -1) {
      alert("Can have only 'A-Z','a-z','_' and '-' in campaign name");
      return;
    }
    setLoading2(true);
    let url = '/marketing_campaign/add_campaign';
    sendHttpCall(
      'POST',
      url,
      {
        marketing_campaign_name: `${selectedCampaignType}-${newCampaignInput}`,
        marketing_campaign_redirect_link:
          selectedCampaignRedirectLinkObj.value === 'none'
            ? null
            : (selectedProduct ? selectedProduct.value:selectedCampaignRedirectLinkObj.value),
        param_id: selectedJob ? selectedJob.value : null,
        mode:selectedMode.value
      },
      props
    )
      .then((response) => {
        if (response.state === 200) {
          setData2(response.data);
          //   setTotalCount(response.totalCount);
          setLoading2(false);
          setError('');
        } else {
          setLoading2(false);
          setError(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };

  const allRedirectLinks = [
    { label: 'None', value: 'none' },
    { label: 'Profile Page', value: 'profile' },
    { label: 'Interview Landing Page', value: 'ai-interview' },
    { label: 'Hire360', value: 'hire360' },
    { label: 'Feedback Page', value: 'feedback' },
    { label: 'Notifications', value: 'notifications' },
    { label: 'GCoins', value: 'gcoins' },
    { label: 'Chats Page', value: 'chats' },
    { label: 'My Job Offers', value: 'my_job_offers' },
    // { label: 'Recruiter Landing Page', value: 'hirer' },
    { label: 'Jobs Seeker', value: 'job-seeker' },
    { label: 'Premium Products', value: 'premium' },
    { label: "Premium ATS Scanner", value: "premium/ats" }
  ];
  
  let redirectLinks = allRedirectLinks;
  
  if (selectedMode.value === 'JOBS') {
    redirectLinks = allRedirectLinks.push({ label: 'Jobs Page', value: 'jobs' });
  } else if (selectedMode.value === 'HIRER') {
    redirectLinks = allRedirectLinks.push({ label: 'Hire Page', value: 'hirer' });
  }
  
  return (
    <div className={classes.root}>
      <div className="marketingCampaign__title">
        Marketing Campaigns Manager
      </div>
      <div className="addCampaign__text">Add new Marketing Campaign</div>
      {/* <ReactModal
        isOpen={openModal}
        overlayClassName="overlay"
        className="modal"
        onRequestClose={closeModal}> */}
        
      <div className="addNewCampaign_container">
        {/* <div className="addNewCampaign_title">Add New Marketing Campaign</div> */}
        <div className="addNewCampaign_label">Enter Mode</div>
        <ReactSelect
          // isLoading={loading}
          options={[
            { label: 'Jobs', value: 'JOBS' },
            { label: 'Hirer', value: 'HIRER'},
          ]}
          value={selectedMode}
          onChange={handleModeChange}
        />
        {selectedMode.value!=='NONE'&&<div>
        <div className="addNewCampaign_label">Choose Campaign Type</div>
        <ReactSelect
          isLoading={loading}
          options={options}
          isDisabled={loading}
          // onCreateOption={handleCreate}
          onChange={handleCampaignTypeChange}
        />
        {selectedCampaignType.length>0&&<div>
          <div className="addNewCampaign_label">Enter Campaign Name</div>
        <input
          type="text"
          className="addNewCampaign_input"
          value={newCampaignInput}
          onKeyPress={(e) => {
            console.log(e.key);
            if (e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onChange={(e) => {
            setNewCampaignInput(e.target.value);
          }}
        />
        <div className="addNewCampaign_label">Enter Redirect Link (if any)</div>
          <ReactSelect
          // isLoading={loading}
          options={allRedirectLinks}
          value={selectedCampaignRedirectLinkObj}
          onChange={handleCampaignRedirectChange}
        />

        {selectedCampaignRedirectLinkObj.value === 'jobs' && (
          <>
            <div className="addNewCampaign_label">
              Select Specific Job (Optional)
            </div>
            <ReactSelect
              // isLoading={loading}
              options={jobOptions}
              value={selectedJob}
              onChange={(e) => setSelectedJob(e)}
            />
          </>
        )}

        {selectedCampaignRedirectLinkObj.value === 'premium' && (
          <>
            <div className="addNewCampaign_label">
              Select Specific Product (Optional)
            </div>
            <ReactSelect
              // isLoading={loading}
              options={productOptions}
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e)}
            />
          </>
        )}
        </div>}
        <button className="addNewCampaign_btn" onClick={addNewCampaign}>
          {loading2
            ? 'Adding Campaign'
            : data2
              ? 'Campaign Added'
              : 'Add Campaign'}
        </button>    
        </div>
        }
        {data2 && (
          <div className="addNewCampaign_label">
            Link for Campaign :<br />
            <a href={data2?.campaignLink} target="_blank">
              {data2.campaignLink}
            </a>
            <br />
            <br />
            Link for Campaign Web:
            <br />
            <a href={data2?.campaignLinkWeb} target="_blank">
              {data2.campaignLinkWeb}
            </a>
          </div>
        )}

        {error && <div className="addNewCampaign_error">{error}</div>}
      </div>
      {/* </ReactModal> */}
    </div>
  );
};

export default AddCampaign;
