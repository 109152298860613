import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { parseISO, format } from 'date-fns';
import { sendHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';

import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Paper,
  ButtonGroup
} from '@material-ui/core';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactModal from 'react-modal';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(1, 3),
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  activeUsersButton: {
    backgroundColor: '#ccc',
    color: '#000',
    '&.selected': {
      backgroundColor: '#297BCA',
      color: '#fff'
    }
  },
  inactiveUsersButton: {
    backgroundColor: '#ccc',
    color: '#000',
    '&.selected': {
      backgroundColor: '#297BCA',
      color: '#fff'
    }
  },
  createUserButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  deactivateButton: {
    color: 'red'
  },
  activateButton: {
    color: 'green'
  },
  editButton: {
    color: '#3f51b5'
  },
  refreshButton: {
    color: '#ff9800'
  }
}));

const ViewAllAdmins = () => {
  const classes = useStyles();

  // ===================== State =====================
  const [adminUsers, setAdminUsers] = useState([]);
  const [status, setStatus] = useState('ACTIVE');

  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [newUser, setNewUser] = useState({
    name: '',
    emailPrefix: '',
    type: 'ADMIN'
  });

  // For permissions TransferList
  const [allPermissions, setAllPermissions] = useState([]);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  
  const [allRoutes, setAllRoutes] = useState([]);
  const [currentPermittedRoutes, setCurrentPermittedRoutes] = useState([]);
  const [currentDeactivatedRoutes, setCurrentDeactivatedRoutes] = useState([]);

  // Confirmation for Deactivate or Regenerate
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [actionType, setActionType] = useState(null);

  const [permissionsTab, setPermissionsTab] = useState('permissions');

  // Currently logged-in admin ID
  const loggedInAdminId = localStorage.getItem('admin_id') || '';

  // ===================== Effects =====================
  useEffect(() => {
    fetchAllPermissions();
    fetchAdminUsers(status);
  }, [status]);

  const fetchAllRoutes = async () => {
    await sendHttpCall(
      'GET',
      `routes/getAllRoutes`,
      null,
      null
    )
      .then((resp) => {
        setAllRoutes(resp?.data || []);
      })
      .catch((err) => {
        toast.error("Some Error Occured Please Try Again!!!");
      })
  }

  useEffect(() => {
    fetchAllRoutes();
  }, []);

  const handleTabChange = () => {
    setPermissionsTab((prev) => prev === 'permissions' ? 'routes' : 'permissions');
  }

  // ===================== API Calls =====================
  // 1) Load all perms
  const fetchAllPermissions = () => {
    sendHttpCall('GET', 'permissions/list', null)
      .then((res) => {
        if (res.success && res.data) {
          setAllPermissions(res.data);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 2) Load admin users
  const fetchAdminUsers = (sts) => {
    sendHttpCall('GET', `admin/getAdminUsers/${sts}`, null)
      .then((res) => {
        if (res.success && res.data) {
          const dataFmt = res.data.map((item, idx) => ({
            ...item,
            last_login: item.last_login
              ? format(parseISO(item.last_login), 'dd MMM, yy | h:mm a')
              : '',
            serial: idx + 1,
            emailPrefix: item.email_id?.split('@')[0] || ''
          }));
          setAdminUsers(dataFmt);
        } else {
          setAdminUsers([]);
          toast.error(res.message || 'No admin users found');
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 3) Create new user
  const createNewUser = () => {
    const email = `${newUser.emailPrefix}@goodspace.ai`;
    const password = generatePassword(newUser.name);

    const body = {
      name: newUser.name,
      email_id: email,
      type: newUser.type,
      password
    };
    sendHttpCall('POST', 'admin/createAdmin', body)
      .then((res) => {
        if (res.success) {
          toast.success('User created');
          const creds = `Email: ${email}\nPassword: ${password}`;
          navigator.clipboard
            .writeText(creds)
            .then(() => toast.success('Credentials copied'))
            .catch(() => toast.error('Failed to copy credentials'));
          fetchAdminUsers(status);
          handleCloseDialog();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 4) Update user
  const updateUser = () => {
    if (!selectedUser) return;
    const email = `${newUser.emailPrefix}@goodspace.ai`;

    const body = {
      name: newUser.name,
      email_id: email,
      type: newUser.type
    };
    sendHttpCall('PUT', `admin/updateDetails/${selectedUser.admin_id}`, body)
      .then((res) => {
        if (res.success) {
          toast.success('User updated');
          fetchAdminUsers(status);
          handleCloseDialog();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 5) Deactivate user
  const deactivateUser = (adminId) => {
    const body = { status: 'INACTIVE' };
    sendHttpCall('PUT', `admin/updateDetails/${adminId}`, body)
      .then((res) => {
        if (res.success) {
          toast.success('User deactivated');
          fetchAdminUsers(status);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 6) Activate user
  const activateUser = (adminId) => {
    const body = { status: 'ACTIVE' };
    sendHttpCall('PUT', `admin/updateDetails/${adminId}`, body)
      .then((res) => {
        if (res.success) {
          toast.success('User activated');
          // If we are currently on "INACTIVE" list, the user will
          // disappear from that list, as it is now ACTIVE.
          fetchAdminUsers(status);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 7) Overwrite permissions
  const overwritePermissions = (adminId, diffArray) => {
    const body = { permissions: diffArray };
    sendHttpCall('PUT', `admin/updatePermissions/${adminId}`, body)
      .then((res) => {
        if (res.success) {
          toast.success('Overwritten perms updated');
          setOpenPermissionDialog(false);
          fetchAdminUsers(status);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // 8) Regenerate password
  const regeneratePassword = (adminId, userName) => {
    const newPass = generatePassword(userName);
    const body = { password: newPass };
    sendHttpCall('PUT', `admin/updateDetails/${adminId}`, body)
      .then((res) => {
        if (res.success) {
          toast.success(`Password regenerated for ${userName}`);
          navigator.clipboard
            .writeText(newPass)
            .then(() => toast.success('Copied new password'))
            .catch(() => toast.error('Failed to copy password'));
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  // ============== Helpers ==============
  const handleCreateOrUpdateUser = () => {
    if (editMode) {
      updateUser();
    } else {
      createNewUser();
    }
  };

  const handleDeactivate = (adminId) => {
    deactivateUser(adminId);
  };
  const handleActivate = (adminId) => {
    activateUser(adminId);
  };
  const handleRegeneratePasswordClick = (rowData) => {
    if (!rowData) return;
    regeneratePassword(rowData.admin_id, rowData.name);
  };

  // =========== Permissions Dialog ===========
  const handleOpenPermissionDialog = async (user) => {
    if (!user) return;
    setSelectedUser(user);

    // 1) base perms from roleDefinition
    let rolePerms = [];
    try {
      const roleResp = await sendHttpCall('GET', `admin/roleDefinition/${user.type}`, null);
      if (roleResp.success && roleResp.data && Array.isArray(roleResp.data.permissions)) {
        rolePerms = roleResp.data.permissions;
      }
    } catch (err) {
      console.error('Error fetching roleDefinition:', err);
    }

    // 2) apply overwritten_permissions
    const diffArray = Array.isArray(user.overwritten_permissions) ? user.overwritten_permissions : [];
    const userPermittedRoutes = Array.isArray(user.permitted_routes) ? user.permitted_routes : [];
    const roleSet = new Set(rolePerms);
    diffArray.forEach((diffItem) => {
      if (!diffItem || diffItem.length < 2) return;
      const sign = diffItem[0];
      const slug = diffItem.substring(1);
      if (sign === '+') roleSet.add(slug);
      else if (sign === '-') roleSet.delete(slug);
    });

    let assignedSlugs = Array.from(roleSet);

    // 3) If user is the logged-in admin => read localStorage to see if we have updated perms
    if (String(user.admin_id) === String(loggedInAdminId)) {
      const storedPermsStr = localStorage.getItem('permissions');
      if (storedPermsStr) {
        // e.g. "manage-admins, boost-posts, etc."
        const storedSlugs = storedPermsStr.split(',').map(s => s.trim()).filter(Boolean);
        if (storedSlugs.length) {
          assignedSlugs = storedSlugs;
        }
      }
    }

    // 4) Build left (unassigned) / right (assigned)
    const assignedObjs = allPermissions.filter((p) => assignedSlugs.includes(p.slug));
    const unassignedObjs = allPermissions.filter((p) => !assignedSlugs.includes(p.slug));

    const permitted = [];
    const deactivated = [];

    allRoutes.forEach((route) => {
      if(userPermittedRoutes.includes(route.routeId)) {
        permitted.push(route);
      } else {
        deactivated.push(route);
      }
    });

    setCurrentPermittedRoutes(permitted);
    setCurrentDeactivatedRoutes(deactivated);

    setRight(assignedObjs);
    setLeft(unassignedObjs);
    setChecked([]);

    setOpenPermissionDialog(true);
  };

  const handlePermissionUpdate = async () => {
    if (!selectedUser) return;

    // final assigned slugs from right side
    const finalAssigned = right.map((p) => p.slug);

    // 1) re-fetch base perms
    let basePerms = [];
    try {
      const roleResp = await sendHttpCall('GET', `admin/roleDefinition/${selectedUser.type}`, null);
      if (roleResp.success && Array.isArray(roleResp.data.permissions)) {
        basePerms = roleResp.data.permissions;
      }
    } catch (err) {
      console.error('Error re-fetching roleDefinition:', err);
    }

    const baseSet = new Set(basePerms);
    const finalSet = new Set(finalAssigned);

    // plus => in finalSet not in baseSet
    const plusItems = [...finalSet].filter(slug => !baseSet.has(slug));
    // minus => in baseSet not in finalSet
    const minusItems = [...baseSet].filter(slug => !finalSet.has(slug));
    const diffArray = [
      ...plusItems.map(sl => `+${sl}`),
      ...minusItems.map(sl => `-${sl}`)
    ];

    // 2) Overwrite on server
    overwritePermissions(selectedUser.admin_id, diffArray);

    // 3) If this user is the currently logged in user => update localStorage
    if (String(selectedUser.admin_id) === String(loggedInAdminId)) {
      localStorage.setItem('permissions', finalAssigned.join(','));
    }
  };

  // TransferList logic
  const handleToggle = (item) => () => {
    const currentIndex = checked.indexOf(item);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleMoveRight = () => {
    if(permissionsTab === "permissions") {
      setRight([...right, ...checked]);
      setLeft(left.filter((i) => !checked.includes(i)));
    } else {
      setCurrentPermittedRoutes((prev) => [...prev, ...checked]);
      setCurrentDeactivatedRoutes((prev) => prev.filter((i) => !checked.includes(i)))
    }
    setChecked([]);
  };
  const handleMoveLeft = () => {
    if(permissionsTab === "permissions") {
      setLeft([...left, ...checked]);
      setRight(right.filter((r) => !checked.includes(r)));
    } else {
      setCurrentDeactivatedRoutes((prev) => [...prev, ...checked]);
      setCurrentPermittedRoutes((prev) => prev.filter((i) => !checked.includes(i)))
    }
    setChecked([]);
  };

  const handleRouteUpdate = async () => {
    if(!selectedUser) return;

    setOpenPermissionDialog(false);
    
    const data = {
      routes: currentPermittedRoutes.map((a) => a.routeId),
      adminId: selectedUser.admin_id,
    }

    await sendHttpCall(
      'PUT',
      "admin/updateUserRoutePermissions",
      data,
      null
    )
    .then(() => {
      fetchAdminUsers(status);
    });
  }

  // =========== Create/Edit User Dialogs ===========
  const handleOpenDialog = (rowData = null) => {
    if (rowData) {
      setEditMode(true);
      setSelectedUser(rowData);
      setNewUser({
        name: rowData.name || '',
        emailPrefix: rowData.emailPrefix || '',
        type: rowData.type || 'ADMIN'
      });
    } else {
      setEditMode(false);
      setSelectedUser(null);
      setNewUser({ name: '', emailPrefix: '', type: 'ADMIN' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleClosePermissionDialog = () => {
    setOpenPermissionDialog(false);
    setSelectedUser(null);
    setChecked([]);
    setLeft([]);
    setRight([]);
  };

  // Confirm => Deactivate or Regenerate
  const handleOpenAlertDialog = (rowData, type) => {
    setSelectedUser(rowData);
    setActionType(type);
    setOpenAlertDialog(true);
  };
  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
    setSelectedUser(null);
    setActionType(null);
  };

  // Default password generator
  const generatePassword = (name) => {
    return (name.split(' ')[0] || '').toLowerCase() + '123';
  };

  // TransferList UI
  const customList = (items) => (
    <Paper style={{ width: 250, height: 300, overflow: 'auto' }}>
      <List dense component="div">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.slug}-label`;
          return (
            <ListItem key={value.slug} button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  // =========== Render ===========
  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <button
          className={`${classes.button} ${classes.activeUsersButton} ${
            status === 'ACTIVE' ? 'selected' : ''
          }`}
          onClick={() => setStatus('ACTIVE')}
        >
          Active Users
        </button>
        <button
          className={`${classes.button} ${classes.inactiveUsersButton} ${
            status === 'INACTIVE' ? 'selected' : ''
          }`}
          onClick={() => setStatus('INACTIVE')}
        >
          Inactive Users
        </button>
        <button
          className={`${classes.button} ${classes.createUserButton}`}
          onClick={() => handleOpenDialog()}
        >
          Create New User
        </button>
      </div>

      <AdminTable
        data={adminUsers}
        status={status}
        onDeactivate={handleOpenAlertDialog}     // (rowData, 'deactivate')
        onActivate={(adminId) => handleActivate(adminId)} // Only adminId
        onEdit={handleOpenDialog}
        onPermissionUpdate={handleOpenPermissionDialog}
        onRegeneratePassword={handleOpenAlertDialog}
      />

      {/* CREATE/EDIT Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editMode ? 'Edit User' : 'Create New User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email Prefix"
            helperText="@goodspace.ai"
            fullWidth
            value={newUser.emailPrefix}
            onChange={(e) => setNewUser({ ...newUser, emailPrefix: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Type"
            select
            fullWidth
            value={newUser.type}
            onChange={(e) => setNewUser({ ...newUser, type: e.target.value })}
          >
            <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
            <MenuItem value="ADMIN">Admin</MenuItem>
            <MenuItem value="SALES">Sales</MenuItem>
            <MenuItem value="CUSTOMER_SUCCESS">Customer Success</MenuItem>
            <MenuItem value="ACCOUNTS">Accounts</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="MARKETING">Marketing</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCreateOrUpdateUser}>
            {editMode ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* PERMISSIONS OVERRIDE DIALOG */}
      <Dialog maxWidth="lg" open={openPermissionDialog} onClose={handleClosePermissionDialog}>
        <DialogTitle>
          Update User Permissions (Diff-based)
        </DialogTitle>
        <DialogContent>
        <ToggleButtonGroup
          color="primary"
          style={{ marginBottom: '10px' }}
          value={permissionsTab}
          exclusive
          onChange={handleTabChange}
          aria-label="Platform"
        >
          <ToggleButton value="permissions">Permissions</ToggleButton>
          <ToggleButton value="routes">Routes</ToggleButton>
        </ToggleButtonGroup>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {/* Left side => unassigned */}
            <Grid item>
              <DialogTitle style={{ margin: 0, paddingTop: 0 }}>
                All Permissions
              </DialogTitle>
              {customList(permissionsTab === "routes" ? currentDeactivatedRoutes : left)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleMoveRight}
                  disabled={checked.length === 0}
                  style={{ marginBottom: 20 }}
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleMoveLeft}
                  disabled={checked.length === 0}
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            {/* Right side => assigned */}
            <Grid item>
              <DialogTitle style={{ margin: 0, paddingTop: 0 }}>
                Assigned Permissions
              </DialogTitle>
              {customList(permissionsTab === "routes" ? currentPermittedRoutes : right)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePermissionDialog}>Cancel</Button>
          <Button onClick={permissionsTab === 'permissions' ? handlePermissionUpdate : handleRouteUpdate}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Deactivate/Regenerate Confirm */}
      <Dialog open={openAlertDialog} onClose={handleCloseAlertDialog}>
        <DialogTitle>
          {actionType === 'regenerate' ? 'Regenerate Password' : 'Deactivate User'}
        </DialogTitle>
        <DialogContent>
          {selectedUser && selectedUser.name ? (
            <>
              Are you sure you want to{' '}
              {actionType === 'regenerate' ? (
                <>
                  regenerate <strong>{selectedUser.name}</strong>'s password
                </>
              ) : (
                <>
                  deactivate <strong>{selectedUser.name}</strong>'s account
                </>
              )}
              ?
            </>
          ) : (
            <span>Loading...</span>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertDialog}>No</Button>
          <Button
            onClick={() => {
              if (actionType === 'regenerate') {
                handleRegeneratePasswordClick(selectedUser);
              } else {
                handleDeactivate(selectedUser.admin_id);
              }
              setOpenAlertDialog(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// ================== Subcomponent: AdminTable ==================
const AdminTable = ({
  data,
  status,
  onDeactivate,
  onActivate,
  onEdit,
  onPermissionUpdate,
  onRegeneratePassword
}) => {
  const classes = useStyles();

  return (
    <MaterialTable
      title={status === 'ACTIVE' ? 'Active Admin Users' : 'Inactive Admin Users'}
      columns={[
        { title: 'S.No.', field: 'serial', type: 'numeric', cellStyle: { width: 'fit-content' } },
        { title: 'Name', field: 'name' },
        { title: 'Admin ID', field: 'admin_id', type: 'numeric' },
        { title: 'Email', field: 'email_id' },
        { title: 'Type', field: 'type' },
        { title: 'Last Login', field: 'last_login' },
        {
          title: 'Action',
          render: (rowData) =>
            status === 'ACTIVE' ? (
              // =========== ACTIVE ROWS => show Deactivate button ===========
              <>
                {/* Deactivate => red button */}
                <IconButton
                  className={classes.deactivateButton}
                  onClick={() => onDeactivate(rowData, 'deactivate')}
                >
                  <PowerSettingsNewIcon />
                </IconButton>
                {/* Override Permissions => key icon */}
                <IconButton
                  className={classes.editButton}
                  onClick={() => onPermissionUpdate(rowData)}
                >
                  <VpnKeyIcon />
                </IconButton>
                {/* Edit => pencil icon */}
                <IconButton className={classes.editButton} onClick={() => onEdit(rowData)}>
                  <EditIcon />
                </IconButton>
                {/* Regenerate Password => refresh icon */}
                <IconButton
                  className={classes.refreshButton}
                  onClick={() => onRegeneratePassword(rowData, 'regenerate')}
                >
                  <RefreshIcon />
                </IconButton>
              </>
            ) : (
              // =========== INACTIVE ROWS => show Activate button ===========
              <>
                {/* Activate => green button */}
                <IconButton
                  className={classes.activateButton}
                  onClick={() => onActivate(rowData.admin_id)}
                >
                  <PowerSettingsNewIcon />
                </IconButton>
                {/* Edit => pencil icon */}
                <IconButton className={classes.editButton} onClick={() => onEdit(rowData)}>
                  <EditIcon />
                </IconButton>
                {/* Regenerate Password => refresh icon */}
                <IconButton
                  className={classes.refreshButton}
                  onClick={() => onRegeneratePassword(rowData, 'regenerate')}
                >
                  <RefreshIcon />
                </IconButton>
              </>
            )
        }
      ]}
      data={data}
      options={{
        search: true,
        paging: false
      }}
    />
  );
};

export default ViewAllAdmins;
