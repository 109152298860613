/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';

import AudioPlayer from './AudioPlayer';
import moment from 'moment';
import styles from './InterviewDetails.module.scss';
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { sendHttpCall } from 'shareService/httpService';
import ReactModal from 'react-modal';
import JobCard from 'components/JobCard/JobCard';
import { Polar } from 'react-chartjs-2';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

export default function EveryTable(props) {
  const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [extraData, setExtraData] = useState(null);
  // const [totalCount, setTotalCount] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);
  // const [finishedInterviewsCount, setFinishedInterviewsCount] = useState(0);
  // const [bloodDonorRequestId, setBloodDonorRequestId] = useState(null);
  const [toastMessage, setToastMessage] = useState('');
  const [deleteInterviewModal, setDeleteInterviewModal] = useState(false);

  const user = {
    type: localStorage.getItem('user_type') || 'ADMIN',
  }

  const permissions = JSON.stringify(localStorage.getItem("permissions")) || "";

  const generateRandomColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
        const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
            Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)}, 0.5)`; // Random RGBA color
        colors.push(color);
    }
    return colors;
  };

  const [PolarChartData, setPolarChartData] = useState({
    labels: [],
    datasets: [
        {
            label: "Ratings (Out of 10)",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        },
    ],
  });
      
  const options = {
    responsive: true,
    scales: {
        r: {
            ticks: {
                min: 10,
                color: "#000", // Tick color
            },
            grid: {
                color: "#ccc", // Gridline color
            },
        },
    },
    plugins: {
        legend: {
            position: "top", // Position of the legend
            labels: {
                color: "#000", // Legend label color
            },
        },
    },
  };

      
  useEffect(() => {
    if (data?.interviewScores?.behavioral) {
        // Extract labels and scores
      const labels = data.interviewScores.behavioral.map((a) => a.skill);
      const score = data.interviewScores.behavioral.map((item) => item.score);

      const colors = generateRandomColors(labels.length);

      setPolarChartData({
          labels,
          datasets: [
              {
                  label: "Behavioral Skills",
                  data: score,
                  backgroundColor: colors,
                  borderColor: colors.map((color) => color.replace("0.5", "1")), // Solid border
                  borderWidth: 1,
              },
          ],
      });
    }
  }, [data]);

  const getRadarChartData = () => {
    if (!data.interviewScores || !data.interviewScores.behavioral) return {};
  
    const labels = data.interviewScores.behavioral.map((item) => item.skill);
    const scores = data.interviewScores.behavioral.map((item) => item.score);
    const reasons = data.interviewScores.behavioral.map((item) => item.reason);
  
    return {
      labels,
      datasets: [
        {
          label: 'Behavioral Skills',
          data: scores,
          backgroundColor: 'rgba(47, 134, 255, 0.2)',
          borderColor: 'rgba(47, 134, 255, 0.8)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(47, 134, 255, 1)',
        },
      ],
      meta: { reasons }, // Attach reasons for tooltip
    };
  };
  
  const radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        angleLines: { color: '#d8e2f1' },
        grid: { color: '#d8e2f1' },
        ticks: {
          color: '#2f86ff',
          font: { size: 14 },
        },
        pointLabels: { color: '#333', font: { size: 14, weight: 'bold' } },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: { color: '#333', font: { size: 14, weight: 'bold' } },
      },
      tooltip: {
        backgroundColor: 'rgba(47, 134, 255, 0.9)',
        titleColor: '#fff',
        bodyColor: '#fff',
        callbacks: {
          afterLabel: function (tooltipItem) {
            const reasons = getRadarChartData().meta.reasons;
            return `Reason: ${reasons[tooltipItem.dataIndex]}`;
          },
        },
      },
    },
  };
  
  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const getLocaleTime = (time) => {
    if (time) {
      if (props.match.params.type === 'active_member')
        return moment(new Date(time + ' UTC')).format('DD/MM/YYYY h:mm:ss A');
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    }
    return '';
  };
  const startDate = useRef(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const endDate = useRef(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date()).format('YYYY-MM-DD')
  );

  const openUserPage = (userId, social) => {
    sessionStorage.setItem('everyTablePage', page);
    sessionStorage.setItem('everyTableLimit', limit);
    props.history.push(`/account/${userId}${social ? '?type=SOCIAL' : ''}`);
  };
  // const columns = [
  //   {
  //     title: 'S No.',
  //     cellStyle: {
  //       position: 'sticky',
  //       left: 0,
  //       backgroundColor: '#FFF'
  //     },
  //     headerStyle: {
  //       position: 'sticky',
  //       left: 0,
  //       backgroundColor: '#FFF'
  //     },
  //     render: (rowData) => page * 20 + rowData.tableData.id + 1
  //   },
  //   {
  //     title: 'Name',
  //     field: 'name',
  //     render: (rowData) => (
  //       <div
  //         style={{ cursor: 'pointer' }}
  //         onClick={() => {
  //           openUserPage(rowData.userDetails.user_id);
  //         }}>
  //         {rowData.userDetails.name}
  //       </div>
  //     )
  //   },
  //   {
  //     title: 'Job Title',
  //     field: 'job_title',
  //     render: (rowData) => rowData.jobDetails?.title
  //   },

  //   {
  //     title: 'Message Count',
  //     field: 'messageCount',
  //     render: (rowData) => rowData.messageCount - 1
  //   },

  //   {
  //     title: 'Status',
  //     render: (rowData) => rowData.interviewStatus
  //   },
  //   {
  //     title: 'Stop Reason',
  //     render: (rowData) =>
  //       rowData.adminRemarks?.replace(
  //         'INTERVIEW ENDED BY USER',
  //         'Ended by user'
  //       )
  //   },
  //   {
  //     title: 'Started At',
  //     field: 'startedAt',
  //     type: 'datetime',
  //     headerStyle: {
  //       position: 'static',
  //       backgroundColor: '#FFF'
  //     },
  //     render: (rowData) => getLocaleTime(rowData.startedAt)
  //   },
  //   {
  //     title: 'Finished At',
  //     field: 'completedAt',
  //     type: 'datetime',
  //     headerStyle: {
  //       position: 'static',
  //       backgroundColor: '#FFF'
  //     },
  //     render: (rowData) => getLocaleTime(rowData.completedAt)
  //   },
  //   {
  //     title: 'Grade',
  //     field: 'grade'
  //   }
  // ];
    
  function camelCaseToNormal(text) {
    return text
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
        .replace(/([A-Z][a-z])/g, (match) => match.charAt(0).toUpperCase() + match.slice(1)) // Capitalize each word
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter of the string
  }

  // const getTableLabel = () => {
  //   let { type } = props.match.params;
  //   const countryCode = window.location.href.split('?countryCode=')[1];

  //   if (type === 'social_connect_requests') {
  //     return 'Social Connect Requests Table';
  //   }
  //   if (type === 'social_connect_requests_accepted') {
  //     return 'Social Connect Requests Accepted Table';
  //   }
  //   if (type === 'social_connect_requests_rejected') {
  //     return 'Social Connect Requests Rejected Table';
  //   }
  //   if (type === 'active_member') {
  //     return 'New Users Table';
  //   }
  //   if (type === 'active_member-country') {
  //     return `New Users Table ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'active_hirers') {
  //     return 'Active Hirers Table';
  //   }
  //   if (type === 'active_hirers-country') {
  //     return `Active Hirers Table ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'verified_hirers') {
  //     return 'Verified Hirers Table';
  //   }
  //   if (type === 'verified_hirers-country') {
  //     return `Verified Hirers Table ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'reportedJobs') {
  //     return 'Reported Jobs';
  //   }
  //   if (type === 'feedback') {
  //     return 'Feedbacks Given';
  //   }
  //   if (type === 'status1') {
  //     return 'Status 1 Users';
  //   }
  //   if (type === 'status1-country') {
  //     return `Status 1 Users ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'status2') {
  //     return 'Status 2 Users';
  //   }
  //   if (type === 'status2-country') {
  //     return `Status 2 Users ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'feedback_conversions') {
  //     return 'Users Converted From Feedback Requests';
  //   }
  //   if (type === 'by_country') {
  //     return `Users From ${countries[props.match.params.period]}`;
  //   }
  //   if (type === 'device_type') {
  //     return `Users From ${props.match.params.period}`;
  //   }
  //   if (type === 'by_campaign') {
  //     return `Users From ${props.match.params.period} Campaign`;
  //   }
  //   if (type === 'survey_sent') {
  //     return 'Survey Messages Sent';
  //   }
  //   if (type === 'survey_link_clicks') {
  //     return 'Survey Link Clicks';
  //   }
  //   if (type === 'survey_received') {
  //     return 'Survey Received';
  //   }
  //   if (type === 'closedJobs') {
  //     return 'Closed Jobs';
  //   }
  //   if (type === 'deactivated') {
  //     return 'Deactivated Users';
  //   }
  //   if (type === 'deactivated-country') {
  //     return `Deactivated Users ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'inactive_hirers') {
  //     return 'Inactive Hirers';
  //   }
  //   if (type === 'active_hirers-2') {
  //     return 'Active Hirers';
  //   }
  //   if (type === 'blocked') {
  //     return 'Blocked Users';
  //   }
  //   if (type === 'blocked-country') {
  //     return `Blocked Users ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   }
  //   if (type === 'transactions') {
  //     return 'Transactions';
  //   }
  //   if (type === 'failed-transactions') {
  //     return 'Failed Transactions';
  //   }
  //   if (type === 'created-orders') {
  //     return 'Created Orders';
  //   }

  //   if (type === 'survey_conversions') {
  //     return 'Users Converted From Survey Messages';
  //   }
  //   if (type === 'referrals_campus_ambassadors')
  //     return 'Referrals (Campus Ambassadors)';
  //   if (type === 'referrals_others') return 'Referrals (Others)';
  //   if (type === 'status2Hirer') return 'Hirer Status 2';
  //   if (type === 'status2Hirer-country')
  //     return `Hirer Status 2 ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   if (type === 'status2Member') return 'Job Seeker Status 2';
  //   if (type === 'status2Member-country')
  //     return `Job Seeker Status 2 ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  //   if (type === 'status4') return 'Status 4 Only Members';
  //   if (type === 'find-blood-conversions') return 'Find Blood Conversions';
  //   if (type === 'find-blood-only-users') return 'Find Blood Only Users';
  //   if (type === 'blood-donor-requests') return 'Blood Donor Requests';
  //   if (type === 'blood-donor-requests-accepted')
  //     return 'Blood Donor Requests Accepted';
  //   if (type === 'blood-donor-requests-rejected')
  //     return 'Blood Donor Requests Rejected';
  //   if (type === 'blood-donor-requests-admin')
  //     return 'Blood Donor Requests Admin';
  //   if (type === 'resume-downloaded') return 'Resume Generated';
  //   if (type === 'resume-audio-uploaded') return 'Resume Audio Uploaded';
  //   if (type === 'status4-country')
  //     return `Status 4 Only Members ${
  //       countryCode === '+1' ? 'USA' : countryCode === '+44' ? 'UK' : ''
  //     }`;
  // };
  
  const getEveryOtherTableData = async () => {
    const { interviewId } = props.match.params; 
    const userId = props.match.params.userId; 
    const offerId = props.match.params.offerId; 
    const roundNumber = props.match.params.roundNumber || 1; 

    let url;
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      if (interviewId) {
        url = `interview/${interviewId}`;
      } else if (userId && offerId) {
            
        url = `interview/details_by_id?userId=${userId}&offerId=${offerId}&roundNumber=${roundNumber}`;
      }
      sendHttpCall('GET', url, null, props).then((response) => {
        setIsLoading(false);
        setData(response.data);
      });
    });
  };
  const getData = async () => {
    getEveryOtherTableData();
  };
  useEffect(() => {
    getData();
    sessionStorage.removeItem('everyTablePage');
    sessionStorage.removeItem('everyTableLimit');
  }, [page]);
  useEffect(() => {
    getData();
  }, []);
  const toDoubleDecimalPlaces = (num) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const handleDeleteInterview = () => {
    sendHttpCall('DELETE', "interview/delete-interview", {
      offerId: data?.offerId,
      userId: data?.userId,
      type: data?.type,
      roundNumber: data?.roundNumber
    })
      .then((res) => {
        showToast(res.message);
        setTimeout(() => {
          props.history.goBack();
        }, 500);
      })
      .catch((err) => console.log(err)); 
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Interview Details</div>
        <div className={styles.sub}>
          <div className={styles.subtitle}>
            <Link to={`/account/${data?.userDetails?.user_id}`}>
              {data?.userDetails?.name}
            </Link>{' '}
            interviewing for{' '}
            <span onClick={() => setShowJobDetails(true)}>
              {data?.jobDetails?.title}
            </span>
          </div>
          {(permissions.includes("delete-interview")) && (
            <div className={styles.deleteButton} onClick={() => setDeleteInterviewModal(prev => !prev)}>Delete Interview</div>
          )}
        </div>
        <div className={styles.CandidateStatus}>
          Candidate status{' '}: 
          <span onClick={() => setShowJobDetails(true)}>
            {data?.isRecomended}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.chatboxContainer}>
            <div className={styles.chatBoxMessages}>
              {data.messages?.map((item) =>
                item.sentBy === 'HR' ? (
                  <MessageReceived
                    text={item.message}
                    date={new Date(item.createdAt)}
                    playAudio={setAudioSrc}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : item.sentBy === 'USER' ? (
                  <MessageSent
                    text={item.message}
                    playAudio={setAudioSrc}
                    date={new Date(item.createdAt)}
                    playingAudioLink={audioSrc}
                    audioLink={item?.messageAudio?.link}
                  />
                ) : null
              )}
            </div>
          </div>
          <div className={`${styles.summaryContainer} ${styles.blueInfoDiv}`}>
            <div className={styles.summaryTitle}>Summary</div>
            <div className={`${styles.spanDiv} ${styles.summaryText}`}>{data?.interviewScores?.overall?.reason}</div>
          </div>
        </div>
      </div>{' '}

      {data.addOns && data.addOns.length > 0 && (
        <div className={`${styles.container3} ${styles.addOnStyling}`}>
          <div className={`${styles.spanDiv} ${styles.title2}`}>AddOns : </div>
          <div className={styles.addOnsContainer}>
            {data.addOns.map((addon, index) => (
              <div key={index} className={styles.addOnItem}>
                {addon.typeName}
              </div>
            ))}
          </div>
        </div>
      )}


      {data.screeningQuestions && data.screeningQuestions.length > 0 && (
        <div className={styles.container3}>
          <div className={styles.title2}>Screening Questions</div>
          <div className={styles.interviewScores2Container}>
            {data.screeningQuestions.map((item, index) => (
              <div key={index} className={styles.interviewScores2}>
                <div className={styles.interviewScoreSno}>{index + 1}.</div>
                <div className={styles.questions}>
                  <h3 className={styles.interviewScoreLabel}>
                    Question: {item.question}
                  </h3>
                  <div className={styles.blueInfoDiv}>
                    <div className={`${styles.spanDiv} ${styles.questionSpanDiv}`}>
                      Answer Provided: <span className={item.isAnsProvided ? styles.success : styles.danger}>{item.isAnsProvided ? "Yes" : "No"}</span>
                    </div>
                    <p className={styles.interviewScoreValue}>
                      Analysis: {item.analysis}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {data.interviewScores && (
        <div className={styles.container3}>
          <div className={styles.title2}>Interview Scores</div>
          <div className={`${styles.interviewScores2Container} ${styles.skillRatingContainer}`}>
            {/* <div className={styles.interviewScoreCategory}>
              <div className={styles.interviewScoreCategoryTitle}>
                Technical
              </div>
              {data.interviewScores.technical.map((item, index) => (
                <div className={styles.interviewScores2}>
                  <div className={styles.interviewScoreSno}>{index + 1}.</div>
                  <div>
                    <div className={styles.interviewScoreLabel}>
                      {item.skill} - <span>{item.score}</span>
                    </div>
                    <div className={styles.interviewScoreValue}>
                      {item.reason}
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div className={styles.SkillScoreList}>
              { data?.interviewScores?.technical?.map((skill) => (
                  <div className={styles.Skill}>
                      <h3>
                          {skill.skill} 
                          <span>
                              {/* {["", "", "", "", ""].map((a, i) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <RatingStar key={i} color={i < skill.score/2 ? "#FFA800" : "#C4C4C4"} />
                              ))} */}
                          </span>
                      </h3>
                      <div className={styles.scoreAndReview}>
                          <div className={styles.Score}>
                              <h4>Score</h4>
                              <div className={styles.circularBar}>
                                <CircularProgressbar
                                  value={skill.score*10}
                                  text={`${skill.score*10}`}
                                  styles={buildStyles({
                                    textColor: "#111827", // Text color
                                    pathColor: "#4caf50", // Progress bar color
                                    trailColor: "#d6d6d6",
                                    textSize: "36px",
                                    fontWeight: '500' // Background color
                                  })}
                                />
                              </div>
                          </div>
                          <div className={`${styles.blueInfoDiv} ${styles.Review}`}>
                              <div className={`${styles.spanDiv}`}>Goodspace rating: <span className={skill?. score > 8 ? styles.success : skill?.score >= 5 ? styles.warning : styles.danger}>{skill?.score > 8 ? "Excellent" : skill?.score >= 5 ? "Good" : "Poor"}</span></div>
                              <p>{skill.reason}</p>
                          </div>
                      </div>
                  </div>
              ))}
            </div>
            {/* <div className={styles.interviewScoreCategory}>
              <div className={styles.interviewScoreCategoryTitle}>
                Behavioral & Situational Assessment
              </div>
              {data.interviewScores.behavioral.map((item, index) => (
                <div className={styles.interviewScores2}>
                  <div className={styles.interviewScoreSno}>{index + 1}.</div>
                  <div>
                    {' '}
                    <div className={styles.interviewScoreLabel}>
                      {item.skill} - <span>{item.score}</span>
                    </div>
                    <div className={styles.interviewScoreValue}>
                      {item.reason}
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div className={styles.blueInfoDiv}>
              <div className={`${styles.interviewScoreCategoryTitle} ${styles.spanDiv}`}>
                Overall <span className={data.interviewScores.overall.score > 8 ? styles.success : data.interviewScores.overall.score >= 5 ? styles.warning : styles.danger}>{data.interviewScores.overall.score}</span>
              </div>
              <p className={styles.interviewScores2}>
                {data.interviewScores.overall.reason}
              </p>
            </div>
          </div>
        </div>
      )}     

      {data.interviewScores && data.interviewScores.behavioral && (
        <div className={`${styles.container3} behavioralAssessment`}>
                <div className={styles.title2}>Behavioral & Situational Assessment</div>
          <div className={styles.behaviour}>
            <div className={styles.ratingGrid}>
              { data?.interviewScores?.behavioral?.map((a) => (
                  <div className={`${styles.blueInfoDiv}`}>
                    <div>
                      <h3 className={styles.spanDiv}>{a.skill}</h3>
                      <div className={`${styles.spanDiv}`}>Goodspace rating <span className={a.score > 6 ? styles.success : a.score >= 3 ? styles.warning : styles.danger}>{a.score > 6 ? "Good" : a.score >= 3 ? "Average" : "Poor"}</span></div>
                    </div>
                    <p>{a.reason}</p>
                  </div>
              ))}
            </div>
            <div className={styles.chartContainer}>
              <Polar data={PolarChartData} options={options} />
              {/* <Radar data={getRadarChartData()} options={radarOptions} /> */}
            </div>
          </div>
        </div>
      )}




{data.proctoring && (
  <div className={styles.container3}>
    <div className={styles.title2}>Proctoring Assessment</div>
    {/* <div className={styles.proctoringContainer}>
      <div className={styles.proctoringItem}>
        <span className={styles.proctoringLabel}>Tab Switches:</span>
        <span className={styles.proctoringValue}>{data.proctoring.tabSwitch?.frequency || 0}</span>
      </div>
      <div className={styles.proctoringItem}>
        <span className={styles.proctoringLabel}>Head Movements:</span>
        <span className={styles.proctoringValue}>{data.proctoring.headMonitoring?.unusualMovementCount || 0}</span>
      </div>
      <div className={styles.proctoringItem}>
        <span className={styles.proctoringLabel}>Multiple Faces Detected:</span>
        <span className={styles.proctoringValue}>{data.proctoring.faceDetection?.multipleFaceCount || 0}</span>
      </div>
      <div className={styles.proctoringItem}>
        <span className={styles.proctoringLabel}>Clipboard Activities:</span>
        <span className={styles.proctoringValue}>
          Copy: {data.proctoring.clipboardActivity?.copyCount || 0}, Paste: {data.proctoring.clipboardActivity?.pasteCount || 0}
        </span>
      </div>
      <div className={styles.proctoringItem}>
        <span className={styles.proctoringLabel}>Alerts:</span>
        <span className={styles.proctoringValue}>{data.proctoring.alerts?.length || 0}</span>
      </div>
    </div> */}
    <div>
      <div className={styles.CheatingInfo}>
          <div className={`${styles.blueInfoDiv}`}>
              <div className={`${styles.spanDiv}`}>Leaving Tab <span className={!data?.proctoring?.tabSwitch?.thresholdCrossed ? styles.success : styles.danger}>{data?.proctoring?.tabSwitch?.frequency} Times</span></div>
              <div className={`${styles.spanDiv}`}>Multiple Faces <span className={!data?.proctoring?.faceDetection?.thresholdCrossed ? styles.success : styles.danger}>{!data?.proctoring?.faceDetection?.thresholdCrossed ? "Not Detected" : "Detected"}</span></div>
              <div className={`${styles.spanDiv}`}>Clipboard Activity <span className={(!data?.proctoring?.clipboardActivity?.thresholdCrossed) ? styles.success : styles.danger}>{(!data?.proctoring?.clipboardActivity?.thresholdCrossed) ? "Not Detected" : "Detected"}</span></div>
              <div className={`${styles.spanDiv}`}>Head Movements <span className={!data?.proctoring?.headMonitoring?.thresholdCrossed ? styles.success : styles.danger}>{data?.proctoring?.headMonitoring?.unusualMovementCount} Times</span></div>
          </div>
      </div>
    </div>
  </div>
)}

      {data.pronunciationAssessment?.pronunciationScore && (
        <div className={styles.container}>
          <div className={styles.title2}>Pronunciation Assessment</div>

          <div className={styles.interviewScores}>
            <div className={styles.visualScoreing}>
              <div className={styles.score}>
                  <h4>Pronunciation Score</h4>
                  <div>
                    <CircularProgressbar
                        value={data?.pronunciationAssessment?.pronunciationScore}
                        text={`${Math.round(data?.pronunciationAssessment?.pronunciationScore)}`}
                        styles={buildStyles({
                            textColor: "#111827", // Text color
                            pathColor: "#4caf50", // Progress bar color
                            trailColor: "#d6d6d6",
                            textSize: "36px",
                            fontWeight: '500' // Background color
                        })}
                    />
                  </div>
              </div>
              <div className={styles.subRating}>
                  <h4>Score Breakdown</h4>
                  <div className={styles.subRatinglist}>
                      { Object.keys(data?.pronunciationAssessment || {}).map((keys) => 
                          (keys !== "pronunciationScore" && keys !== "isUnlocked") && (
                              <div className={styles.ratingData}>
                                  <div className={styles.info}>
                                      <h5>{camelCaseToNormal(keys)}</h5>
                                      <div>{toDoubleDecimalPlaces(data?.pronunciationAssessment[keys]) || 0}/100</div>
                                  </div>
                                  {/* <ProgressBar
                                    progress={toDoubleDecimalPlaces(data?.pronunciationAssessment[keys]) || 0} 
                                    // className={`${styles.customProgressBar} ${data?.pronunciationAssessment[keys] > 80 ? styles.success : data?.pronunciationAssessment[keys] > 60 ? styles.warning : styles.danger}`}
                                  /> */}
                                  <div className={styles.progressContainer}>
                                    <div
                                        className={`${styles.progressBar}  ${data?.pronunciationAssessment[keys] > 80 ? styles.success : data?.pronunciationAssessment[keys] > 60 ? styles.warning : styles.danger}`}
                                        style={{ width: `${data?.pronunciationAssessment[keys]}%` }}
                                    ></div>
                                  </div>
                              </div>
                          )
                      )}
                  </div>
              </div>
            </div>
            {/* <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.accuracyScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Accuracy</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.fluencyScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Fluency</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.completenessScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Completeness</div>
            </div>
            <div className={styles.interviewScoreContainer}>
              <div className={styles.interviewScore}>
                {toDoubleDecimalPlaces(
                  data.pronunciationAssessment?.pronunciationScore
                )}
              </div>
              <div className={styles.interviewScoreTitle}>Pronunciation</div>
            </div> */}
          </div>
        </div>
      )}{' '}
       {data.userImages?.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title2}>User Images</div>
          <div className={styles.userImages}>
            {data.userImages?.slice(0, 10).map((item) => (
              <img src={item} alt="" className={styles.userImg} />
            ))}
          </div>
        </div>
      )}
      {data.userVideos?.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title2}>User Videos</div>
          <div className={styles.userImages}>
            {data.userVideos?.slice(0, 10).map((item) => (
              <video src={item} alt="" controls className={styles.userImg} />
            ))}
          </div>
        </div>
      )} 
      <AudioPlayer src={audioSrc} onPause={() => setAudioSrc(null)} />
      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
      {data?.jobDetails && (
        <ReactModal
          onRequestClose={() => setShowJobDetails(false)}
          isOpen={showJobDetails}
          className={styles.jobModalContainer}
          overlayClassName="active_jobs__modal-overlay2">
          <div className={styles.jobModal}>
            <JobCard data={data?.jobDetails} hideButtons />
          </div>
        </ReactModal>
      )}
      <ReactModal
        onRequestClose={() => setDeleteInterviewModal(prev => !prev)}
        isOpen={deleteInterviewModal}
        className={styles.jobModalContainer}
        overlayClassName="active_jobs__modal-overlay2"
      >
        <div className={styles.deleteModal}>
          <div>Are you sure. You want to delete the Interview?</div>
          <div className={styles.buttonGrp}>
            <div onClick={() => setDeleteInterviewModal(prev => !prev)}>No</div>
            <div onClick={handleDeleteInterview}>Yes</div>
          </div>
        </div>
      </ReactModal>
    </div>
    
  );

  
}


const MessageSent = ({
  text,
  date,
  audioLink,
  playAudio,
  playingAudioLink
}) => (
  <div className={styles.messageSent}>
    <div className={styles.messageSentText}>{text}</div>
    <div className={styles.messageSentExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageSentTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);

const MessageReceived = ({
  text,
  date,
  audioLink,
  playAudio,
  playingAudioLink
}) => (
  <div className={styles.messageReceived}>
    <div className={styles.messageReceivedText}>{text}</div>
    <div className={styles.messageReceivedExtra}>
      {audioLink && (
        <div
          className={styles.messageAudioButton}
          onClick={() => playAudio(audioLink)}>
          <AudioIcon playing={playingAudioLink === audioLink} />
        </div>
      )}
      <div className={styles.messageReceivedTime}>
        {moment(date).format('hh:mm A')}
      </div>
    </div>
  </div>
);
const AudioIcon = ({ playing }) => {
  if (playing)
    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1657 2.14424C12.8728 2.50021 13 3.27314 13 3.7446V20.2561C13 20.7286 12.8717 21.4998 12.1656 21.8554C11.416 22.2331 10.7175 21.8081 10.3623 21.4891L4.95001 16.6248H3.00001C1.89544 16.6248 1.00001 15.7293 1.00001 14.6248L1 9.43717C1 8.3326 1.89543 7.43717 3 7.43717H4.94661L10.3623 2.51158C10.7163 2.19354 11.4151 1.76635 12.1657 2.14424ZM11 4.63507L6.00618 9.17696C5.82209 9.34439 5.58219 9.43717 5.33334 9.43717H3L3.00001 14.6248H5.33334C5.58015 14.6248 5.81823 14.716 6.00179 14.881L11 19.3731V4.63507Z"
            fill="#000000"></path>{' '}
          <path
            d="M16.0368 4.73124C16.1852 4.19927 16.7368 3.88837 17.2688 4.03681C20.6116 4.9696 23 8.22106 23 12C23 15.779 20.6116 19.0304 17.2688 19.9632C16.7368 20.1117 16.1852 19.8007 16.0368 19.2688C15.8884 18.7368 16.1993 18.1852 16.7312 18.0368C19.1391 17.3649 21 14.9567 21 12C21 9.04332 19.1391 6.63512 16.7312 5.96321C16.1993 5.81477 15.8884 5.2632 16.0368 4.73124Z"
            fill="#000000"></path>{' '}
          <path
            d="M16.2865 8.04192C15.7573 7.88372 15.2001 8.18443 15.0419 8.71357C14.8837 9.24271 15.1844 9.79992 15.7136 9.95812C16.3702 10.1544 17 10.9209 17 12C17 13.0791 16.3702 13.8456 15.7136 14.0419C15.1844 14.2001 14.8837 14.7573 15.0419 15.2865C15.2001 15.8156 15.7573 16.1163 16.2865 15.9581C17.9301 15.4667 19 13.8076 19 12C19 10.1924 17.9301 8.53333 16.2865 8.04192Z"
            fill="#000000"></path>{' '}
        </g>
      </svg>
    );
  else
    return (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 4.63513L6.00618 9.17702C5.82209 9.34445 5.58219 9.43723 5.33334 9.43723H3L3.00001 14.6248H5.33334C5.58015 14.6248 5.81823 14.7161 6.00179 14.8811L11 19.3732V4.63513ZM12.1657 2.1443C12.8728 2.50027 13 3.2732 13 3.74466V20.2561C13 20.7286 12.8717 21.4999 12.1656 21.8555C11.416 22.2331 10.7175 21.8082 10.3623 21.4891L4.95001 16.6248H3.00001C1.89544 16.6248 1.00001 15.7294 1.00001 14.6248L1 9.43723C0.999998 8.33266 1.89543 7.43723 3 7.43723H4.94661L10.3623 2.51165C10.7163 2.1936 11.4151 1.76641 12.1657 2.1443Z"
            fill="#000000"></path>{' '}
        </g>
      </svg>
    );
};
