import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        permissions: [],
        loaded: false
    }
}

const permissionsReducer = createSlice({
    name: "permissions",
    initialState: initialState,
    reducers: {
        setUserPermissions: (state, action) => {
            state.data = {
                ...state.data,
                permissions: action.payload,
            }
        }
    }
});

export const { permissions } = permissionsReducer.actions;
export default permissionsReducer.reducer;