import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { TotalUsers } from 'views/Dashboard/components';
import EditJobPostV2 from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/EditJobPostV2';
import Pagination from '@material-ui/lab/Pagination';
import styles from './JobCard.module.scss';
import { useJobBoostMaster } from '../../contexts/JobBoostMasterContext';
import TextField from '@mui/material/TextField';
import {
  FaClipboard,
  FaLink,
  FaEye,
  FaGift,
  FaMoneyBillAlt,
  FaGgCircle,
  FaLaptop,
  FaAndroid,
  FaApple,
  FaFilter
} from 'react-icons/fa';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { sendHttpCall } from 'shareService/httpService';
// import FilterListIcon from '@mui/icons-material/FilterList';
import { object } from 'underscore';
import { IconButton } from '@mui/material';
import ViewOfferCandidatesDetails from 'components/ViewOfferCandidatesDetails/ViewOfferCandidatesDetails';
import Markdown from 'react-markdown';

const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatTime = (time) => {
  const date = new Date(time);
  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};

const userPermissions = localStorage.getItem("permissions");
console.log(userPermissions)

export default function JobCard({
  data,
  status,
  refreshData,
  deleteJob,
  rejectJob,
  approveJob,
  setJobOnHold,
  showToast,
  currentPage,
  boostJob,
  toggleAutoRecruiter,
  hideButtons
}) {
  const [viewStats, setViewStats] = useState(false);
  const [showBoostJobModal, setShowBoostJobModal] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectReasonCustom, setRejectReasonCustom] = useState('');
  const [showRejectJobModal, setShowRejectJobModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checked, setChecked] = useState(!!data?.autoRecruiterEnabled);
  const [user8, setUser8] = useState(null);
  const [status3Ft, setStatus3Ft] = useState(null);
  const [isStatus3FtLoading, setIsStatus3FtLoading] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [whatsappStats, setWhatsappStats] = useState('');
  const [emailStats, setEmailStats] = useState('');
  const [previewJD, setPreviewJD] = useState(false);
  //Application states
  const [npsData, setNpsData] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const candidateDetailsCardRef = useRef(null);

  const { isOpen, openBoostDrawer, closeBoostDrawer } = useJobBoostMaster();

  const userType = localStorage.getItem('user_type');
  const adminId = localStorage.getItem('admin_id');

  const fetchStats = async (offerId) => {
    try {
      const response = await sendHttpCall('GET', `/job/stats/${offerId}`);

      console.log('Response received:', response);

      // Assuming response is structured as expected
      if (response && response.whatsappStats) {
        setWhatsappStats(response.whatsappStats);
        setEmailStats(response.emailStats);
        setViewStats(true);
      } else {
        showToast('No stats available.');
      }
    } catch (error) {
      console.error('Error fetching communication stats:', error);
      showToast('Failed to fetch communication stats.');
    }
  };

  const StatsComponent = ({
    viewStats,
    setViewStats,
    whatsappStats,
    emailStats
  }) => {
    // Prepare data for WhatsApp display
    console.log(whatsappStats);
    console.log(emailStats);
    const whatsappDisplayData = Object.entries(whatsappStats).map(
      ([status, count]) => ({
        label:
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' '),
        count
      })
    );

    // Prepare data for Email display
    const emailDisplayData = Object.entries(emailStats).map(
      ([status, count]) => ({
        label:
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' '),
        count
      })
    );

    const sortedWhatsAppData = [...whatsappDisplayData].sort((a, b) => {
      if (a.label === 'Total') return -1;
      if (b.label === 'Total') return 1;
      if (a.label === 'Applied') return -1;
      if (b.label === 'Applied') return 1;
      return 0;
    });

    const sortedEmailData = [...emailDisplayData].sort((a, b) => {
      if (a.label === 'Total') return -1;
      if (b.label === 'Total') return 1;
      if (a.label === 'Applied') return -1;
      if (b.label === 'Applied') return 1;
      return 0;
    });

    const getCardStyle=(label)=>{
      if(label==='Total') return {backgroundColor:'#297bca'}
      if(label==="Applied") return {backgroundColor:'#4CAF50'}
    }

    const getTextStyle = (label) => {
      if (label === 'Total' || label === 'Applied') return { color: '#fff' };
      return {}
    };
    const getTitleStyle = (label) => {
      if (label === 'Total' || label === 'Applied') return { color: '#fff' };
      return {color:'#297bca'}
    };

    return (
      viewStats && (
        <ReactModal
          isOpen={viewStats}
          onRequestClose={() => setViewStats(false)}
          className="active_jobs__modal-modal2"
          overlayClassName="active_jobs__modal-overlay2">
          <div className="active_jobs__modal2">
            <h3 style={{ fontWeight: 'bold' }}>WhatsApp Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                {sortedWhatsAppData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count}
                    cardStyle={getCardStyle(data.label)}
                    titleStyle={getTitleStyle(data.label)}
                    textStyle={getTextStyle(data.label)} />
                  </Grid>
                ))}
              </Grid>
            </div>
            <hr style={{ margin: '40px 0' }} />{' '}
            {/* Separator between sections */}
            <h3 style={{ fontWeight: 'bold' }}>Email Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                {sortedEmailData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count} 
                    cardStyle={getCardStyle(data.label)}
                    titleStyle={getTitleStyle(data.label)}
                    textStyle={getTextStyle(data.label)} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </ReactModal>
      )
    );
  };

  const toggleDescriptionExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const openJobPage = (offerId) => {
    window.open(`/dashboard#/offer/${offerId}`);
  };

  function DropdownMenu({ data, showToast }) {
    const handleCopyOfferId = () => {
      navigator.clipboard.writeText(data.offerId).then(
        () => showToast('Offer ID copied to clipboard!'),
        (err) => console.error('Could not copy text: ', err)
      );
    };

    const handleCopyInterviewLink = () => {
      if (checked) {
        navigator.clipboard.writeText(data.interviewLink).then(
          () => showToast('Interview link copied to clipboard!'),
          (err) => console.error('Could not copy text: ', err)
        );
      } else {
        showToast('Auto-Recruiter not enabled');
      }
    };

    const handleJobPagePreview = () => {
      window.open(data.url, '_blank');
    };

    const isLinkDisabled = !checked;

    return (
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="22"
            style={{ transform: 'rotate(90deg)' }}
            fill="currentColor"
            class="bi bi-three-dots"
            viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
          </svg>
        </button>
        <div className={styles.dropdownContent}>
          <a onClick={handleCopyOfferId}>
            <FaClipboard className={styles.icon} /> Copy Offer ID
          </a>
          <a
            onClick={handleCopyInterviewLink}
            className={isLinkDisabled ? styles.dropdownLinkDisabled : ''}>
            <FaLink className={styles.icon} /> Interview Link
          </a>
          <a onClick={handleJobPagePreview}>
            <FaEye className={styles.icon} /> JobPage Preview
          </a>
        </div>
      </div>
    );
  }

  const toggleFilter = () => {
    setFilterOpen((prev) => !prev);
  }

  useEffect(() => {
    if(data.nps_remark) {
      const nps = data.nps_remark.split(":")[1];
      setNpsData(nps);
    }
  }, [data]);

  const description = data?.description || "";
  const shouldShorten = description.length > 300;
  const displayedDescription =
    shouldShorten && !isDescriptionExpanded
      ? `${description.substring(0, 300)}...`
      : description;

  // console.log("checkkk")
  console.log(data);
  // console.log(data.potentialuser8Email.data)
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {
            status === 'INACTIVE' && (
              // data.admin_approval_status === 'REJECTED' ? (
              <div className={styles.rejectReason}>
                {data.admin_approval_status === 'REJECTED' ? (
                  <strong>Rejected:</strong>
                ) : data.admin_approval_status === 'APPROVED' ? (
                  <strong style={{ color: '#000' }}>Closed:</strong>
                ) : (
                  <strong style={{ color: '#000' }}>Closed:</strong>
                )}{' '}
                {data.admin_message}
              </div>
            )
            // ) : (
            //   ''
          }
          <div className={styles.details}>
            {/* <span /> */}
            {data.jobPlan && (
  <div
    className={`${styles.planTag} ${
      data.is_organic === 1
        ? styles.organic
        : data.jobPlan === 'PLUS'
        ? styles.plus
        : styles.classic
    }`}
  >
    {data.is_organic === 1 ? `${data.jobPlan} (Organic)` : data.jobPlan}
  </div>
)}


            <div className={styles.time}>
              {status === 'ACTIVE'
                ? 'Approved'
                : status === 'INACTIVE'
                ? data.admin_approval_status === 'REJECTED'
                  ? 'Rejected'
                  : data.admin_approval_status === 'APPROVED'
                  ? 'Closed'
                  : 'Closed'
                : 'Posted'}{' '}
              at - {formatTime(new Date(data.sortByTime))}
              {(data.adminName && data.adminName !== 'undefined') ||
              data.email ? (
                <>
                  <br />
                  <span>
                    by-
                    {data.adminName && data.adminName !== 'undefined'
                      ? data.adminName
                      : data.email}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.hirerDetails}>
            <div className={styles.hirerDetailsLeft}>
              <div className={styles.hirerImg}>
                <img src={data.userInfo[0].image_id} />
              </div>
              <div className={styles.hirerTextDetails}>
                <Link
                  // onClick={() => {
                  //   sessionStorage.setItem('viewJobPage', currentPage);
                  // }}
                  className={styles.hirerName}
                  to={`/account/${data.userInfo[0].user_id}`}>
                  {data.userInfo[0].name}
                </Link>

                <div className={styles.hirerCompany}>
                  {data.userInfo[0].organisation}
                </div>
              
                {data.invite_source && data.invite_source !== '' && (
                  <div className={styles.type2}>
                    {data.invite_source.split('_').slice(1).join('_')}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.rightBtns}>
              { userPermissions?.includes("auto-recruiter") && <div className={styles.autoBtn}>
                {status === 'ACTIVE' && (
                  <CheckBox
                    checked={checked}
                    onClick={() => {
                      toggleAutoRecruiter(data.offerId, !checked).then(
                        (res) => {
                          setChecked((a) => !a);
                        }
                      );
                    }}
                  />
                )}
              </div>}
              <div className={styles.dropBtn}>
                <DropdownMenu data={data} showToast={showToast} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.row}>
            <div>
              <div className={styles.title} onClick={() => openJobPage(data.offerId)}>{data.title}
              {data.nps_remark && (
                  <span className={styles.npsRemark}>
                    &nbsp;| { npsData ? 
                    ["","","","",""].map((a, i) => (
                      <StarSvg color={i < npsData ? "#FFA800" : "#C4C4C4"} />
                    )) : null }
                </span>
                )}

              </div>
              <div className={styles.subtitle}>
                {data.jobType.length > 0
                  ? data.jobType[0].job_type
                  : data.type_of_service || data.entrepreneur_work_type}{' '}
                - {data.location_city || 'Remote'}
              </div>
            </div>
            <div className={styles.hirerJobCount}>{data?.jobCount}</div>
          </div>
          {data.preferredLocations && data.preferredLocations.length > 0 && (
            <div className={styles.section}>
              <div className={styles.sectionTitle}>Preferred Locations</div>
              <div className={styles.sectionContent2}>
                <div className={styles.sectionCards}>
                  {data.preferredLocations.map((item) => (
                    <div className={styles.sectionCard}>{item.location}</div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {data.industry_type && (
            <>
              <div className={styles.sectionTitle}>Industry</div>
              <div className={styles.type3}>{data.industry_type}</div>
            </>
          )}
          <div className={styles.section}>
            <div className={styles.flexContainer}>
              <div className={styles.sectionTitle}>Description</div>
              <Button variant='text' color='primary' onClick={() => {
                setPreviewJD(true);
              }}>Preview</Button>
            </div>
            <div className={styles.sectionContent}>
              <div className={styles.modalTextContent}>
                <Markdown>
                  {description}
                </Markdown>
              </div>
            </div>

            {/* {shouldShorten && (
              <button onClick={toggleDescriptionExpand} className={styles.sectionCard} style={{ backgroundColor: '#1A237E' }}>
                {isDescriptionExpanded ? 'Show less' : 'Read more'}
              </button>
            )} */}
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Skills</div>
            <div className={styles.sectionContent2}>
              <div className={styles.sectionCards}>
                {data.skills
                  .sort((a, b) => a.is_soft_skill - b.is_soft_skill)

                  .map((skill) => (
                    <div
                      className={
                        styles.sectionCard +
                        ' ' +
                        (skill.is_soft_skill ? styles.sectionCardVariant2 : '')
                      }>
                      {skill.skill}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Offer Requirements:</div>
          </div>

          <div className={styles.candidateTable}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                {' '}
                {data.monthly_compensation_id
                  ? 'Yearly Compensation'
                  : data.hourly_compensation_id
                  ? 'Hourly Compensation'
                  : 'Earning Potential'}
              </div>
              <div className={styles.valueCell2}>
                {data.displayCompensation}
              </div>
            </div>

            {data.entrepreneur_investment_potential_id && (
              <div className={styles.tableRow}>
                <div className={styles.tableCell}>Investment Potential </div>
                <div className={styles.valueCell2}>
                  {data.entrepreneur_investment_potential}
                </div>
              </div>
            )}
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>Highest Qualification</div>
              <div className={styles.valueCell2}>
                {data.highest_qualification_required}
              </div>
            </div>

            <div className={styles.tableRow}>
              <div className={styles.tableCell}>Work Experience </div>
              <div className={styles.valueCell2}>
                {`${data.lowerworkex} - ${data.upperworkex} years`}
              </div>
            </div>
          </div>

          {/* POTENTIAL DATA */}
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Potential Candidates:</div>
          </div>

          <div className={styles.candidateTable}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}> S3</div>

              {data.potentialCandidateAiCutOffCount > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_ai_cutoff/${data.offerId}`}>
                    {data.potentialCandidateAiCutOffCount}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}> S3 Weighted</div>

              {data.potentialCandidateS3WeightedCount > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_s3_weighted/${data.offerId}`}>
                    {data.potentialCandidateAiCutOffCount}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>

            {/* <div className={styles.tableRow}>
              <div className={styles.tableCell}>S8 (Mobile)</div>

              {data.potentialuserss8m > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    onClick={() => {
                      sessionStorage.setItem('viewJobPage', currentPage);
                    }}
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_s8m/${data.offerId}`}>
                    {data.potentialuserss8m}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>S8 (Email)</div>

              {data.potentialuserss8e > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    onClick={() => {
                      sessionStorage.setItem('viewJobPage', currentPage);
                    }}
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_s8e/${data.offerId}`}>
                    {data.potentialuserss8e}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div> */}
          </div>

          {status !== 'UNDER_REVIEW' && (
            <>
              <div
                className={styles.section2}
                style={data.applicationsCount ? { cursor: 'pointer' } : null}
                onClick={() =>
                  data.applicationsCount
                    ? candidateDetailsCardRef.current?.fetchApplications(data.offerId)
                    : null
                }>
                <div className={styles.sectionTitle}>No. of Applications</div>
                <div className={styles.sectionContent3}>
                  <div className={styles.applicationsCount}>
                    <span>
                      {' '}
                      {data.applicationsCount ||
                        data.applicationCount ||
                        0}{' '}
                    </span>
                    {data.applicationsCount > 0 && (
                      <div className={styles.viewApplicationsLength}>
                        (View)
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section2}>
                <div className={styles.sectionTitle}>Communications Stats</div>
                <div className={styles.buttons}>
                  <button
                    className={styles.primaryButton}
                    onClick={() => fetchStats(data.offerId)}>
                    Fetch Stats
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {!hideButtons && (
          <div className={styles.footer}>
            <div className={styles.buttons}>
              {status !== 'INACTIVE' && (
                <>
                  {' '}
                  <button
                    className={styles.dangerButton}
                    onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </button>
                  <button
                    className={styles.primaryButtonAlt}
                    onClick={() => setEditJob(true)}>
                    Edit
                  </button>
                  <button
                    className={styles.dangerButtonAlt}
                    onClick={() => setShowRejectJobModal(true)}>
                    Reject
                  </button>
                </>
              )}
              {status === 'ACTIVE' && userPermissions?.includes("boost-posts") && (
                <button
                  className={styles.primaryButton}
                  onClick={() => openBoostDrawer(data.offerId)}>
                  Boost
                </button>
              )}
              {(status === 'UNDER_REVIEW' || status === 'ON_HOLD') && (
                <div className={styles.dropdown}>
                  <div
                    className={`${styles.dropdownContent} ${styles.approveDropdownContent}`}>
                    <a onClick={() => approveJob(data.offerId, true)}>
                      <FaGift className={styles.icon} /> Free Job
                      {/*   isFreeActive : true */}
                    </a>
                    <a onClick={() => approveJob(data.offerId)}>
                      <FaMoneyBillAlt className={styles.icon} /> Paid Job
                    </a>
                  </div>
                  <button className={styles.primaryButton}>Approve</button>
                </div>
                // <button
                //   className={styles.primaryButton}
                //   onClick={() => approveJob(data.offerId)}>
                //   Approve
                // </button>
              )}
              {status === 'UNDER_REVIEW' && (
                <button
                  className={styles.onHoldButton}
                  onClick={() => setJobOnHold(data.offerId)}>
                  Hold
                </button>
              )}

              {/* {status === 'ACTIVE' && (
              <button
                className={styles.primaryButton}
                // onClick={() => approveJob(data.offerId)}>
              >
                Boost Job
              </button>
            )} */}
            </div>
          </div>
        )}
      </div>
      <ViewOfferCandidatesDetails 
        ref={candidateDetailsCardRef}
        data={data}
        showToast={showToast}
      />
      <StatsComponent
        viewStats={viewStats}
        setViewStats={setViewStats}
        whatsappStats={whatsappStats}
        emailStats={emailStats}
      />
      {editJob && (
        <ReactModal
          isOpen={editJob}
          className="active_jobs__modal-modal"
          overlayClassName="active_jobs__modal-overlay">
          <EditJobPostV2
            cancel={() => setEditJob(false)}
            dataProps={data}
            id={data?.offerId}
            showToast={showToast}
            refresh={() => {
              setEditJob(false);
              setTimeout(refreshData, 500);
            }}
          />
        </ReactModal>
      )}
      {showRejectJobModal && (
        <ReactModal
          isOpen={showRejectJobModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Select a reason to reject this job for {data.title} by{' '}
              {data.userInfo && data.userInfo[0].name}?
            </div>
            {showRejectJobModal && (
              <ReactSelect
                value={rejectReason}
                onChange={(e) => {
                  setRejectReasonCustom('');
                  setRejectReason(e);
                }}
                options={[
                  {
                    label: 'Insufficient information to Apply',
                    value: 'Insufficient information to Apply'
                  },
                  {
                    label: 'Role Description not appropriate',
                    value: 'Role Description not appropriate'
                  },
                  {
                    label: 'Please add more skills',
                    value: 'Please add more skills'
                  },
                  {
                    label:
                      'Salary does not commensurate with skills / experience',
                    value:
                      'Salary does not commensurate with skills / experience'
                  },
                  {
                    label: 'Post not Appropriate',
                    value: 'Post not Appropriate'
                  },
                  {
                    label: 'Official Email not there',
                    value: 'Official Email not there'
                  },
                  {
                    label: 'Official Website not there',
                    value: 'Official Website not there'
                  },
                  {
                    label: 'Official Email & Website not there',
                    value: 'Official Email & Website not there'
                  },
                  { value: 'Custom message', label: 'Custom message' }
                ]}
              />
            )}
            {rejectReason?.value === 'Custom message' && (
              <input
                className={styles.textField}
                placeholder="Choose custom message"
                type="text"
                value={rejectReasonCustom}
                onChange={(e) => setRejectReasonCustom(e.target.value)}
              />
            )}
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  onClick={() => {
                    rejectJob(
                      data.offerId,
                      rejectReasonCustom || rejectReason?.value
                    );
                  }}>
                  Reject
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowRejectJobModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
      {showBoostJobModal && (
        <ReactModal
          isOpen={showBoostJobModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Are you sure you want to boost this job for {data.title}?
            </div>
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  style={{
                    backgroundColor: '#3485ff'
                  }}
                  onClick={() => {
                    boostJob(data.offerId);
                    setShowBoostJobModal(false);
                  }}>
                  Boost Job
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowBoostJobModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
      {showDeleteModal && (
        <ReactModal
          isOpen={showDeleteModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Do you really want to delete this job from database. This action
              is irreversible.{' '}
            </div>
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  onClick={() => {
                    deleteJob(data.offerId);
                  }}>
                  DELETE
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
      <ReactModal
        isOpen={previewJD}
        onRequestClose={() => setPreviewJD(false)} 
        className={`jobs_under_review__modal-modal ${styles.jdOverLay}`}
        overlayClassName="jobs_under_review__modal-overlay"
      >
        <div className="jobs_under_review__modal">
          <div className={styles.title2}>
            Job Description
          </div>
          <div className={`${styles.modalTextContent} ${styles.markdownContentContainer}`} style={{ maxHeight: '15rem' }}>
            <Markdown >
              {data.description}
            </Markdown>
          </div>
        </div>
      </ReactModal>
    </>
  );
}

function StarSvg({color}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} width="16" height="16" viewBox="0 0 16 16">
      <path d="M12.552 5.02242C11.6978 4.81729 10.8059 4.82723 9.95646 5.05134C9.10697 5.27546 8.32632 5.70675 7.68442 6.3066C7.04252 6.90645 6.55941 7.65613 6.27834 8.48851C5.99728 9.32089 5.92703 10.21 6.0739 11.0762C6.05156 11.0546 6.02798 11.0344 6.00327 11.0155C5.82827 10.8824 5.60952 10.833 5.17202 10.7343L4.77515 10.6443C3.23765 10.2962 2.4689 10.1224 2.28577 9.5343C2.10327 8.9468 2.62702 8.33367 3.67515 7.10805L3.9464 6.79117C4.2439 6.44305 4.39327 6.26867 4.46015 6.05367C4.52702 5.83805 4.50452 5.60555 4.45952 5.14117L4.41827 4.71805C4.26015 3.08242 4.18077 2.26492 4.65952 1.9018C5.13827 1.53805 5.85827 1.8693 7.29702 2.53242L7.67015 2.70367C8.0789 2.89242 8.28327 2.98617 8.50015 2.98617C8.71702 2.98617 8.9214 2.89242 9.33077 2.70367L9.70265 2.53242C11.142 1.86992 11.862 1.53867 12.3401 1.90117C12.8195 2.26492 12.7401 3.08242 12.582 4.71805L12.552 5.02242Z"/>
      <path opacity="0.5" d="M6.72166 12.1176L6.51666 11.7501C6.29166 11.3463 6.17916 11.1444 6.00416 11.0113C6.02916 11.0301 6.05228 11.0501 6.07478 11.0719C5.92788 10.2057 5.99812 9.31651 6.27922 8.48407C6.56032 7.65163 7.04349 6.9019 7.68547 6.30203C8.32745 5.70216 9.10819 5.27089 9.95776 5.04683C10.8073 4.82277 11.6992 4.81293 12.5535 5.01818L12.541 5.13693C12.4967 5.60131 12.4742 5.83381 12.541 6.04943C12.6079 6.26443 12.7567 6.43881 13.0548 6.78693L13.326 7.10381C14.3742 8.32881 14.8979 8.94193 14.7148 9.53006C14.5323 10.1182 13.7635 10.2926 12.226 10.6401L11.8285 10.7301C11.3917 10.8288 11.1729 10.8782 10.9973 11.0113C10.8223 11.1444 10.7098 11.3463 10.4848 11.7501L10.2804 12.1176C9.48853 13.5376 9.09291 14.2476 8.50103 14.2476C7.90916 14.2476 7.51353 13.5376 6.72166 12.1176Z"/>
    </svg>
  )
}

function CheckBox({ checked, onClick }) {
  return (
    <>
      {' '}
      <input checked={checked} type="checkbox" className={styles.switchInput} />
      <label className={styles.switchLabel} onClick={onClick}>
        <div class={styles.switchContainer}>
          {/* <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} /> */}
        </div>
      </label>
    </>
  );
}

