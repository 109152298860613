import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { salesStages } from '../../config';
import styles from './design.module.scss';
import AddIcon from '@material-ui/icons/Add';
import { sendHttpCall } from 'shareService/httpService';
import MaterialTable from 'material-table';
import DateFnsUtils from '@date-io/date-fns';

import { selectoptions } from '../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { Grid, Typography, TextField, Button } from '@material-ui/core';

import { useLeadDrawer } from '../../contexts/LeadDrawerContext';
import { useSelector } from 'react-redux';


function getCurrentDay(date = new Date()) {
  const previous = new Date();
  previous.setDate(date.getDate());
  return previous;
}

export default function Leads(props) {
  const adminId = localStorage.getItem('admin_id');
  const startInitial = new Date(2019, 1, 1);
  const { openDrawer , isOpen } = useLeadDrawer();
  const [selectedStartDate, setSelectedStartDate] = useState(
    localStorage.getItem('leadsStartDate')
      ? localStorage.getItem('leadsStartDate')
      : moment(getCurrentDay()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    localStorage.getItem('clickEndDate')
      ? localStorage.getItem('clickEndDate')
      : moment(getCurrentDay()).format('YYYY-MM-DD')
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('selectedPeriodUserSearch')
      ? localStorage.getItem('selectedPeriodUserSearch')
      : 'Today'
  );

  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem('everyTablePage'), 10) || 0
  );
  const [limit, setLimit] = useState(
    parseInt(sessionStorage.getItem('everyTableLimit'), 10) || 50
  );

  const userType = localStorage.getItem('user_type');
const userName = localStorage.getItem('user_name');
const [selectedAdmin, setSelectedAdmin] = useState(null);
const [salesAdmins, setSalesAdmins] = useState([]);

  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };

  const adminStaticConfig = useSelector((state) => state.adminStaticConfig.data);

  const getSalesAdmins = (data) => {
    // let url = `admin/users/SALES`;
    // sendHttpCall('GET', url, null, props)
    //   .then((response) => {
    //     const formattedAdmins = response.data.map((admin) => ({
    //       label: `${admin.name} (${admin.email_id})`,
    //       value: admin.admin_id
    //     }));
    //     setSalesAdmins([{ label: 'All Admins', value: '' }, ...formattedAdmins]);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching sales admins:', error);
    //   });

    const formattedAdmins = data.map((admin) => ({
      label: `${admin.name} (${admin.email_id})`,
      value: admin.admin_id
    }));
    setSalesAdmins([{ label: 'All Admins', value: '' }, ...formattedAdmins]);

  };
  
  // Fetch admins on component mount if user is admin
  useEffect(() => {
    if (userType === 'ADMIN' && adminStaticConfig.loaded) {
      getSalesAdmins(adminStaticConfig['allAdmins']);
    }
  }, [adminStaticConfig]);

  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));
        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 6 months':
        setSeletedPeriod('Last 6 months');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 183);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
        break;
    }
    setPage(0);
  };

  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleDrawerOpen = (leadId) => {
    openDrawer(leadId);
  };

  const getTableData = async () => {
    setIsLoading(true);
    let url = `sales/leads?limit=${limit}&page=${page}&start_date=${selectedStartDate}&end_date=${selectedEndDate}`;
    
    if (userType !== 'ADMIN') {
      url += `&adminId=${adminId}`;
    } else if (selectedAdmin && selectedAdmin !== 'self') {
      url += `&adminId=${selectedAdmin}`;
    }
  
    sendHttpCall('GET', url, null, props).then((response) => {
      setIsLoading(false);
      setData(response.data);
      setTotalCount(response.totalCount);
    });
  };
  

  useEffect(() => {
    getTableData();
  }, [isOpen,page, limit, selectedEndDate, selectedStartDate, selectedAdmin]);

  const handleExpectedRevenueChange = (e) => {
    setExpectedRevenue(e.target.value);
  };

  const handleSalesStageChange = (selectedOption) => {
    setSalesStage(selectedOption);
  };

  useEffect(() => {
    setData([]);
    setTotalCount(0);
    if (page === 0) getTableData();
    else setPage(0);
  }, [props?.match?.params?.type]);

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    },
    updateIcon: {
      color: '#297bca',
      cursor: 'pointer',
      '&:hover': {
        color: '#1e5e99'
      }
    }
  }));
  const classes = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.match.params.type} Leads Report</div>
      <div className="flex">
        <div>
          <div className="sm-width">
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT PERIOD
            </Typography>
          </div>
          <div className="sm-width">
            <TextField
              fullWidth
              margin="dense"
              name="status"
              onChange={handleTimeChange}
              required
              select
              SelectProps={{ native: true }}
              value={seletedPeriod}
              variant="outlined">
              {selectoptions.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </div>
        {isCustomDate && (
          <Grid container justify="space-around">
            <Grid xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="SELECT START DATE"
                      format="dd/MM/yyyy"
                      value={selectedStartDate}
                      onChange={handleChangeStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      id="date-picker-dialog"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      label="SELECT END DATE"
                      margin="normal"
                      onChange={handleChageEndDate}
                      value={selectedEndDate}
                    />
                  </Grid>
                  <Grid xs={2}>
                    {okdate && (
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.srch_btn}
                        onClick={filterByDateRange}>
                        SEARCH
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2}></Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}

<div>
  {userType === 'ADMIN' ? (
    <div>
      <Typography variant="body2" color="textSecondary" component="p">
        SELECT SALES ADMIN
      </Typography>
      <TextField
        fullWidth
        select
        margin="dense"
        variant="outlined"
        value={selectedAdmin}
        onChange={(event) => setSelectedAdmin(event.target.value)}
        // style={{ marginTop: '10px' }}
        SelectProps={{
          native: true,
          // style: { fontSize: '1rem', padding: '2px' }
        }}
      >
        {salesAdmins.map((admin) => (
          <option key={admin.value} value={admin.value}>
            {admin.label}
          </option>
        ))}
      </TextField>
    </div>
  ) : (
    <Typography
      variant="h4"
      color="textPrimary"
      component="p"
      style={{
        fontWeight: 'bold',
        padding: '10px 0',
        color: '#297bca',
      }}
    >
      Hi, {userName}!
    </Typography>
  )}
</div>
        <Button
          color="primary"
          variant="contained"
          className={classes.addLeadButton}
          onClick={() => props.history.push('/add-lead')}>
          <AddIcon /> Add Lead
        </Button>
      </div>

      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'S No.',
            cellStyle: {
              position: 'sticky',
              left: 0,
              backgroundColor: '#FFF'
            },
            headerStyle: {
              position: 'sticky',
              left: 0,
              backgroundColor: '#FFF'
            },
            render: (rowData) => page * limit + rowData.tableData.id + 1
          },
          {
            title: 'BDR/BDM Name',
            field: 'assignedToName',
            cellStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            },
            headerStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            }
          },
          {
            title: 'Created By',
            field: 'createdBy.adminName',
            cellStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            },
            headerStyle: {
              position: 'sticky',
              left: 40,
              backgroundColor: '#FFF'
            }
          },
          { title: 'Company', field: 'companyName' },
          {
            title: 'Client Name',
            field: 'name',
            render: (rowData) => (
              <div
                style={{
                  color: '#297bca',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}
                onClick={() => handleDrawerOpen(rowData._id)}>
                {rowData.name}
              </div>
            )
          },
          { title: 'Client Email', field: 'email' },
          { title: 'Contact', field: 'mobileNumber' },
          { title: 'Sales Stage', field: 'salesStage.label' },
          {
            title: 'Next Follow Up',
            field: 'remarks',
            render: (rowData) => {
              const lastRemark = rowData.remarks[rowData.remarks.length - 1];
              return lastRemark ? (
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {moment(new Date(lastRemark.nextFollowUpAt)).format('DD/MM/YYYY h:mm A')}
                </span>
              ) : '';
            }
          },  
          {
            title: 'Expected Revenue',
            field: 'expectedRevenue',
            render: (rowData) =>
              `${rowData.currency} ${rowData.expectedRevenue}`
          },
          // {
          //   title: 'Forecasted Revenue',
          //   field: 'expectedRevenue',
          //   render: (rowData) => {
          //     const stagePercentage =
          //       salesStages[rowData.salesStage.value - 1].percentage / 100;
          //     const forecastedRevenue =
          //       rowData.expectedRevenue * stagePercentage;
          //     return `${rowData.currency} ${forecastedRevenue.toFixed(2)}`;
          //   }
          // },
          {
            title: 'Created At',
            field: 'createdAt',
            render: (rowData) => moment(new Date(rowData.createdAt)).format('DD/MM/YYYY h:mm A')
          },
          {
            title: 'Last Connect By',
            field: 'remarks',
            render: (rowData) => {
              const lastRemark = rowData.remarks[rowData.remarks.length - 1];
              return lastRemark ? lastRemark.admin.adminName : '';
            }
          },  
          { title: 'Source', field: 'source' }
        ]}
        data={data}
        totalCount={totalCount}
        page={page}
        title=""
        onChangePage={(e) => setPage(e)}
        onChangeRowsPerPage={(e) => setLimit(e)}
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: limit,
          pageSizeOptions: [10, 20, 30, 40, 50],
          sorting: true,
          columnsButton: true
          //  exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true
        }}
      />
    </div>
  );
}
