import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        loaded: false,
        relationship: [],
        skill_level: [],
        monthlyMaster: [],
        hourlyMaster: [],
        typeOfWork: [],
        currentAvailability: [],
        workExperienceRange: [],
        highestQualification: [],
        parameters: [],
        reportJobReasons: [],
        hoursPerWeekRange: [],
        opportunities: [],
        hourlyCompensation: [],
        investmentPotential: [],
        earningPotential: [],
        entrepreneurWorkType: [],
        annualTurnover: [],
        donationAmount: [],
        volunteerTime: [],
        bloodGroup: [],
        professions: {}
    }
}

const staticConfigReducer = createSlice({
    name: "staticConfig",
    initialState: initialState,
    reducers: {
        loadStaticData: (state, action) => {
            console.log("Action CAllled");
            console.log(action);
            state.data = {
                ...state.data,
                ...action.payload,
                loaded: true
            }
        }
    }
});

export const { loadStaticData } = staticConfigReducer.actions;
export default staticConfigReducer.reducer;