import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  Button,
  colors,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink as RouterLink } from 'react-router-dom';

// Import MUI icons used in your old code:
import {
  Dashboard,
  ViewAgenda,
  ViewCarousel,
  Error as ErrorIcon,
  PersonAdd,
  Search,
  VerifiedUser,
  People,
  HourglassFullRounded,
  NotificationImportant,
  Timeline,
  ShowChart,
  MultilineChart,
  PieChart,
  SaveOutlined,
  Money,
  AirlineSeatLegroomExtraOutlined,
  Public,
  Group,
  Book,
  VerifiedUserSharp,
  Person,
  WhatsApp,
  Email,
  WhatshotSharp,
  Notifications,
  PeopleTwoTone
} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

// Your custom InterviewIcon:
import { InterviewIcon } from 'icons';

// 1) Creating the iconMap for different icons
const iconMap = {
  Dashboard: <Dashboard />,
  ViewAgenda: <ViewAgenda />,
  ViewCarousel: <ViewCarousel />,
  Error: <ErrorIcon />,
  PersonAdd: <PersonAdd />,
  Search: <Search />,
  VerifiedUser: <VerifiedUser />,
  VerifiedUserSharp: <VerifiedUserSharp />,
  People: <People />,
  PeopleTwoTone: <PeopleTwoTone />,
  HourglassFullRounded: <HourglassFullRounded />,
  NotificationImportant: <NotificationImportant />,
  Timeline: <Timeline />,
  ShowChart: <ShowChart />,
  MultilineChart: <MultilineChart />,
  PieChart: <PieChart />,
  SaveOutlined: <SaveOutlined />,
  Money: <Money />,
  AirlineSeatLegroomExtraOutlined: <AirlineSeatLegroomExtraOutlined />,
  Public: <Public />,
  Group: <Group />,
  Book: <Book />,
  Person: <Person />,
  WhatsApp: <WhatsApp />,
  Email: <Email />,
  WhatshotSharp: <WhatshotSharp />,
  Notifications: <Notifications />,
  Settings: <SettingsIcon />,
  SettingsApplications: <SettingsApplicationsIcon />,
  SettingsIcon: <SettingsIcon />,
  InterviewIcon: <InterviewIcon />
};

// 2) The parseIcon function that uses this iconMap
function parseIcon(iconStr) {
  // Return the mapped icon if found; fallback to <Dashboard /> if not
  return iconMap[iconStr] || <Dashboard />;
}


const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  sub_button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12,
    marginTop: -10,
    marginBottom: -10
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

// 2) Because NavLink uses forwardRef:
const CustomRouterLink = forwardRef((props, ref) =>{ 
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink 
        {...props} 
        onClick={(e) => {
          e.preventDefault();
          window.location.hash = props.to;
        }}
      />
    </div>
  )
});

const SidebarNav = props => {
  const { navigation, className, ...rest } = props;
  const classes = useStyles();

  const resolveRoutePath = (path, meta) => {
    if (!meta) return path;
    
    return path.split('/')
      .map(segment => {
        if (segment.startsWith(':')) {
          const param = segment.slice(1);
          // Handle optional parameters (marked with ?)
          if (param.endsWith('?')) {
            const cleanParam = param.slice(0, -1);
            return meta[cleanParam] || '';
          }
          return meta[param] || segment;
        }
        return segment;
      })
      .filter(Boolean) // Remove empty segments
      .join('/');
  };
   
  const getNavigationPath = (item) => {
    const path = resolveRoutePath(item.path, item.meta);
    return path.endsWith('/') ? path.slice(0, -1) : path;
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {Object.values(navigation).map((group) => (
        <ListItem
          className={classes.item}
          disableGutters
          key={group.id}
        >
          <ExpansionPanel style={{ boxShadow: 'none', width: '100%' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Button
                className={classes.button}
              >
                <div className={classes.icon}>
                  {parseIcon(group.icon)}
                </div>
                {group.name}
              </Button>
            </ExpansionPanelSummary>

            {group.items.map((item) => (
              <ListItem
                className={classes.item}
                disableGutters
                key={item.id}
              >
                <ExpansionPanelDetails style={{ marginLeft: 20 }}>
                  <Button
                    className={classes.sub_button}
                    component={CustomRouterLink}
                    to={getNavigationPath(item)}
                    activeClassName={classes.active}
                  >
                    <div className={classes.icon}>
                      {parseIcon(item.icon)}
                    </div>
                    {item.name}
                  </Button>
                </ExpansionPanelDetails>
              </ListItem>
            ))}
          </ExpansionPanel>
        </ListItem>
      ))}
    </List>
  );
};


// const SidebarNav = props => {
//   const { groupedRoutes = [], className, ...rest } = props;
//   const classes = useStyles();

//   return (
//     <List {...rest} className={clsx(classes.root, className)}>
//       {groupedRoutes.map((group) => (
//         <ListItem
//           className={classes.item}
//           disableGutters
//           key={group.title}
//         >
//           <ExpansionPanel style={{ boxShadow: 'none' }}>
//             <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
//               <Button
//                 className={classes.button}
//                 component={CustomRouterLink}
//               >
//                 {/* Use parseIcon for the group-level icon */}
//                 <div className={classes.icon}>
//                   {parseIcon(group.icon)}
//                 </div>
//                 {group.title}
//               </Button>
//             </ExpansionPanelSummary>

//             {(group.pages || []).map((sub) => (
//               <ListItem
//                 className={classes.item}
//                 disableGutters
//                 key={sub.title}
//               >
//                 <ExpansionPanelDetails style={{ marginLeft: 20 }}>
//                   <Button
//                     className={classes.sub_button}
//                     component={CustomRouterLink}
//                     to={sub.href}
//                     activeClassName={classes.active}
//                   >
//                     <div className={classes.icon}>
//                       {parseIcon(sub.icon)}
//                     </div>
//                     {sub.title}
//                   </Button>
//                 </ExpansionPanelDetails>
//               </ListItem>
//             ))}
//           </ExpansionPanel>
//         </ListItem>
//       ))}
//     </List>
//   );
// };

// SidebarNav.propTypes = {
//   // groupedRoutes: PropTypes.array.isRequired,
//   className: PropTypes.string
// };

export default SidebarNav;
