import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
    Drawer,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import styles from './styles.module.scss';
import Typography from 'theme/typography';
import CustomSelectWrapper from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/CustomSelectWrapper';
import { getCompensationFromIdNew } from '../constants/converstion';

const ToggleFilter = ({ isOpen, toggleDrawer, salesAdmins, setSelectedAdminId, selectedAdminId, searchQueryInfo, setSearchQueryInfo, handleSearch, selectedPeriod, handleTimeChange, setYearlyCompensationData, yearlyCompensationData, handleClear }) => {
  const [compensationDetails, setCompensationDetails] = useState([]);
  const staticConfig = useSelector((state) => state.staticConfig.data);

  useEffect(() => {
    const yearly = staticConfig?.monthlyMaster.map((a) => ({
      label: getCompensationFromIdNew(a.id),
      value: a.id
    }));
    
    setCompensationDetails(yearly);
  }, [staticConfig]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      className={`${styles.container}`}
      ModalProps={{
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }
        }
      }}
    >
      <div className={`${styles.drawerContent} active_jobs__container`}
        // className="active_jobs__search"
      >
        <div className={styles.mainContainer}>
          <IconButton onClick={toggleDrawer} className={`${styles.closeBtn} active_jobs__modal-btn-cancel`}>
            <Close />
          </IconButton>
          <div className='active_jobs__header'>
            <h3>Filters</h3>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button onClick={handleClear} color='primary'>Clear Filter</Button>
          </div>
        </div>
        <div className={styles.contentContainer}>
            <FormControl variant="outlined">
              <InputLabel id="search-type">Search By</InputLabel>
              <Select
                labelId='search-type'
                label="Search By"
                value={searchQueryInfo.field}
                onChange={(e) => setSearchQueryInfo(() => ({ field: e.target.value, value: "" }))}
                variant="outlined"
                className={styles.select}
              >
                <MenuItem value="Name">Name</MenuItem>
                <MenuItem value="User Id">User Id</MenuItem>
                <MenuItem value="Offer Id">Offer Id</MenuItem>
                <MenuItem value="Job Title">Job Title</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
                {/* <MenuItem value="Last Year">Last Year</MenuItem> */}
              </Select>
            </FormControl>
            { searchQueryInfo.field && (
              <TextField value={searchQueryInfo?.value || ""} type={(searchQueryInfo?.field === "User Id" || searchQueryInfo?.field === "Offer Id") ? 'number' : 'text'} onChange={(e) => {
                setSearchQueryInfo({ ...searchQueryInfo, value: e.target?.value || '' })
              }} id="outlined-basic" label={`Search Hirer by ${searchQueryInfo?.field}`} variant="outlined" fullWidth />
            )}
            <div className={styles.fieldContainer}>
              <label>Assigned To</label>
              <TextField
                  fullWidth
                  select
                  margin="dense"
                  variant="outlined"
                  value={selectedAdminId}
                  onChange={(event) => setSelectedAdminId(event.target.value)}
                  style={{ marginBlock: 0 }}
                  InputLabelProps={{
                      style: { color: '#5f6368', fontWeight: 'bold' }
                  }}
                  SelectProps={{
                      native: true,
                      style: { fontSize: '1rem', padding: '2px' } // Improve dropdown appearance
                  }}
              >
                  <option value="0">
                      All Admins
                  </option>
                  {salesAdmins.map((admin) => (
                      <option key={admin.value} value={admin.value}>
                        {admin.label}
                      </option>
                  ))}
              </TextField>
            </div>
            <div>
              <label>Filter by Date</label>
              <TextField
                fullWidth
                margin="dense"
                name="status"
                onChange={(event) => handleTimeChange(event)}
                required
                select
                SelectProps={{ native: true }}
                value={selectedPeriod}
                variant="outlined"
                // className={classes.filterSelect}
              >
                {[
                  "All",
                  'Today',
                  'Yesterday',
                  'This Week (Mon - Today)',
                  'Last Week (Mon - Sun)',
                  'This Month',
                  'Last Month',
                  'Last 3 Months',
                  'Last 6 Months',
                  'Last Year'
                ].map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.formLabel}>Annual Compensation</label>
              <CustomSelectWrapper
                options={
                  [{ label: "Remove Filter", value: 0 } ,...compensationDetails]
                }
                onChange={setYearlyCompensationData}
                theme="ONBOARDING_HIRER"
                value={yearlyCompensationData}
                placeholder="Eg 4 LPA"
              />
            </div>
            <Button color='primary' variant='contained' style={{ borderRadius: '20px', marginTop: 'auto' }} onClick={handleSearch}>Search</Button>
          </div>
        </div>
    </Drawer>
  )
}

export default ToggleFilter