import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import styles from './styles.module.scss';
import { TextField, Menu, MenuItem, Box, Button, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
    FaGgCircle,
    FaLaptop,
    FaAndroid,
    FaApple,
    FaEye,
    FaFilter
} from 'react-icons/fa';
import Loader from 'components/Loader';
import { IconButton } from '@mui/material';
import { sendHttpCall } from 'shareService/httpService';
import { TotalUsers } from 'views/Dashboard/components';
import { esocialUrl } from 'config';
import Axios from 'axios';

const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY';


function ToggleStatusModal({
    isOpen,
    onRequestClose,
    confirmAction,
    candidateName,
    isShortlisting
  }) {
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="active_jobs__modal-modal"
        overlayClassName="active_jobs__modal-overlay">
        <div className="active_jobs__modal2">
          <div className="active_jobs__modal-heading">
            {' '}
            MANUAL APPLICATION STATUS UPDATE
          </div>
          Are you sure you want to
          <span
            style={{
              color: isShortlisting ? '#297bca' : '#ff4e4e',
              paddingLeft: '0.4rem'
            }}>
            {isShortlisting ? 'shortlist' : 'reject'}{' '}
          </span>
          {candidateName}?
          <div className="active_jobs2__modal-btns">
            <button
              className="active_jobs__modal-btn-confirm"
              onClick={confirmAction}>
              Confirm
            </button>
            <button
              className="active_jobs__modal-btn-cancel"
              onClick={onRequestClose}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
    );
}


function FeedbackModal({ isOpen, onRequestClose, feedback, prompt, status }) {
  const getStatusStyles = () => {
    if (status === 'REJECTED') {
      return {
        background: '#fde8e9',
        color: '#ff3a30'
      };
    } else if (status === 'ACCEPTED') {
      return {
        background: '#e7f0fe',
        color: '#297BCA'
      };
    }
    return {};
  };

  const statusStyles = getStatusStyles();
  const createMarkup = (htmlContent) => ({ __html: htmlContent });
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="active_jobs__modal-modal"
      overlayClassName="active_jobs__modal-overlay">
      <div className="active_jobs__modal2">
        <div className={styles.modalHeading}>AI Shortlister Feedback</div>
        <div
          className={styles.modalTextContent}
          dangerouslySetInnerHTML={createMarkup(feedback)}
          style={statusStyles}
        />

        <div className={styles.modalHeading}>AI System Prompt</div>
        {/* <div className={styles.modalSubHeading}>System Prompt</div> */}
        <div className={styles.modalTextContent}>{prompt.system}</div>
        <div className={styles.modalHeading}>AI User Prompt</div>
        <div className={styles.modalTextContent}>{prompt.user}</div>

        <div className="active_jobs2__modal-btns">
          <button
            className="active_jobs__modal-btn-cancel"
            onClick={onRequestClose}>
            Close
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
const manualShortlister = (offerId, candidateId, status) => {
    const shortListData = {
        userId: candidateId,
        status: status
    };
    return new Promise((resolve, reject) => {
        Axios.post(
            `${esocialUrl}/offer/${offerId}/apply/admin-response`,
            shortListData,
            {
                headers: {
                authorization: notLoggedInTokenProd
                }
            }
        )
            .then((res) => {
                resolve(res);
            })
            .catch((e) => {
                console.error('Error in manualShortlister', e.response);
                reject(e);
            });
    });
};

const ViewOfferCandidatesDetails = forwardRef(({ data, showToast }, ref) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [goToPage, setGoToPage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [currentCandidate, setCurrentCandidate] = useState({});
    const [isShortlisting, setIsShortlisting] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [currentFeedback, setCurrentFeedback] = useState('');
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [applicationStatus, setApplicationStatus] = useState('');
    const [applicationState, setApplicationState] = useState({});
    const [viewApplicationStates, setViewApplicationStates] = useState(false);
    const [applicationsLoading, setApplicationsLoading] = useState(false);
    const [applications, setApplications] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [lastPage, setLastPage] = useState(10);
    const [page, setPage] = useState(1);
    const [viewCandidates, setViewCandidates] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [interviewFilter, setInterviewFilter] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        if (event.key === 'Enter') {
            fetchApplications(data.offerId, 1, searchTerm);
            setPage(1);
        }
    };
    
    const handleFilterInterviewCand = () => {
        let filter;
        setInterviewFilter((prev) => {
            filter = !prev;
            return filter;
        });
        fetchApplications(data.offerId, 1, searchTerm, filter);
        setPage(1);
    }

    
    const handleChange = (event, value) => {
        setPage(value);
        fetchApplications(data.offerId, value);
    };

    const handleGoToPageChange = (event) => {
        setGoToPage(event.target.value);
    };
    
    const handleGoToPage = () => {
        let pageNumber = parseInt(goToPage, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
            setPage(pageNumber);
            setGoToPage('');
        } else {
            alert('Please enter a valid page number');
        }
    };

    const handleToggleStatus = (candidate) => {
        setCurrentCandidate(candidate);
        setIsShortlisting(candidate.request_status !== 'ACCEPTED');
        setShowConfirmation(true);
    };

        
    const confirmStatusChange = () => {
        const newStatus = isShortlisting ? 1 : 0;
        console.log("New Status: ", newStatus); 
        manualShortlister(data.offerId, currentCandidate.user_id, newStatus)
            .then(() => {
                showToast(`Status changed successfully for ${currentCandidate.name}.`);
                setShowConfirmation(false);
                setTimeout(refreshData, 1200);
                setViewCandidates(false);
            })
            .catch((error) => {
                console.error('Error updating status:', error);
                showToast('Failed to change status.');
                setShowConfirmation(false);
            });
    };

    const openUserPage = (userId) => {
        window.open(`/dashboard#/account/${userId}`, '_blank');
    };

    
    const fetchApplications = async (offerId, page = 1, searchTerm = '', interviewFilterSec = null) => {
        console.log("CALLED", offerId);
        try {
            setApplicationsLoading(true);
            const response = await sendHttpCall(
                'GET',
                `/jobs/get_applications?offerId=${offerId}&page=${page}&searchTerm=${searchTerm}${interviewFilterSec !== null ? `&interviewCandidates=${interviewFilterSec}` : `&interviewCandidates=${interviewFilter}`}`,
                null
            );
        
            if (response) {
                setApplications(response.applications);
                setTotalCount(response.total);
                setViewCandidates(true);
                setLastPage(Math.ceil(response.total / 10));
            } else if (response.status === 400) {
                showToast(response.message, 'error');
            } else {
                throw new Error(response.message || 'Failed to fetch applications');
            }
        
            setApplicationsLoading(false);
        } catch (error) {
            setApplications([]);
            console.error('Error fetching applications:', error);
            showToast(error.message || 'Failed to fetch applications.', 'error');
            setApplicationsLoading(false);
        }
    };
    
    useImperativeHandle(ref, () => {
        return {
            fetchApplications
        };
    })

    const fetchApplicationStates = async (offerId) => {
        try {
        const response = await sendHttpCall(
            'GET',
            `jobs/get_applications_count?offerId=${offerId}`
        );

        console.log('Response received:', response);

        if (response) {
            console.log(response);
            setApplicationState(response);
            setViewApplicationStates(true);
            console.log('Data Received: ', applicationState);
        }
        } catch (error) {
        console.error('Error fetching application states:', error);
        showToast('Failed to fetch communication states');
        }
    };

    return (
        <>
            <ApplicationStatesComponent
                viewApplicationStates={viewApplicationStates}
                setViewApplicationStates={setViewApplicationStates}
                applicationState={applicationState}
            />
            {viewCandidates && (
                <ReactModal
                    isOpen={viewCandidates}
                    onRequestClose={() => {
                        setViewCandidates(false);
                        setSearchTerm('');
                    }}
                    className="active_jobs__modal-modal2"
                    overlayClassName="active_jobs__modal-overlay2"
                >
                    <div className="active_jobs__modal2">
                        <div
                            style={{
                                display: 'flex',
                                padding: '10px 20px',
                                justifyContent: 'space-between'
                            }}>
                            <div className="candidate_list_title">Candidates</div>
                            <button
                            className="candidate_application_count_btn"
                            onClick={() => fetchApplicationStates(data.offerId)}>
                            View Stats
                            </button>
                        </div>

                        <div className={`${styles.candidateListTitleContainer} candidate_list_title`}>
                            <TextField
                                label="Search by Candidate Name or User ID"
                                variant="outlined"
                                fullWidth
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchSubmit}
                            />
                            <IconButton
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                id="basic-button" 
                                onClick={handleClick} 
                                className={styles.svgCont}
                            >
                            <FaFilter />
                            {/* <div className={`${filterOpen ? styles.PopUpMenu : styles.hidePopUp}`}>
                                <div>Interview Candidates</div>
                            </div> */}
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                style={{ zIndex: '10000', top: '50px' }}
                            >
                            <MenuItem className={styles.menuItem}>
                                <label htmlFor={styles.check}>Interviewed Candidates</label> 
                                <input id={styles.check} type="checkbox" checked={interviewFilter} onChange={handleFilterInterviewCand} />
                                <label className={styles.switch} htmlFor={styles.check}>
                                <svg viewBox="0 0 212.4992 84.4688" overflow="visible">
                                    <path
                                    pathLength="360"
                                    fill="none"
                                    stroke="currentColor"
                                    d="M 42.2496 0 A 42.24 42.24 90 0 0 0 42.2496 A 42.24 42.24 90 0 0 42.2496 84.4688 A 42.24 42.24 90 0 0 84.4992 42.2496 A 42.24 42.24 90 0 0 42.2496 0 A 42.24 42.24 90 0 0 0 42.2496 A 42.24 42.24 90 0 0 42.2496 84.4688 L 170.2496 84.4688 A 42.24 42.24 90 0 0 212.4992 42.2496 A 42.24 42.24 90 0 0 170.2496 0 A 42.24 42.24 90 0 0 128 42.2496 A 42.24 42.24 90 0 0 170.2496 84.4688 A 42.24 42.24 90 0 0 212.4992 42.2496 A 42.24 42.24 90 0 0 170.2496 0 L 42.2496 0"
                                    ></path>
                                </svg>
                                </label>
                            </MenuItem>
                            </Menu>
                        </div>

                        {/* Loader and conditionally rendering content */}
                        {applicationsLoading ? (
                            <div className="loader-container">
                            <Loader />{' '}
                            {/* Make sure this Loader component is properly imported and styled */}
                            </div>
                        ) : applications.length > 0 ? (
                            <div className="candidate_list_items">
                            {applications.map((a, index) => (
                                <div className="candidate_list_item" key={a.user_id}>
                                <div className="candidate_list_item_no">
                                    {(page - 1) * 10 + index + 1}
                                </div>
                                <div>
                                    <img
                                        src={a.image_id}
                                        className="candidate_list_item_img"
                                        alt=""
                                    />
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => openUserPage(a.user_id)}
                                        className="candidate_list_item_name"
                                    >
                                    {a.name}{' '}
                                    {a.is_good_fit ? (
                                        <span className="candidate_list_item_name_r">R</span>
                                    ) : (
                                        ''
                                    )}
                                    {a.interviewId ? (
                                        <Link
                                            to={`/auto-interview/interview-by-details/${a.user_id}/${data.offerId}/1`}
                                            className="candidate_list_item_name_r"
                                            style={{
                                                backgroundColor: a.interviewStatus === "NOT_CLEARED"
                                                ? '#E96D5B'
                                                : a.interviewStatus === "CLEARED" ? '#68BB62'
                                                : "#F0F1F2",
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                        i
                                        </Link>
                                    ) : (
                                        ''
                                    )}
                                    {a.profile_viewed ? (
                                        <div className={styles.tooltipIconContainer}>
                                        <FaEye />
                                        <span className={styles.tooltipText}>
                                            Profile Viewed
                                        </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    </div>
                                </div>
                                <div>
                                    {a.apply_device === 'web' && <FaLaptop />}
                                    {a.apply_device === 'ios' && <FaApple />}
                                    {a.apply_device === 'android' && <FaAndroid />}
                                </div>
                                <div
                                    className={
                                    'candidate_list_item_status' +
                                    (a.request_status === 'ACCEPTED'
                                        ? ' status_blue'
                                        : '') +
                                    (a.request_status === 'REJECTED' ? ' status_red' : '')
                                    }>
                                    {a.request_status === 'ACCEPTED'
                                    ? 'SHORTLISTED'
                                    : a.request_status}
                                </div>

                                <button
                                    onClick={() => handleToggleStatus(a)}
                                    style={{
                                    marginLeft: '8px',
                                    cursor: 'pointer',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color:
                                        a.request_status === 'ACCEPTED'
                                        ? '#ff4e4e'
                                        : '#297bca',
                                    fontSize: '24px'
                                    }}>
                                    <FaGgCircle />
                                </button>

                                <div
                                    className={`candidate_list_item_applyStatus ${
                                    a.apply_source === 'WHATSAPP'
                                        ? 'whatsapp'
                                        : a.apply_source === 'FEED'
                                        ? 'feed'
                                        : a.apply_source === 'LINKEDIN'
                                        ? 'linkedin'
                                        : a.apply_source === 'SEARCH'
                                        ? 'search'
                                        : ''
                                    }`}>
                                    {a.apply_source}
                                </div>

                                <button
                                    className={styles.feedbackBtn}
                                    onClick={() => {
                                    setCurrentFeedback(a.shortlisterFeedBack);
                                    setCurrentPrompt(a.prompt);
                                    setShowFeedbackModal(true);
                                    setApplicationStatus(a.request_status);
                                }}>
                                    AI
                                </button>
                                </div>
                            ))}
                            </div>
                        ) : (
                            <div>No applications found</div>
                        )}

                        <div className="pagination">
                            <div className="active_jobs__pagination-count">
                            Total Count: {totalCount}
                            </div>
                            <Pagination
                            size="large"
                            color="#297bca"
                            count={lastPage}
                            page={page}
                            onChange={handleChange}
                            />
                            <Box display="flex" alignItems="center" gap={1}>
                            <TextField
                                size="small"
                                label="Go to page"
                                style={{ width: '100px' }}
                                variant="outlined"
                                value={goToPage}
                                onChange={handleGoToPageChange}
                                inputProps={{ type: 'number', min: 1, max: lastPage }}
                                onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleGoToPage();
                                }
                                }}
                            />
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#297bca' }}
                                onClick={handleGoToPage}>
                                Go
                            </Button>
                            </Box>
                        </div>
                    </div>
                    <ToggleStatusModal
                        isOpen={showConfirmation}
                        onRequestClose={() => setShowConfirmation(false)}
                        confirmAction={confirmStatusChange}
                        candidateName={currentCandidate.name}
                        isShortlisting={isShortlisting}
                    />

                    <FeedbackModal
                    isOpen={showFeedbackModal}
                    onRequestClose={() => setShowFeedbackModal(false)}
                    feedback={currentFeedback}
                    prompt={currentPrompt}
                    status={applicationStatus}
                    />
                </ReactModal>
            )}
        </>
    )
})

const ApplicationStatesComponent = ({
    viewApplicationStates,
    setViewApplicationStates,
    applicationState
  }) => {
    console.log('Application Data: ', applicationState);

    //preparing data for application states
    const applicationDisplayData = Object.entries(applicationState).map(
      ([status, count]) => ({
        label: convertCamelCaseToReadable(
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')
        ),
        count
      })
    );

    return (
      viewApplicationStates && (
        <ReactModal
          isOpen={viewApplicationStates}
          onRequestClose={() => setViewApplicationStates(false)}
          className="active_jobs__modal-modal2"
          overlayClassName="active_jobs__modal-overlay2"
        >
          <div className="active_jobs__modal2">
            <h3 style={{ fontWeight: 'bold' }}>Application Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid
                container
                spacing={3}
                style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                {applicationDisplayData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </ReactModal>
      )
    );
};

export default ViewOfferCandidatesDetails;

//function to convert camelcase string into human readable form
function convertCamelCaseToReadable(str) {
    let result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
    result = result
      .split(' ') // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' ');
    return result;
}