import React from 'react'
import styles from './styles.module.scss';

const NoInterviewAvailable = () => {

    return (
        <div className={`${styles.mainContainer} ${styles.mainCont}`}>
            <div className={styles.step1Div}>
                <div className={styles.header}>
                    <h1>NO CURRENT INTERVIEWS</h1>
                </div>
            </div>
        </div>
    )
}

export default NoInterviewAvailable