// import React from 'react';
// import { Switch, Redirect } from 'react-router-dom';
import RouteConfig from './RoutesConfig';
import { RouteWithLayout } from 'components';

// const Routes = (props) => {

//   return (
//     <Switch>
//       {RouteConfig.map((route, index) => {
//         return (
//           <RouteWithLayout
//           path={route.path}
//           key={route.routeId}
//           component={route.component}
//           exact={route.exact}
//           layout={route.layout}
//           />
//         );
//       })}
//       <Redirect 
//         from="/"
//         to={props.path}
//       />
//     </Switch>
//   )
// }

// export default Routes;


import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, layout: Layout, routeId, isPublic, ...rest }) => {
  const hasPermission = () => {
    if (isPublic) return true;
    
    try {
      const permittedRoutes = JSON.parse(localStorage.getItem('login_data') || '{}')['permitted_routes'];
      console.log("permittedRoutes", permittedRoutes);

      console.log(permittedRoutes.includes(routeId), routeId);
    
      return permittedRoutes.includes(routeId);
    } catch (error) {
      console.error('Error checking route permissions:', error);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (!hasPermission()) {
          return <Redirect to="/404" />;
        }

        return Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

const Routes = (props) => {
  return (
    <Switch>
      <Redirect from="/" to={props.path} exact />
      {RouteConfig.map((route) => (
        <ProtectedRoute
          key={route.routeId}
          path={route.path}
          routeId={route.routeId}
          component={route.component}
          exact={route.exact}
          layout={route.layout}
          isPublic={route.public || false}
        />
      ))}
      
      {/* <Route path="/404" component={() => <div>Page Not Found</div>} /> */}
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;