import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPaginate from 'react-paginate';

import { red } from '@material-ui/core/colors';

import { Grid, Button } from '@material-ui/core';
import { sendHttpCall } from 'shareService/httpService';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import BlogCard from './component/blogCard';
import './blog.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    maxWidth: 445
  },
  contentBody: {
    padding: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  },
  toggleGroup: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden'
  },
  toggleButton: {
    textTransform: 'none',
    fontWeight: 500,
    width: '120px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    '&:not(.Mui-selected)': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.grey[200]
      }
    }
  }
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();

  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [viewMode, setViewMode] = useState('published');

  const handlePageChange = (page) => {
    setCurrentPage(page.selected + 1);
    console.log(page);
  };

  // Handle toggle changes
  const handleToggleChange = (event, newView) => {
    if (newView) {
      setViewMode(newView);
      setCurrentPage(1); // reset page
    }
  };

  useEffect(() => {
    let baseUrl = 'blogs/getAll';

    if (viewMode === 'drafts') {
      baseUrl = 'blogs/getAllDrafts';
    } else if (viewMode === 'articles') {
      baseUrl = 'blogs/getAllArticles';
    }

    const url = `${baseUrl}?limit=8&page=${currentPage - 1}`;

    sendHttpCall('GET', url, null, props).then((response) => {
      setPages(Math.ceil(response.count / 8));
      setData(response.blogs);
      console.log(response);
    });
  }, [viewMode, currentPage, props]);

  return (
    <div className={classes.contentBody}>
      <div className="marketingCampaign__title blogs_Container">

        {/* Title changes based on active viewMode */}
        <span>
          {viewMode === 'drafts'
            ? 'Draft Blogs'
            : viewMode === 'articles'
            ? 'Articles'
            : 'Published Blogs'}
        </span>

        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => {
            props.history.push(`/edit-blogs`);
          }}
        >
          Create New
        </Button>

        {/* Toggle Button Group for Published / Articles / Drafts */}
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleToggleChange}
          className={classes.toggleGroup}
          style={{ marginLeft: '10px' }}
        >
          <ToggleButton
            value="published"
            className={classes.toggleButton}
          >
            PUBLISHED
          </ToggleButton>
          <ToggleButton
            value="articles"
            className={classes.toggleButton}
          >
            ARTICLES
          </ToggleButton>
          <ToggleButton
            value="drafts"
            className={classes.toggleButton}
          >
            DRAFTS
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className="blog-list-wrapper">
        <Grid container spacing={3}>
          {data.length === 0 && (
            <div
              style={{
                margin: '5rem 0',
                textAlign: 'center',
                width: '100%',
                fontFamily: 'Roboto',
                fontSize: '1.1rem',
                fontWeight: '500'
              }}
            >
              No Data Found
            </div>
          )}
          {data.map((d, idx) => (
            <BlogCard key={idx} data={d} props={props} />
          ))}
        </Grid>
      </div>

      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel=".."
        breakClassName="break-me"
        pageCount={pages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}
