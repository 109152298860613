import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography, Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  tagStyle: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.875rem'
  },
  tagStyle1: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: '#008000',
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.875rem',
    cursor: 'pointer'
  }
}));

const Profile = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  // 1) Basic user info from localStorage
  const user = {
    name: localStorage.getItem('user_name')   || 'Goodspace User',
    avatar: localStorage.getItem('user_imageId') || '',
    bio:   localStorage.getItem('user_email') || '',
    type:  localStorage.getItem('user_type')  || 'ADMIN',
    adminId: localStorage.getItem('admin_id')
  };

  // 2) Retrieve the "permissions" string from localStorage
  let storedPerms = localStorage.getItem('permissions') || '';

  // 3) Convert that string into an array
  let finalPermissions = [];
  if (storedPerms) {
    // Try to parse as JSON first; if it fails, assume comma-separated
    try {
      // e.g. '["manage-admins","boost-posts"]'
      const parsed = JSON.parse(storedPerms);
      finalPermissions = Array.isArray(parsed) ? parsed : [parsed];
    } catch (err) {
      // If not valid JSON, treat it as a comma-separated string
      // e.g. "manage-admins,boost-posts"
      finalPermissions = storedPerms.split(',').map((p) => p.trim());
    }
  }

  // 4) Determine if "manage-admins" is in finalPermissions
  // const isSuperAdmin    = (user.type === 'SUPER_ADMIN');
  // const isAdmin         = (user.type === 'ADMIN');
  const canManageAdmins = finalPermissions.includes('manage-admins');

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/settings"
      />

      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>

      {user.bio && (
        <Typography variant="body2">
          {user.bio}
        </Typography>
      )}

      {/* Show user type */}
      <Chip
        label={user.type}
        size="small"
        color="primary"
        className={classes.tagStyle}
      />

      {/**
       * Show "MANAGE ADMINS" if:
       *  finalPermissions includes "manage-admins"
       */}
      {(canManageAdmins) && (
        <Chip
          label="MANAGE ADMINS"
          size="small"
          color="primary"
          className={classes.tagStyle1}
          component={RouterLink}
          to="/manage-gs-admins"
        />
      )}
      {(canManageAdmins) && (
        <Chip
          label="MANAGE ROUTES"
          size="small"
          color="primary"
          className={classes.tagStyle1}
          component={RouterLink}
          to="/manage-gs-admins-routes"
        />
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
