import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Chip,
} from '@material-ui/core';
import { Bar, Pie, Polar } from 'react-chartjs-2';
import { sendHttpCall, Toast } from 'shareService/httpService';
import moment from 'moment';
import MVPIMAGE from './MVP.png';

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  '@import': [
    `url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap')`,
  ],
  root: {
    padding: theme.spacing(4),
    backgroundColor: '#f0f4f8',
    fontFamily: 'Poppins, sans-serif',
    minHeight: '100vh',
  },
  section: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  totalRevenue: {
    backgroundColor: '#4a90e2',
    color: '#ffffff',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  breakdownText: {
    color: '#4a90e2',
    fontWeight: 500,
  },
  polarChart: {
    marginTop: theme.spacing(3),
  },
  mvpBox: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: '#ffd700',
    color: '#ffffff',
    fontWeight: 600,
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
      animation: `$shimmer 2s infinite`,
    }
  },
  '@keyframes shimmer': {
    '0%': {
      left: '-100%',
    },
    '100%': {
      left: '100%',
    },
  },
  filterContainer: {
    marginBottom: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f9f9f9',
    width: 250,
    height: 80
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  totalChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#297bca',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  revenueCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #155724',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#d4edda',
    width: 250,
    height: 80
  },
  revenueChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#155724',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  SalesCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid rgb(218, 84, 0)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: 'rgb(255, 223, 205)',
    width: 250,
    height: 80
  },
  SalesChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: 'rgb(218, 84, 0)',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1)
  },
  MVPCard: {
    marginBottom: theme.spacing(2),
    border: '2px solid #6a1b9a', // Dark purple for border
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#f3e5f5', // Light purple for background
    width: 250,
    height: 80,
    position: "relative",
    "&::after": {
      content: '""',
      width: '40px',
      height: "40px",
      position: "absolute",
      top: '10px',
      right: "10px",
      backgroundImage: `url(${MVPIMAGE})`,
      backgroundSize: 'contain'
    }
  },
  MVPChip: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    backgroundColor: '#6a1b9a', // Dark purple for chip
    color: '#fff',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    border: 'none'
  },
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
  },
  individualPolarChart: {
    marginTop: theme.spacing(3),
    height: '300px',
  },
  individualChartContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(2),
  },
  shimmerAnimation: {
    animation: `$shimmer 2s infinite`,
  },
}));

// ChartJS.register(ArcElement, Tooltip, Legend);
const RevenueBreakdown = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [revenueData, setRevenueData] = useState(null);
  const [dateFilterType, setDateFilterType] = useState('Month');
  const [filterValue, setFilterValue] = useState('December');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  
  const calculateDateRange = (filterType, value) => {
    const today = moment();
    let start, end;

    switch (filterType) {
      case 'Days':
        switch (value) {
          case 'Today':
            start = today.clone().startOf('day');
            end = today.clone().endOf('day');
            break;
          case 'Last 7 Days':
            start = today.clone().subtract(6, 'days').startOf('day');
            end = today.clone().endOf('day');
            break;
          case 'Last 30 Days':
            start = today.clone().subtract(29, 'days').startOf('day');
            end = today.clone().endOf('day');
            break;
          default:
            start = today.clone().startOf('day');
            end = today.clone().endOf('day');
        }
        break;
      case 'Month':
        start = moment(`${value} ${selectedYear}`, 'MMMM YYYY').startOf('month');
        end = moment(`${value} ${selectedYear}`, 'MMMM YYYY').endOf('month');
        break;
      case 'Quarter':
        const quarter = parseInt(value.charAt(1), 10);
        start = moment().year(selectedYear).quarter(quarter).startOf('quarter');
        end = moment().year(selectedYear).quarter(quarter).endOf('quarter');
        break;
      default:
        start = today.clone().startOf('day');
        end = today.clone().endOf('day');
    }

    return {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    };
  };

  const [startDate, setStartDate] = useState(calculateDateRange(dateFilterType, filterValue).start);
  const [endDate, setEndDate] = useState(
    calculateDateRange(dateFilterType, filterValue).end
  );

  useEffect(() => {
    fetchRevenueData();
  }, [startDate, endDate]);

  const fetchRevenueData = async () => {
    setLoading(true);
    const query = `?start_date=${startDate}&end_date=${endDate}`;
    try {
      const response = await sendHttpCall('GET', `sales/revenue_breakdown${query}`);
      if (response) {
        setRevenueData(response);
      }
    } catch (error) {
      Toast('Error fetching revenue data');
    } finally {
      setLoading(false);
    }
  };

  const handleYearChange = (event) => {
    const year = Number(event.target.value);
    setSelectedYear(year);
  };

  useEffect(() => {
    const {start, end} = calculateDateRange(dateFilterType, filterValue)
    setStartDate(start);
    setEndDate(end);
  }, [selectedYear]);

  const handleDateFilterChange = (event) => {
    console.log(event.target.value);
    const filter = event.target.value;
    setDateFilterType(filter);
    setFilterValue(getDefaultFilterValue(filter));
    const { start, end } = calculateDateRange(filter, getDefaultFilterValue(filter));
    setStartDate(start);
    setEndDate(end);
  };

  const handleFilterValueChange = (event) => {
    console.log(event.target.value);
    const value = event.target.value;
    setFilterValue(value);
    const { start, end } = calculateDateRange(dateFilterType, value);
    setStartDate(start);
    setEndDate(end);
  };

  const getDefaultFilterValue = (filterType) => {
    switch (filterType) {
      case 'Days':
        return 'Today';
      case 'Month':
        return moment().format('MMMM');
      case 'Quarter':
        return `Q${moment().quarter()}`;
      default:
        return 'Month';
    }
  };


  const generateChartColors = (count) => {
    const predefinedColors = [
      'rgba(54, 162, 235, 0.6)',
      'rgba(255, 99, 132, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(75, 192, 192, 0.6)',
      'rgba(153, 102, 255, 0.6)',
      'rgba(255, 159, 64, 0.6)',
    ];
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(predefinedColors[i % predefinedColors.length]);
    }
    return colors;
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" align="center" color="primary">
          Loading Revenue Breakdown...
        </Typography>
      </div>
    );
  }

  if (!revenueData) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" align="center">
          No data available
        </Typography>
      </div>
    );
  }

  const { totalRevenue, organicRevenue, salesRevenue, individualSalesRevenue } = revenueData;

  // Prepare data for Revenue Breakdown Polar Chart
  const revenueBreakdownData = {
    labels: ['Organic Revenue', 'Sales Revenue'], // Labels for the x-axis
    datasets: [
      {
        label: 'Revenue Breakdown', // Label for the dataset
        data: [organicRevenue, salesRevenue], // Data points for the bar chart
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'], // Bar colors
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'], // Bar border colors
        borderWidth: 1, // Width of the bar borders
      },
    ],
  };

  // Prepare data for Individual Sales Revenue Polar Chart
  const individualPolarData = {
    labels: individualSalesRevenue.map((item) => item.adminName),
    datasets: [
      {
        label: 'Individual Sales Revenue',
        data: individualSalesRevenue.map((item) => item.revenue),
        backgroundColor: generateChartColors(individualSalesRevenue.length),
      },
    ],
  };

  // Determine MVP
  const mvp = individualSalesRevenue.reduce(
    (prev, current) => (prev.revenue > current.revenue ? prev : current),
    { revenue: 0, adminName: 'N/A' }
  );

  const options = {
    responsive: true,
    tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                const label = data.labels[tooltipItem.index];
                const value = data.datasets[0].data[tooltipItem.index];
                console.log(label);
                return `${label} ₹${value.toLocaleString()}`; // Add rupee sign to the value
            },
        },
    },
  };

  return (
    <div className={classes.root}>
      {/* Filters */}
      <Grid container spacing={4} className={classes.filterContainer}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="filter-type-label">Filter By</InputLabel>
            <Select
              labelId="filter-type-label"
              value={dateFilterType}
              onChange={handleDateFilterChange}
              label="Filter By"
            >
              <MenuItem value="Days">Days</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Quarter">Quarter</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {(dateFilterType === "Month" || dateFilterType === "Quarter") && (
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="year-select-label"> Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedYear}
                onChange={handleYearChange}
                label="Year"
              >
                {/* Generate year options dynamically */}
                {[...Array(5)].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          {/* <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="filter-value-label">Select</InputLabel>
            <Select
              labelId="filter-value-label"
              value={filterValue}
              onChange={handleFilterValueChange}
              label="Select"
            >
              {dateFilterType === 'Days' && (
                <>
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                </>
              )}
              {dateFilterType === 'Month' && (
                <>
                  {moment.months().map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </>
              )}
              {dateFilterType === 'Quarter' && (
                <>
                  <MenuItem value="Q1">Q1</MenuItem>
                  <MenuItem value="Q2">Q2</MenuItem>
                  <MenuItem value="Q3">Q3</MenuItem>
                  <MenuItem value="Q4">Q4</MenuItem>
                </>
              )}
            </Select>
          </FormControl> */}
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="filter-value-label">Select</InputLabel>
            <Select
              labelId="filter-value-label"
              value={filterValue}
              onChange={handleFilterValueChange}
              label="Select"
            >
              {dateFilterType === 'Days' &&
                [
                  <MenuItem key="Today" value="Today">
                    Today
                  </MenuItem>,
                  <MenuItem key="Last7Days" value="Last 7 Days">
                    Last 7 Days
                  </MenuItem>,
                  <MenuItem key="Last30Days" value="Last 30 Days">
                    Last 30 Days
                  </MenuItem>,
                ]}
              {dateFilterType === 'Month' &&
                moment.months().map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              {dateFilterType === 'Quarter' &&
                [
                  <MenuItem key="Q1" value="Q1">
                    Q1
                  </MenuItem>,
                  <MenuItem key="Q2" value="Q2">
                    Q2
                  </MenuItem>,
                  <MenuItem key="Q3" value="Q3">
                    Q3
                  </MenuItem>,
                  <MenuItem key="Q4" value="Q4">
                    Q4
                  </MenuItem>,
                ]}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {/* Left Side: Total Revenue & Breakdown */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className={classes.totalRevenue}>
              Total Revenue: ₹{totalRevenue.toLocaleString()}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              { individualSalesRevenue?.map((a, i) => a.adminId !== 32 && (
                <Card className={(i === 0 && a.revenue !== 0) ? classes.MVPCard : classes.card} key={a.adminId}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="subtitle1">{a.adminName}</Typography>
                    <Chip
                      label={`₹ ${a.revenue}`}
                      color="primary"
                      variant="outlined"
                      className={(i===0 && a.revenue !== 0)? classes.MVPChip : classes.totalChip}
                    />
                  </CardContent>
                </Card>
              ))}
              <Card className={classes.revenueCard}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1">Organic Revenue</Typography>
                  <Chip
                    label={`₹ ${organicRevenue}`}
                    className={classes.revenueChip}
                  />
                </CardContent>
              </Card>
              <Card className={classes.SalesCard}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1">Sales Revenue</Typography>
                  <Chip
                    label={`₹ ${salesRevenue}`}
                    className={classes.SalesChip}
                  />
                </CardContent>
              </Card>
            </div>
            <div>

            </div>
          </Grid>
          {/* <Grid item xs={6}>
            <Paper className={classes.section}>
              <Typography variant="h5" gutterBottom>
                MVP
              </Typography>
              <Box className={classes.mvpBox}>
                {mvp.adminName} - ₹{mvp.revenue.toLocaleString()}
              </Box>
            </Paper>
          </Grid> */}
        </Grid>

        {/* Right Side: MVP & Individual Revenue */}
        <Grid container spacing={4}>
          {parseInt(totalRevenue, 10) > 0  && (
            <Grid item xs={6}>
              <Paper className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  Revenue Breakdown
                </Typography>
                <Box className={classes.polarChart}>
                  <Pie data={revenueBreakdownData} options={options} />
                </Box>
              </Paper>
            </Grid>
          )}
          { parseInt(salesRevenue, 10) > 0 && (
            <Grid item xs={6}>
              <Paper className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  Individual Sales Revenue
                </Typography>
                <Box className={classes.polarChart}>
                  <Pie data={individualPolarData} options={options} />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default RevenueBreakdown;
