import { RouteWithLayout } from './components';
import ClickTracking from 'views/ClickTracking/ClickTracking';
import NotificationsTracking from 'views/ClickTracking/NotificationsTracking';
import AlertsReportDownload from 'views/AlertsReportDownload/AlertsReportDownload';
import StartedInterviews from 'views/Interviews/StartedInterviews';
import StuckInterviews from 'views/Interviews/StuckInterviews';
import FinishedInterviews from 'views/Interviews/FinishedInterviews';
import RohanBotLogs from 'views/RohanBotLogs/RohanBotLogs';
import RohanBotChats from 'views/RohanBotLogs/RohanChats';
import SakshiBotChats from 'views/SakshiBotLogs/SakshiChats';
import SakshiBotLogs from 'views/SakshiBotLogs/SakshiBotLogs';
import RecommendedCandidates from 'views/Interviews/RecommendedCandidates';
import InterviewCounts from 'views/Interviews/InterviewCounts';
import InterviewData from 'views/Interviews/InterviewData';
import InterviewDetails from 'views/Interviews/InterviewDetails';
import OnboardingChatDetails from 'views/Interviews/OnboardingChatDetails';
import ClickTrackingSource from 'views/ClickTracking/ClickTrackingBySource';
import ClickTrackingByLink from 'views/ClickTracking/ClickTrackingByLink';
import WhatsAppCommunications from 'views/Communications/WhatsappCommunications';
import Opportunities from 'views/Sales/Opportunities';
import Pipeline from 'views/Sales/SalesPipeline';
import RevenueBreakdown from 'views/Sales/RevenueBreakdown';
import Leads from 'views/Sales/Leads';
import AddLead from 'views/Sales/AddLead';
import SalesDashboard from 'views/Sales/SalesDashboard';
import SendNotification from 'views/Sales/SendNotification';
import WorkReports from 'views/Sales/WorkReports';
import ManagementReports from 'views/Sales/ManagementReports';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import BlogDetails from './views/blog/details/index';
import EditBlogs from './views/blog/edit/index';
import KpiPage from './views/Dashboard/KpiPage';
import {
  Dashboard as DashboardView,
  TopSkill as TopSkillView,
  TopDesignation as TopDesignationView,
  socialmedia as socialmedia,
  socialmedialist as socialmedialist,
  SkillUsersList as SkillUsersListView,
  UserEngagement as UserEngagementView,
  //ProductList as ProductListView,
  Typography as TypographyView,
  Icons as IconsView,
  UserDetail as UserDetailView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ExpansionSideNav as ExpansionSideNav,
  InventoryManagment as InventoryManagment
} from './views';
import DashboardOld from 'views/Dashboard/DashboardOld';
import JobDetail  from './views/OfferDetail/OfferDetail'
import GCoins from './views/Dashboard/components/GCoins/GCoins';
import DashboardUsa from './views/Dashboard/components/USA/USADashboard';
import DashboardUK from './views/Dashboard/components/UK/UKDashboard';
import VolunteerData from './views/Dashboard/components/VolunteerData/VolunteerData';
import VolunteerDataList from './views/Dashboard/components/VolunteerData/VolunteerDataList';
import {
  BusinessServiceType as BusinessServiceType,
  Degrees as Degrees,
  DegreeLevels as DegreeLevels,
  DegreeFields as DegreeFields,
  Designations as Designations,
  Fitnesses as Fitnesses,
  HabitFrequencies as HabitFrequencies,
  IndustryTypes as IndustryTypes,
  Interests as Interests,
  PoliticalViews as PoliticalViews,
  RelationshipStatus as RelationshipStatus,
  Religions as Religions,
  Purposes as Purposes,
  Skills as Skills,
  TzPoints as TzPoints,
  Relationships as Relationships
} from './views/Masters';
import { ZeroCountryCode as ZeroCountryCodeView } from './views/DBCorruptions';
import ChatsView from './views/Chats';
import {
  ActiveUsersList as ActiveUsersListView,
  VerifiedUsersList as VerifiedUsersListView,
  UserRatingAnalytics as UserRatingAnalyticsView,
  DownloadCount as DownloadCountView,
  LinkClicks as LinkClicksView,
  ApplicationExpired as ApplicationExpiredView,
  ApplicationAccepted as ApplicationAcceptedView,
  JobsApplication as JobsApplicationView,
  NoJobs as NoJobsView,
  JobPostings as JobPostingsView,
  Hire as HireView,
  InactiveUsersList as InactiveUsersListView,
  UserCountByCity as UserCountByCityView,
  UserSearch as UserSearchView,
  ActiveSocialUsers as ActiveSocialUsersView,
  HirerSearch as HirerSearchView,
  UserRequest as UserRequestView,
  UserMasterRequest as UserMasterRequestView,
  Status2SocialUsers as Status2SocialUsersView,
  NewJoinRequestList as NewJoinRequestListView,
  ReportedJobs,
  RedirectJobClick
} from './views/UserList';
import candidateFeedback from 'views/candidateFeedback';
import { AuxCardsList as AuxCardsListView } from './views/Cards';
import { LogsExplorer } from 'views';
import MarketingCampaigns from 'views/MarketingCampaigns';
import CreatePaymentLink from 'views/PaymentServices/createPaymentLink';
import ManualPaymentGateway from 'views/PaymentServices/manualPaymentGateway';
import AddCampaign from 'views/MarketingCampaigns/AddCampaign';
import UsersByCampaign from 'views/MarketingCampaigns/UsersByCampaign';
import RedirectLinkData from 'views/MarketingCampaigns/RedirectLinkData';
import PromoCodes from 'views/PromoCodes';
import AddPromoCode from 'views/PromoCodes/AddPromoCode';
import ViewJobsUnderReview from 'views/JobPosts/ViewJobsUnderReview';
import ViewJobsOnHold from 'views/JobPosts/ViewJobsOnHold';
import ViewAllActiveJobs from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview';
import FullMemberList from 'views/UserList/FullMemberList';
import FeedbackRequestData from 'views/UserList/FeedbackRequestData';
import ReportGenerator from 'views/Dashboard/components/ReportGenerator';
import SurveyMessage from 'views/SurveyMessage';
import EveryTable  from 'views/Dashboard/components/EveryTable';
import ViewAllClosedJobs from 'views/JobPosts/ViewAllClosedJobs';
import AddJob from 'views/JobPosts/AddJob/AddJob';
import DesignationUsersList from 'views/DesignationUsersList';
import ViewSocialExpUnderReview from 'views/SocialExpPosts/ViewSocialExpUnderReview';
import ViewSocialActiveExp from 'views/SocialExpPosts/ViewActiveSocialExp';
import ApplicationsSource from './views/Dashboard/components/ApplicationsSource/ApplicationsSource'
import {
  GCoinsTransactionsByUser,
  GCoinsTransactionsGrouped
} from 'views/GCoins';
import ViewAllJobsByUserAndType from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/ViewAllJobsByUserAndType';
import Status0Search from 'views/Status0Search/Status0Search';
import PotentialCandidates from 'views/UserList/JobsApplication/PotentialCandidates';
import PotentialCandidatess3 from 'views/UserList/JobsApplication/PotentialCandidatess3';
import PotentialCandidatess3Rerank from 'views/UserList/JobsApplication/PotentialCandidatess3Rerank';
import PotentialCandidatesvs3 from 'views/UserList/JobsApplication/Potentials3VectorUsers';
import PotentialCandidatess8m from 'views/UserList/JobsApplication/PotentialCandidates8m'
import PotentialCandidatess8e from 'views/UserList/JobsApplication/PotentialCandidatess8e'
import PotentialCandidatess8EmailFullText from 'views/UserList/JobsApplication/PotentialCandidateUser8FullText'
import PotentialCandidatesAi from 'views/UserList/JobsApplication/PotentialCandidatesAi';
import PotentialCandidatesS3Weighted from 'views/UserList/JobsApplication/PotentialCandidatesS3Weighted';
import PitchUnderReview from 'views/PitchUnderReview/PitchUnderReview';
import IntroUnderReview from 'views/PitchUnderReview/IntroUnderReview';
import PitchActive from 'views/PitchUnderReview/PitchActive';
import ViewAllAdmins from 'views/Admins/Admins';
import AiInterviewer from 'views/AiInterviewer/AiInterviewer';
import OfferTypeSwitch from 'views/OfferDashboard/OfferDashboard';
import ManageRoutes from 'views/ManageRoutes/ManageRoutes';
import { exact } from 'prop-types';

const routes = [

  {
    routeId: 'user_search',
    path: '/search-user',
    component: UserSearchView,
    layout: MainLayout,
    exact: true,
    name: 'Search User'
  },
  {
    routeId: 'verified_users',
    path: '/verified-users/:start_date/:end_date',
    component: VerifiedUsersListView,
    layout: MainLayout,
    exact: true,
    name: 'Verified Users'
  },
  {
    routeId: 'redirect_unique_jobs',
    path: '/redirect_jobs_clicks/unique/:offerId',
    component: RedirectJobClick,
    layout: MainLayout,
    exact: true,
    name: 'Unique Job Clicks'
  },
  {
    routeId: 'redirect_all_jobs',
    path: '/redirect_jobs_clicks/all/:offerId',
    component: RedirectJobClick,
    layout: MainLayout,
    exact: true,
    name: 'All Job Clicks'
  },
  {
    routeId: 'reported_jobs',
    path: '/reports/jobs/:offerId',
    component: ReportedJobs,
    layout: MainLayout,
    exact: true,
    name: 'Reported Jobs'
  },
  {
    routeId: 'link_clicks',
    path: '/linkclicks/:start_date/:end_date',
    component: LinkClicksView,
    layout: MainLayout,
    exact: true,
    name: 'Link Clicks'
  },
  {
    routeId: 'download_count',
    path: '/downloadcount/:start_date/:end_date',
    component: DownloadCountView,
    layout: MainLayout,
    exact: true,
    name: 'Download Count'
  },
  {
    routeId: 'jobs_by_user',
    path: '/jobs-by-user/:userId/:type',
    component: ViewAllJobsByUserAndType,
    layout: MainLayout,
    exact: true,
    name: 'Jobs by User'
  },
  {
    routeId: 'survey_message',
    path: '/send_survey/:name/:userId',
    component: SurveyMessage,
    layout: MainLayout,
    exact: true,
    name: 'Send Survey'
  },
  {
    routeId: 'zero_country_codes',
    path: '/zero-country-codes',
    component: ZeroCountryCodeView,
    layout: MainLayout,
    exact: true,
    name: 'Invalid Country Codes'
  },
  {
    routeId: 'chats_view',
    path: '/chats/:chatType/:userIds',
    component: ChatsView,
    layout: MainLayout,
    exact: true,
    name: 'Chats'
  },
  {
    routeId: 'volunteer_data',
    path: '/volunteer',
    component: VolunteerData,
    layout: MainLayout,
    exact: true,
    name: 'Volunteer Data'
  },
  {
    routeId: 'volunteer_data_list',
    path: '/volunteer/:type',
    component: VolunteerDataList,
    layout: MainLayout,
    exact: true,
    name: 'Volunteer Data List'
  },



  {
    routeId: 'page_dashboard',
    path: '/dashboard',
    component: DashboardView,
    layout: MainLayout,
    exact: true,
    name: 'Dashboard',
    icon: 'Dashboard',
    parentGroupId: 'group_main',
    type: 'page'
  },
  {
    routeId: 'page_auto_interview',
    path: '/auto-interview',
    component: InterviewCounts,
    layout: MainLayout,
    exact: true,
    name: 'Auto Interview',
    icon: 'InterviewIcon',
    parentGroupId: 'group_main',
    type: 'page'
  },
  {
    routeId: 'page_report_generator',
    path: '/report',
    component: ReportGenerator,
    layout: MainLayout,
    exact: true,
    name: 'Report Generator',
    icon: 'PieChart',
    parentGroupId: 'group_main',
    type: 'page'
  },
  {
    routeId: 'page_sales_dashboard',
    path: '/sales-dashboard',
    component: SalesDashboard,
    layout: MainLayout,
    exact: true,
    name: 'Dashboard',
    icon: 'Dashboard',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_piechart',
    path: '/revenue-breakdown',
    component: RevenueBreakdown,
    layout: MainLayout,
    exact: true,
    name: 'Revenue Breakdown',
    icon: 'PieChart',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_my_pipeline',
    path: '/sales-pipeline',
    component: Pipeline,
    layout: MainLayout,
    exact: true,
    name: 'My Pipeline',
    icon: 'People',  
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_opportunities',
    path: '/opportunities',
    component: Opportunities,
    layout: MainLayout,
    exact: true,
    name: 'Opportunities',
    icon: 'People',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_leads',
    path: '/leads',
    component: Leads,
    layout: MainLayout,
    exact: true,
    name: 'Leads',
    icon: 'People',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_add_lead',
    path: '/add-lead',
    component: AddLead,
    layout: MainLayout,
    exact: true,
    name: 'Add Lead',
    icon: 'SettingsIcon',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'paeg_send_notification',
    path: '/push-notifications',
    component: SendNotification,
    layout: MainLayout,
    exact: true,
    name: 'Send Notification',
    icon: 'NotificationImportant',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_management_report',
    path: '/management-reports',
    component: ManagementReports, 
    layout: MainLayout,
    exact: true,
    name: 'Management Report',
    icon: 'People',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_work_report',
    path: '/work-reports',
    component: WorkReports,
    layout: MainLayout,
    exact: true,
    name: 'Work Report',
    icon: 'People',
    parentGroupId: 'sales_main',
    type: 'page'
  },
  {
    routeId: 'page_add_new_campaign',
    path: '/add-marketing-campaign',
    component: AddCampaign,
    layout: MainLayout,
    exact: true,
    name: 'Add New Campaign',
    icon: 'SettingsIcon',
    parentGroupId: 'marketing_main',
    type: 'page'
  },
  {
    routeId: 'page_view_campaigns',
    path: '/marketing-campaigns',
    component: MarketingCampaigns,
    layout: MainLayout,
    exact: true, 
    name: 'View Campaigns',
    icon: 'VerifiedUser',
    parentGroupId: 'marketing_main',
    type: 'page'
  },
  {
    routeId: 'page_view_users_by_campaign',
    path: '/marketing-campaigns-user',
    component: UsersByCampaign,
    layout: MainLayout,
    exact: true,
    name: 'View Users By Campaign',
    icon: 'People',
    parentGroupId: 'marketing_main',
    type: 'page'
  },
  {
    routeId: 'page_view_link_click_data',
    path: '/marketing-campaigns-clicks',
    component: RedirectLinkData,
    layout: MainLayout,
    exact: true,
    name: 'View Link Click Data',
    icon: 'People',
    parentGroupId: 'marketing_main',
    type: 'page'
  },
  {
    routeId: 'page_create_new_payment_link',
    path: '/create-payment-link',
    component: CreatePaymentLink,
    layout: MainLayout,
    exact: true,
    name: 'Create New Payment Link',
    icon: 'SettingsIcon',
    parentGroupId: 'payment_main',
    type: 'page'
  },
  {
    routeId: 'page_manual_payment_gateway',
    path: '/manual-payment-gateway',
    component: ManualPaymentGateway,
    layout: MainLayout,
    exact: true,
    name: 'Manual Payment Gateway',
    icon: 'SettingsIcon',
    parentGroupId: 'payment_main',
    type: 'page'
  },
  {
    routeId: 'page_view_under_review',
    path: '/social-experience-under-review',
    component: ViewSocialExpUnderReview,
    layout: MainLayout,
    exact: true,
    name: 'View Under Review',
    icon: 'SettingsIcon',
    parentGroupId: 'social_main',
    type: 'page'
  },
  {
    routeId: 'page_view_all_experiences',
    path: '/active-social-experience',
    component: ViewSocialActiveExp,
    layout: MainLayout,
    exact: true,
    name: 'View All Experiences',
    icon: 'VerifiedUser',
    parentGroupId: 'social_main',
    type: 'page'
  },
  {
    routeId: 'page_active_jobs',
    path: '/active-jobs',
    component: ViewAllActiveJobs,
    layout: MainLayout,
    exact: true,
    name: 'Active Jobs',
    icon: 'VerifiedUser',
    parentGroupId: 'job_main',
    type: 'page'
  },
  {
    routeId: 'page_job_view_under_review',
    path: '/jobs-under-review/:start_date?/:end_date?',
    component: ViewJobsUnderReview,
    layout: MainLayout,
    exact: true,
    name: 'View Under Review',
    icon: 'SettingsIcon',
    parentGroupId: 'job_main',
    type: 'page'
  },
  {
    routeId: 'edit_blogs',
    name: 'Edit Blogs',
    path: '/edit-blogs/:blog_id',
    component: EditBlogs,
    layout: MainLayout,
    exact: true,
  },
  // {
  //   routeId: 'page_job_view_under_review_dated',
  //   name: 'View Under Review',
  //   path: '/jobs-under-review/:start_date/:end_date',
  //   component: ViewJobsUnderReview,
  //   layout: MainLayout,
  //   exact: true,
  // },
  {
    routeId: 'page_view_on_hold_jobs',
    path: '/jobs-on-hold',
    component: ViewJobsOnHold,
    layout: MainLayout,
    exact: true,
    name: 'View On Hold Jobs',
    icon: 'HourglassFullRounded',
    parentGroupId: 'job_main',
    type: 'page'
  },
  {
    routeId: 'offer_dashboard_Job_Posts',
    name: 'Job Posts',
    path: '/offer-dashboard/:offerType/:status?',
    meta: {
      offerType: 1,
      status: null
    },
    component: OfferTypeSwitch,
    layout: MainLayout,
    exact: true,
    parentGroupId: 'offers',
    type: 'page'
  },
  {
    routeId: 'offer_dashboard_ai_interview',
    name: 'AI Interview',
    path: '/offer-dashboard/:offerType/:status?',
    meta: {
      offerType: 4,
      status: null
    },
    component: OfferTypeSwitch,
    layout: MainLayout,
    exact: true,
    parentGroupId: 'offers',
    type: 'page'
  },
  {
    routeId: 'page_view_closed_jobs',
    path: '/closed-jobs',
    component: ViewAllClosedJobs,
    layout: MainLayout,
    exact: true,
    name: 'View Closed Jobs',
    icon: 'VerifiedUser',
    parentGroupId: 'job_main',
    type: 'page'
  },
  {
    routeId: 'page_add_job',
    path: '/add-job',
    component: AddJob,
    layout: MainLayout,
    exact: true,
    name: 'Add Job',
    icon: 'VerifiedUser',
    parentGroupId: 'job_main',
    type: 'page'
  },
  {
    routeId: 'page_pitch_view_under_review',
    path: '/pitch-under-review',
    component: PitchUnderReview,
    layout: MainLayout,
    exact: true,
    name: 'View Under Review',
    icon: 'SettingsIcon',
    parentGroupId: 'pitch_main',
    type: 'page'
  },
  {
    routeId: 'page_pitch_all',
    path: '/pitch-all',
    component: PitchActive,
    layout: MainLayout,
    exact: true,
    name: 'All',
    icon: 'SettingsIcon',
    parentGroupId: 'pitch_main',
    type: 'page'
  },
  {
    routeId: 'page_intro_view_under_review',
    path: '/intro-under-review',
    component: IntroUnderReview,
    layout: MainLayout,
    exact: true,
    name: 'View Under Review',
    icon: 'SettingsIcon',
    parentGroupId: 'intro_main',
    type: 'page'
  },
  {
    routeId: 'page_add_new_promo_code',
    path: '/add-promo-code',
    component: AddPromoCode,
    layout: MainLayout,
    exact: true,
    name: 'Add New Promo Code',
    icon: 'SettingsIcon',
    parentGroupId: 'promo_main',
    type: 'page'
  },
  {
    routeId: 'page_view_promo_codes',
    path: '/promo-codes',
    component: PromoCodes,
    layout: MainLayout,
    exact: true,
    name: 'View Promo Codes',
    icon: 'VerifiedUser',
    parentGroupId: 'promo_main',
    type: 'page'
  },
  {
    routeId: 'page_aux_cards',
    path: '/aux-cards',
    component: AuxCardsListView,
    layout: MainLayout,
    exact: true,
    name: 'Aux cards',
    icon: 'ViewCarousel',
    parentGroupId: 'cards_main',
    type: 'page'
  },
  {
    routeId: 'page_search_user',
    path: '/search-user',
    component: UserSearchView,
    layout: MainLayout,
    exact: true,
    name: 'Search User',
    icon: 'Search',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_search_hirers',
    path: '/search-hirers',
    component: HirerSearchView,
    layout: MainLayout,
    exact: true,
    name: 'Search Hirers',
    icon: 'Search',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_search_social_profiles',
    path: '/search-social-user',
    component: ActiveSocialUsersView,
    layout: MainLayout,
    exact: true,
    name: 'Search Social Profiles',
    icon: 'Search',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_active_users',
    path: '/active-users',
    component: ActiveUsersListView,
    layout: MainLayout,
    exact: true,
    name: 'Active Users',
    icon: 'VerifiedUser',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_inactive_users',
    path: '/inactive-users',
    component: InactiveUsersListView,
    layout: MainLayout,
    exact: true,
    name: 'Inactive Users',
    icon: 'People',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_latest_signups',
    path: '/signups',
    component: NewJoinRequestListView,
    layout: MainLayout,
    exact: true,
    name: 'Latest SignUps',
    icon: 'PersonAdd',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_city_wise_user_count',
    path: '/user-count-by-city',
    component: UserCountByCityView,
    layout: MainLayout,
    exact: true,
    name: 'City Wise User Count',
    icon: 'People',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_user_master_request',
    path: '/use-master-resquest',
    component: UserMasterRequestView,
    layout: MainLayout,
    exact: true,
    name: 'User Master Request',
    icon: 'SettingsIcon',
    parentGroupId: 'users_main',
    type: 'page'
  },
  {
    routeId: 'page_kpi',
    path: '/kpi',
    name: 'KPI',
    component: KpiPage,
    layout: MainLayout,
    exact: true,
    parentGroupId: "group_main"
  },
  {
    routeId: "page_applications_source",
    type: "page",
    name: "Applications Source",
    path: "/applications-source",
    component: ApplicationsSource,
    parentGroupId: "group_main",
    layout: MainLayout,
  },
  {
    routeId: 'page_blog_list',
    name: 'Blogs',
    path: '/blog-details',
    component: BlogDetails,
    layout: MainLayout,
    parentGroupId: "group_main",
    exact: true,
  },
  {
    routeId: 'page_total_user',
    name: 'Total Users',
    path: '/logs-explorer',
    component: LogsExplorer,
    layout: MainLayout,
    exact: true,
    parentGroupId: "group_main"
  },
  {
    routeId: 'job_application',
    name: 'Job Applications',
    path: '/job-application/:type/:start/:end',
    component: JobsApplicationView,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'candidate_feedback',
    name: "Candidate Feedback",
    path: '/job-application/candidate/feedBack/:offerId/:userId',
    component: candidateFeedback,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: "page_good_dex",
    type: "page",
    name: "GoodDex",
    path: "/inventory",
    component: InventoryManagment,
    parentGroupId: "group_main",
    layout: MainLayout,
  },
  {
    routeId: "page_ai_interview",
    type: "page",
    name: "AI Interviewer",
    icon: "Dashboard",
    path: "/ai-interviewer",
    component: AiInterviewer,
    layout: MainLayout,
    parentGroupId: "group_main"
  },
  // Public routes
  {
    routeId: 'manage_admins',
    path: '/manage-gs-admins',
    component: ViewAllAdmins,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'every_table',
    path: '/data/:type/:period/:start_date/:end_date',
    component: EveryTable,
    layout: MainLayout,
    exact: true,
  },

  {
    routeId: 'started_interviews',
    name: 'Started Interviews',
    path: '/started-interviews/:start_date/:end_date',
    component: StartedInterviews,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'rohan_bot_logs',
    name: "Rohan Bot Logs",
    path: '/rohan-chatbot-logs/:start_date/:end_date',
    component: RohanBotLogs,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Rohan_bot_chats',
    name: 'Rohan Bot Chats',
    path: '/rohan-chats/:userId',
    component: RohanBotChats,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'sakshi_bot_logs',
    name: "Sakshi Bot Logs",
    path: '/sakshi-chatbot-logs/:start_date/:end_date',
    component: SakshiBotLogs,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'sakshi_bot_chats',
    name: 'Sakshi Bot Chats',
    path: '/sakshi-chats/:userId',
    component: SakshiBotChats,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'interview_details_2',
    name: 'Interview Details 2',
    path: '/auto-interview/interview/:interviewId',
    component: InterviewDetails,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'interview_details',
    name: 'Interview Details',
    path: '/auto-interview/interview-by-details/:userId/:offerId/:roundNumber',
    component: InterviewDetails,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'interview_data',
    name: 'Interview Data',
    path: '/auto-interview/:type/:start_date/:end_date',
    component: InterviewData,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'onboarding_chat_details',
    name: 'Onboarding Chat Details',
    path: '/onboarding-chat/:userId',
    component: OnboardingChatDetails,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidates',
    path: '/job/potential_candidates/:offerId',
    component: PotentialCandidates,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatess3',
    path: '/job/potential_candidates_s3/:offerId',
    component: PotentialCandidatess3,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatess3Rerank',
    path: '/job/potential_candidates_s3_rerank/:offerId',
    component: PotentialCandidatess3Rerank,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatesAi',
    name: 'Potential Candidates AI',
    path: '/job/potential_candidates_ai_cutoff/:offerId',
    component: PotentialCandidatesAi,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatesS3Weighted',
    name: 'Potential Candidates S3 Weighted',
    path: '/job/potential_candidates_s3_weighted/:offerId',
    component: PotentialCandidatesS3Weighted,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatesvs3',
    path: '/job/potential_candidates_s3_vector/:offerId',
    component: PotentialCandidatesvs3,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatess8m',
    path: '/job/potential_candidates_s8m/:offerId',
    component: PotentialCandidatess8m,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatess8e',
    path: '/job/potential_candidates_s8e/:offerId',
    component: PotentialCandidatess8e,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PotentialCandidatess8EmailFullText',
    path: '/job/potential_candidates_user8_full_text/:offerId',
    component: PotentialCandidatess8EmailFullText,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Status0Search',
    path: '/search-contact-db',
    component: Status0Search,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'ClickTracking',
    path: '/tracking/:type',
    component: ClickTracking,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'ClickTrackingSource',
    path: '/tracking/:type/:source/:startDate/:endDate',
    component: ClickTrackingSource,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'ClickTrackingByLink',
    path: '/tracking/:type/:source/:link/:startDate/:endDate/users',
    component: ClickTrackingByLink,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'WhatsAppCommunications',
    path: '/communications/:type',
    component: WhatsAppCommunications,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Status2SocialUsersView',
    path: '/incomplete-social-user',
    component: Status2SocialUsersView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'user_details_view',
    name: "User Details View",
    path: '/account/:userid',
    component: UserDetailView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'offer_details',
    name: "Offer Details",
    path: '/offer/:offerId',
    component: JobDetail,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'GCoinsTransactionsByUser',
    path: '/gcoins/transactions/:code',
    component: GCoinsTransactionsByUser,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'GCoinsTransactionsGrouped',
    path: '/gcoins/:type',
    component: GCoinsTransactionsGrouped,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'NotificationsTracking',
    path: '/tracking/notifications',
    component: NotificationsTracking,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'AlertsReportDownload',
    path: '/tracking/alerts-report-generator',
    component: AlertsReportDownload,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DashboardOld',
    path: '/dashboard-old',
    component: DashboardOld,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'GCoins',
    path: '/gcoins',
    component: GCoins,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DashboardUsa',
    path: '/usa',
    component: DashboardUsa,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DashboardUK',
    path: '/uk',
    component: DashboardUK,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'UserEngagementView',
    path: '/generations',
    component: UserEngagementView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'TopSkillView',
    path: '/top-skills',
    component: TopSkillView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'TopDesignationView',
    path: '/top-job-titles',
    component: TopDesignationView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'socialmedia',
    path: '/socialmedia',
    component: socialmedia,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'socialmedialist',
    path: '/social-users/:media/:start/:end',
    component: socialmedialist,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'SkillUsersListView',
    path: '/skill-detail/:skill_id',
    component: SkillUsersListView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DesignationUsersList',
    path: '/designation-detail/:skill_id',
    component: DesignationUsersList,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'UserRequestView',
    path: '/use-resquest',
    component: UserRequestView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'FullMemberList',
    path: '/full-members/:start_date/:end_date',
    component: FullMemberList,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'FeedbackRequestData',
    path: '/feedback-requests/:start_date/:end_date',
    component: FeedbackRequestData,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'UserRatingAnalyticsView',
    path: '/dashboard/uan/:name/:user_id/:start_date/:end_date',
    component: UserRatingAnalyticsView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'SettingsView',
    path: '/settings',
    component: SettingsView,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'ExpansionSideNav',
    path: '/ExpansionSideNav',
    component: ExpansionSideNav,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'job_posting_view',
    path: '/jobs/:type/:start_date/:end_date',
    component: JobPostingsView,
    layout: MainLayout,
    exact: true,
  },
  // Master Routes
  {
    routeId: 'BusinessServiceType',
    path: '/business-service-type',
    component: BusinessServiceType,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DegreeLevels',
    path: '/degree-levels',
    component: DegreeLevels,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'DegreeFields',
    path: '/degree-fields',
    component: DegreeFields,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Degrees',
    path: '/degrees',
    component: Degrees,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Designations',
    path: '/designations',
    component: Designations,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Fitnesses',
    path: '/fitnesses',
    component: Fitnesses,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'HabitFrequencies',
    path: '/habit_frequencies',
    component: HabitFrequencies,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'IndustryTypes',
    path: '/industry_types',
    component: IndustryTypes,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Interests',
    path: '/intersets',
    component: Interests,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'PoliticalViews',
    path: '/political_views',
    component: PoliticalViews,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'RelationshipStatus',
    path: '/relationship_status',
    component: RelationshipStatus,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Relationships',
    path: '/relationships',
    component: Relationships,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Religions',
    path: '/religions',
    component: Religions,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Purposes',
    path: '/purposes',
    component: Purposes,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'Skills',
    path: '/skills',
    component: Skills,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'recommended_candidates',
    name: 'Recommended Candidates',
    path: '/recommended-candidates/:start_date/:end_date',
    component: RecommendedCandidates,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'stuck_interviews',
    name: 'Stuck Interviews',
    path: '/stuck-interviews/:start_date?/:end_date?',
    component: StuckInterviews,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'finished_interviews',
    name: 'Finished Interviews',
    path: '/finished-interviews/:start_date?/:end_date?',
    component: FinishedInterviews,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'HireView',
    path: '/hire/:type/:start_date/:end_date',
    component: HireView,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'TzPoints',
    path: '/tz-points',
    component: TzPoints,
    layout: MainLayout,
    exact: true
  },
  {
    routeId: 'manage_routes',
    path: '/manage-gs-admins-routes',
    component: ManageRoutes,
    layout: MainLayout,
    exact: true,
  },
  {
    routeId: 'SignUpView',
    path: '/sign-up',
    component: SignUpView,
    layout: MinimalLayout,
    exact: true,
    public: true,
    type: 'page'
  },
  {
    routeId: 'SignInView',
    path: '/sign-in',
    component: SignInView,
    layout: MinimalLayout,
    exact: true,
    public: true,
    type: 'page'
  },
  {
    routeId: 'Notfound',
    path: '*',
    component: NotFoundView,
    layout: MinimalLayout,
    public: true,
    type: 'page'
  }
];


// const routes = [
//     {
//       path: '/sign-up',
//       component: SignUpView,
//       layout: MinimalLayout,
//       exact: true,
//       public: true,
//     },
//     {
//       path: '/sign-in',
//       component: SignInView,
//       layout: MinimalLayout,
//       exact: true,
//       public: true,
//     },
//     {
//       routeId: 'ActiveSocialUsersView',
//       path: '/search-social-user',
//       component: ActiveSocialUsersView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'GCoinsTransactionsByUser',
//       path: '/gcoins/transactions/:code',
//       component: GCoinsTransactionsByUser,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'NotificationsTracking',
//       path: '/tracking/notifications',
//       component: NotificationsTracking,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AlertsReportDownload',
//       path: '/tracking/alerts-report-generator',
//       component: AlertsReportDownload,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'StartedInterviews',
//       path: '/started-interviews/:start_date/:end_date',
//       component: StartedInterviews,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'StuckInterviews',
//       path: '/stuck-interviews/:start_date/:end_date',
//       component: StuckInterviews,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'FinishedInterviews',
//       path: '/finished-interviews/:start_date/:end_date',
//       component: FinishedInterviews,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RohanBotLogs',
//       path: '/rohan-chatbot-logs/:start_date/:end_date',
//       component: RohanBotLogs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RohanBotChats',
//       path: '/rohan-chats/:userId',
//       component: RohanBotChats,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SakshiBotLogs',
//       path: '/sakshi-chatbot-logs/:start_date/:end_date',
//       component: SakshiBotLogs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SakshiBotChats',
//       path: '/sakshi-chats/:userId',
//       component: SakshiBotChats,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RecommendedCandidates',
//       path: '/recommended-candidates/:start_date/:end_date',
//       component: RecommendedCandidates,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'page_auto_interview',
//       path: '/auto-interview',
//       component: InterviewCounts,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'InterviewData',
//       path: '/auto-interview/:type/:start_date/:end_date',
//       component: InterviewData,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'InterviewDetails',
//       path: '/auto-interview/interview/:interviewId',
//       component: InterviewDetails,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'InterviewDetails',
//       path: '/auto-interview/interview-by-details/:userId/:offerId/:roundNumber',
//       component: InterviewDetails,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AiInterviewer',
//       path: '/ai-interviewer',
//       component: AiInterviewer,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'OnboardingChatDetails',
//       path: '/onboarding-chat/:userId',
//       component: OnboardingChatDetails,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidates',
//       path: '/job/potential_candidates/:offerId',
//       component: PotentialCandidates,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatess3',
//       path: '/job/potential_candidates_s3/:offerId',
//       component: PotentialCandidatess3,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatess3Rerank',
//       path: '/job/potential_candidates_s3_rerank/:offerId',
//       component: PotentialCandidatess3Rerank,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatesAi',
//       path: '/job/potential_candidates_ai_cutoff/:offerId',
//       component: PotentialCandidatesAi,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatesS3Weighted',
//       path: '/job/potential_candidates_s3_weighted/:offerId',
//       component: PotentialCandidatesS3Weighted,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatesvs3',
//       path: '/job/potential_candidates_s3_vector/:offerId',
//       component: PotentialCandidatesvs3,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatess8m',
//       path: '/job/potential_candidates_s8m/:offerId',
//       component: PotentialCandidatess8m,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatess8e',
//       path: '/job/potential_candidates_s8e/:offerId',
//       component: PotentialCandidatess8e,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PotentialCandidatess8EmailFullText',
//       path: '/job/potential_candidates_user8_full_text/:offerId',
//       component: PotentialCandidatess8EmailFullText,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Status0Search',
//       path: '/search-contact-db',
//       component: Status0Search,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ClickTracking',
//       path: '/tracking/:type',
//       component: ClickTracking,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ClickTrackingSource',
//       path: '/tracking/:type/:source/:startDate/:endDate',
//       component: ClickTrackingSource,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ClickTrackingByLink',
//       path: '/tracking/:type/:source/:link/:startDate/:endDate/users',
//       component: ClickTrackingByLink,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'WhatsAppCommunications',
//       path: '/communications/:type',
//       component: WhatsAppCommunications,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'GCoinsTransactionsGrouped',
//       path: '/gcoins/:type',
//       component: GCoinsTransactionsGrouped,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Status2SocialUsersView',
//       path: '/incomplete-social-user',
//       component: Status2SocialUsersView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'HirerSearchView',
//       path: '/search-hirers',
//       component: HirerSearchView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserDetailView',
//       path: '/account/:userid',
//       component: UserDetailView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'JobDetail',
//       path: '/offer/:offerId',
//       component: JobDetail,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserDetailView',
//       path: '/rohanchat/:userid',
//       component: UserDetailView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserDetailView',
//       path: '/sakshichat/:userid',
//       component: UserDetailView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'EveryTable',
//       path: '/data/:type/:period/:start_date/:end_date',
//       component: EveryTable,
//       layout: MainLayout,
//       exact: true,
//     },
  
//     {
//       routeId: 'Pipeline',
//       path: '/sales-pipeline',
//       component: Pipeline,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'OfferTypeSwitch',
//       path: '/offer-dashboard/:offerType/:status?',
//       component: OfferTypeSwitch,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RevenueBreakdown',
//       path: '/revenue-breakdown',
//       component: RevenueBreakdown,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Leads',
//       path: '/leads',
//       component: Leads,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddLead',
//       path: '/add-lead',
//       component: AddLead,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SalesDashboard',
//       path: '/sales-dashboard',
//       component: SalesDashboard,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SendNotification',
//       path: '/push-notifications',
//       component: SendNotification,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'WorkReports',
//       path: '/work-reports',
//       component: WorkReports,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ManagementReports',
//       path: '/management-reports',
//       component: ManagementReports,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Opportunities',
//       path: '/opportunities',
//       component: Opportunities,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'InventoryManagment',
//       path: '/inventory',
//       component: InventoryManagment,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'BlogDetails',
//       path: '/blog-details',
//       component: BlogDetails,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'LogsExplorer',
//       path: '/logs-explorer',
//       component: LogsExplorer,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'EditBlogs',
//       path: '/edit-blogs/:blog_id',
//       component: EditBlogs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'EditBlogs',
//       path: '/edit-blogs',
//       component: EditBlogs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DashboardView',
//       path: '/dashboard',
//       component: DashboardView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DashboardOld',
//       path: '/dashboard-old',
//       component: DashboardOld,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'KpiPage',
//       path: '/kpi',
//       component: KpiPage,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'GCoins',
//       path: '/gcoins',
//       component: GCoins,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DashboardUsa',
//       path: '/usa',
//       component: DashboardUsa,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ApplicationsSource',
//       path: '/applications-source',
//       component: ApplicationsSource,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DashboardUK',
//       path: '/uk',
//       component: DashboardUK,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'VolunteerData',
//       path: '/social-services',
//       component: VolunteerData,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'VolunteerDataList',
//       path: '/social-services/:type',
//       component: VolunteerDataList,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ReportGenerator',
//       path: '/report',
//       component: ReportGenerator,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SurveyMessage',
//       path: '/send_survey/:name/:userId',
//       component: SurveyMessage,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserEngagementView',
//       path: '/generations',
//       component: UserEngagementView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'TopSkillView',
//       path: '/top-skills',
//       component: TopSkillView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'TopDesignationView',
//       path: '/top-job-titles',
//       component: TopDesignationView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'socialmedia',
//       path: '/socialmedia',
//       component: socialmedia,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'socialmedialist',
//       path: '/social-users/:media/:start/:end',
//       component: socialmedialist,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SkillUsersListView',
//       path: '/skill-detail/:skill_id',
//       component: SkillUsersListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DesignationUsersList',
//       path: '/designation-detail/:skill_id',
//       component: DesignationUsersList,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserRequestView',
//       path: '/use-resquest',
//       component: UserRequestView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserMasterRequestView',
//       path: '/use-master-resquest',
//       component: UserMasterRequestView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'FullMemberList',
//       path: '/full-members/:start_date/:end_date',
//       component: FullMemberList,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'FeedbackRequestData',
//       path: '/feedback-requests/:start_date/:end_date',
//       component: FeedbackRequestData,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserRatingAnalyticsView',
//       path: '/dashboard/uan/:name/:user_id/:start_date/:end_date',
//       component: UserRatingAnalyticsView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SettingsView',
//       path: '/settings',
//       component: SettingsView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ExpansionSideNav',
//       path: '/ExpansionSideNav',
//       component: ExpansionSideNav,
//       layout: MainLayout,
//       exact: true,
//     },
  
//     // MASTERS (industry, degrees, etc.)
//     {
//       routeId: 'BusinessServiceType',
//       path: '/business-service-type',
//       component: BusinessServiceType,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DegreeLevels',
//       path: '/degree-levels',
//       component: DegreeLevels,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DegreeFields',
//       path: '/degree-fields',
//       component: DegreeFields,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Degrees',
//       path: '/degrees',
//       component: Degrees,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Designations',
//       path: '/designations',
//       component: Designations,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Fitnesses',
//       path: '/fitnesses',
//       component: Fitnesses,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'HabitFrequencies',
//       path: '/habit_frequencies',
//       component: HabitFrequencies,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'IndustryTypes',
//       path: '/industry_types',
//       component: IndustryTypes,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Interests',
//       path: '/intersets',
//       component: Interests,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PoliticalViews',
//       path: '/political_views',
//       component: PoliticalViews,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RelationshipStatus',
//       path: '/relationship_status',
//       component: RelationshipStatus,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Relationships',
//       path: '/relationships',
//       component: Relationships,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Religions',
//       path: '/religions',
//       component: Religions,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Purposes',
//       path: '/purposes',
//       component: Purposes,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Skills',
//       path: '/skills',
//       component: Skills,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'TzPoints',
//       path: '/tz-points',
//       component: TzPoints,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ZeroCountryCodeView',
//       path: '/invalid-country-codes',
//       component: ZeroCountryCodeView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'NewJoinRequestListView',
//       path: '/signups',
//       component: NewJoinRequestListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ActiveUsersListView',
//       path: '/active-users',
//       component: ActiveUsersListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ChatsView',
//       path: '/chats/:chatType/:userIds',
//       component: ChatsView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'VerifiedUsersListView',
//       path: '/verified-users/:start_date/:end_date',
//       component: VerifiedUsersListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RedirectJobClick',
//       path: '/redirect_jobs_clicks/unique/:offerId',
//       component: RedirectJobClick,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RedirectJobClick',
//       path: '/redirect_jobs_clicks/all/:offerId',
//       component: RedirectJobClick,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ReportedJobs',
//       path: '/reports/jobs/:offerId',
//       component: ReportedJobs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'HireView',
//       path: '/hire/:type/:start_date/:end_date',
//       component: HireView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'JobPostingsView',
//       path: '/jobs/:type/:start_date/:end_date',
//       component: JobPostingsView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'JobsApplicationView',
//       path: '/job-application/:type/:start/:end',
//       component: JobsApplicationView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'candidateFeedback',
//       path: '/job-application/candidate/feedBack/:offerId/:userId',
//       component: candidateFeedback,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'LinkClicksView',
//       path: '/linkclicks/:start_date/:end_date',
//       component: LinkClicksView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'DownloadCountView',
//       path: '/downloadcount/:start_date/:end_date',
//       component: DownloadCountView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'InactiveUsersListView',
//       path: '/inactive-users',
//       component: InactiveUsersListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UserCountByCityView',
//       path: '/user-count-by-city',
//       component: UserCountByCityView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PromoCodes',
//       path: '/promo-codes',
//       component: PromoCodes,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddPromoCode',
//       path: '/add-promo-code',
//       component: AddPromoCode,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'MarketingCampaigns',
//       path: '/marketing-campaigns',
//       component: MarketingCampaigns,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'CreatePaymentLink',
//       path: '/create-payment-link',
//       component: CreatePaymentLink,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ManualPaymentGateway',
//       path: '/manual-payment-gateway',
//       component: ManualPaymentGateway,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddCampaign',
//       path: '/add-marketing-campaign',
//       component: AddCampaign,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'UsersByCampaign',
//       path: '/marketing-campaigns-user',
//       component: UsersByCampaign,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RedirectLinkData',
//       path: '/marketing-campaigns-clicks',
//       component: RedirectLinkData,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AuxCardsListView',
//       path: '/aux-cards',
//       component: AuxCardsListView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsUnderReview',
//       path: '/jobs-under-review/',
//       component: ViewJobsUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsOnHold',
//       path: '/jobs-on-hold/',
//       component: ViewJobsOnHold,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PitchUnderReview',
//       path: '/pitch-under-review/',
//       component: PitchUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'IntroUnderReview',
//       path: '/intro-under-review/',
//       component: IntroUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'PitchActive',
//       path: '/pitch-all/',
//       component: PitchActive,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewSocialExpUnderReview',
//       path: '/social-experience-under-review/',
//       component: ViewSocialExpUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewSocialActiveExp',
//       path: '/active-social-experience/',
//       component: ViewSocialActiveExp,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddJob',
//       path: '/add-job/',
//       component: AddJob,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsUnderReview',
//       path: '/jobs-under-review/:start_date/:end_date',
//       component: ViewJobsUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsOnHold',
//       path: '/jobs-on-hold/:start_date/:end_date',
//       component: ViewJobsOnHold,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllActiveJobs',
//       path: '/active-jobs',
//       component: ViewAllActiveJobs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllJobsByUserAndType',
//       path: '/jobs-by-user/:userId/:type',
//       component: ViewAllJobsByUserAndType,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllClosedJobs',
//       path: '/closed-jobs',
//       component: ViewAllClosedJobs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllAdmins',
//       path: '/manage-gs-admins',
//       component: ViewAllAdmins,
//       layout: MainLayout,
//       exact: true,
//       public: true,
//     },
//     {
//       routeId: 'ManageRoutes',
//       path: '/manage-gs-admins-routes',
//       component: ManageRoutes,
//       layout: MainLayout,
//       exact: true,
//       public: true
//     },
  
//     // SALES-LEVEL ACCESS
//     {
//       routeId: 'DashboardView',
//       path: '/dashboard',
//       component: DashboardView,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Opportunities',
//       path: '/opportunities',
//       component: Opportunities,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Pipeline',
//       path: '/sales-pipeline',
//       component: Pipeline,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'RevenueBreakdown',
//       path: '/revenue-breakdown',
//       component: RevenueBreakdown,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Leads',
//       path: '/leads',
//       component: Leads,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddLead',
//       path: '/add-lead',
//       component: AddLead,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SalesDashboard',
//       path: '/sales-dashboard',
//       component: SalesDashboard,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'SendNotification',
//       path: '/push-notifications',
//       component: SendNotification,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'WorkReports',
//       path: '/work-reports',
//       component: WorkReports,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ManagementReports',
//       path: '/management-reports',
//       component: ManagementReports,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'AddJob',
//       path: '/add-job/',
//       component: AddJob,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsUnderReview',
//       path: '/jobs-under-review/',
//       component: ViewJobsUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsUnderReview',
//       path: '/jobs-under-review/:start_date/:end_date',
//       component: ViewJobsUnderReview,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewJobsOnHold',
//       path: '/jobs-on-hold/:start_date/:end_date',
//       component: ViewJobsOnHold,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllActiveJobs',
//       path: '/active-jobs',
//       component: ViewAllActiveJobs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllJobsByUserAndType',
//       path: '/jobs-by-user/:userId/:type',
//       component: ViewAllJobsByUserAndType,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'OfferTypeSwitch',
//       path: '/offer-dashboard/:offerType/:status?',
//       component: OfferTypeSwitch,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'ViewAllClosedJobs',
//       path: '/closed-jobs',
//       component: ViewAllClosedJobs,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'CreatePaymentLink',
//       path: '/create-payment-link',
//       component: CreatePaymentLink,
//       layout: MainLayout,
//       exact: true,
//     },
//     {
//       routeId: 'Notfound',
//       path: '*',
//       component: NotFoundView,
//       layout: MinimalLayout,
//       public: true,
//     }
// ];

export default routes;
  