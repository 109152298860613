import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'react-bootstrap';
import Tabs from './constants/Tabs';
import { sendHttpCall } from 'shareService/httpService';
import Loader from 'components/Loader';
import NoInterviewAvailable from './components/NoInterviewAvailable';
import styles from './styles.module.scss';
import moment from 'moment';

// Function to handle opening user page
const openUserPage = (userId) => {
    window.open(`/dashboard#/account/${userId}`, '_blank');
};

// CopySVG Component
const CopySVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33337 7.77838C1.33337 7.17723 1.57218 6.60069 1.99727 6.17561C2.42235 5.75053 2.99888 5.51172 3.60004 5.51172H5.07004V6.84505H3.60004C3.35251 6.84505 3.11511 6.94339 2.94007 7.11842C2.76504 7.29345 2.66671 7.53085 2.66671 7.77838V12.5784C2.66671 12.8259 2.76504 13.0633 2.94007 13.2384C3.11511 13.4134 3.35251 13.5117 3.60004 13.5117H8.40004C8.64758 13.5117 8.88497 13.4134 9.06001 13.2384C9.23504 13.0633 9.33337 12.8259 9.33337 12.5784V11.3784H10.6667V12.5784C10.6667 13.1795 10.4279 13.7561 10.0028 14.1812C9.57773 14.6062 9.0012 14.8451 8.40004 14.8451H3.60004C2.99888 14.8451 2.42235 14.6062 1.99727 14.1812C1.57218 13.7561 1.33337 13.1795 1.33337 12.5784V7.77838Z"
            fill="#2369AC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 2H12C12.7072 2 13.3855 2.28095 13.8856 2.78105C14.3857 3.28115 14.6667 3.95942 14.6667 4.66667V10C14.6667 10.7072 14.3857 11.3855 13.8856 11.8856C13.3855 12.3857 12.7072 12.6667 12 12.6667H6.66667C5.95942 12.6667 5.28115 12.3857 4.78105 11.8856C4.28095 11.3855 4 10.7072 4 10V4.66667C4 3.95942 4.28095 3.28115 4.78105 2.78105C5.28115 2.28095 5.95942 2 6.66667 2ZM6.66667 3.33333C6.31304 3.33333 5.97391 3.47381 5.72386 3.72386C5.47381 3.97391 5.33333 4.31304 5.33333 4.66667V10C5.33333 10.3536 5.47381 10.6928 5.72386 10.9428C5.97391 11.1929 6.31304 11.3333 6.66667 11.3333H12C12.3536 11.3333 12.6928 11.1929 12.9428 10.9428C13.1929 10.6928 13.3333 10.3536 13.3333 10V4.66667C13.3333 4.31304 13.1929 3.97391 12.9428 3.72386C12.6928 3.47381 12.3536 3.33333 12 3.33333H6.66667Z"
            fill="#2369AC"
        />
    </svg>
);

const AiInterviewer = () => {
    const [activeTab, setActiveTab] = useState(Tabs.ACTIVE);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        totalCount: 0,
        totalPages: 1,
        currentPage: 1,
        limit: 10,
    });

    // Function to fetch interviews based on type and page
    const fetchInterviews = async (page = 1, type = activeTab) => {
        setLoading(true);
        try {
            const response = await sendHttpCall(
                'GET',
                `interview/list_by_type?limit=${pagination.limit}&page=${page}&type=${type}`,
                null,
                null
            );

            if (response && response.data) {
                setData(response.data || []);
                setPagination(response.pagination || {
                    totalCount: 0,
                    totalPages: 1,
                    currentPage: 1,
                    limit: 10,
                });
            } else {
                setData([]);
                setPagination({
                    totalCount: 0,
                    totalPages: 1,
                    currentPage: 1,
                    limit: 10,
                });
            }
        } catch (error) {
            console.error('Error fetching interviews:', error);
            setData([]);
            setPagination({
                totalCount: 0,
                totalPages: 1,
                currentPage: 1,
                limit: 10,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInterviews(1, activeTab);
    }, [activeTab]);

    // Handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPagination((prev) => ({ ...prev, currentPage: 1 }));
    };

    // Handle page change from pagination component
    const handlePageChange = (event, value) => {
        fetchInterviews(value, activeTab);
    };

    // Handle "Go to page" functionality
    const handleGoToPage = () => {
        const pageNumber = parseInt(pagination.currentPage, 10);
        if (pageNumber >= 1 && pageNumber <= pagination.totalPages) {
            fetchInterviews(pageNumber, activeTab);
        } else {
            alert('Please enter a valid page number');
        }
    };

    // Function to format posted date
    const getLocaleTime = (time) => {
        return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss A');
    };

    // Function to render skills as chips
    const renderSkills = (skills) =>
        skills.map((skill, index) => (
            <span className={styles.skill} key={index}>
                {skill}
            </span>
        ));

    // Function to render addons as chips
    const renderAddons = (addons) =>
        addons.map((addon) => (
            <span className={styles.addon} key={addon.type_selection_id || addon.interview_type_id}>
                {addon.display_name || addon.type_name}
            </span>
        ));

    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.aiInterviewDashboardMainDiv}>
                    <div className={styles.mainContentDivHeader}>
                        <div className={styles.TopHeader}>
                            <h3>AI Interviewer</h3>
                            <div className={styles.TabSwitch}>
                                {Object.values(Tabs).map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => handleTabChange(tab)}
                                        className={`${activeTab === tab ? styles.active : ''}`}
                                    >
                                        {tab.replace('_', ' ')}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : data.length > 0 ? (
                        <>
                            <div className={styles.cardDiv}>
                                {data.map((interview) => (
                                    <div className={styles.card} key={interview.offerId}>
                                        <div className={styles.title}>{interview.title}</div>
                                        <div className={styles.createdOn}>
                                            Posted on: {getLocaleTime(interview.postedAt)}
                                        </div>
                                        <div
                                            className={styles.hirerInfo}
                                            onClick={() => openUserPage(interview.hirerUserId)}
                                        >
                                            Created by: <span className={styles.hirerName}>{interview.hirerName}</span> at {interview.organisation}
                                        </div>
                                        <div className={styles.metrics}>
                                            <div className={styles.metric}>
                                                <span className={styles.metricLabel}>Invited via GS:</span>
                                                <span className={styles.metricValue}>{interview.invitedCandidates}</span>
                                            </div>
                                            <div className={styles.metric}>
                                                <span className={styles.metricLabel}>Invites Bought:</span>
                                                <span className={styles.metricValue}>{interview.totalInvitesBought}</span>
                                            </div>
                                            {interview.assessmentAnalytics && (
                                                <>
                                                    <div className={styles.metric}>
                                                        <span className={styles.metricLabel}>Interviewed:</span>
                                                        <span className={styles.metricValue}>{interview.assessmentAnalytics.interviewedCount}</span>
                                                    </div>
                                                    <div className={styles.metric}>
                                                        <span className={styles.metricLabel}>Qualified:</span>
                                                        <span className={styles.metricValue}>{interview.assessmentAnalytics.qualifiedCount}</span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.skills}>
                                            {renderSkills(interview.skills)}
                                        </div>
                                        <div className={styles.addons}>
                                            Add-ons:
                                            {renderAddons(interview.addons)}
                                        </div>
                                        <div className={styles.ButtonGrp}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => navigator.clipboard.writeText(interview.interviewLink)}
                                                className={styles.copyButton}
                                            >
                                                <CopySVG /> Copy Interview Link
                                            </Button>
                                            <a
                                                href={`/dashboard#/offer/${interview.offerId}`}
                                                className={styles.viewAssessmentsButton}
                                            >
                                                View Assessments
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.paginationContainer}>
                                <div className={styles.paginationCount}>
                                    Total Count: {pagination.totalCount}
                                </div>
                                <Pagination
                                    count={pagination.totalPages}
                                    page={pagination.currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                                <Box display="flex" alignItems="center" gap={1}>
                                    <TextField
                                        size="small"
                                        label="Go to page"
                                        variant="outlined"
                                        value={pagination.currentPage}
                                        onChange={(e) =>
                                            setPagination((prev) => ({
                                                ...prev,
                                                currentPage: e.target.value,
                                            }))
                                        }
                                        inputProps={{
                                            type: 'number',
                                            min: 1,
                                            max: pagination.totalPages,
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleGoToPage();
                                        }}
                                        className={styles.goToPageInput}
                                    />
                                    <Button
                                        variant="contained"
                                        onClick={handleGoToPage}
                                        className={styles.goToPageButton}
                                    >
                                        Go
                                    </Button>
                                </Box>
                            </div>
                        </>
                    ) : (
                        <NoInterviewAvailable />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AiInterviewer;
