import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {
        loaded: false,
        allAdmins: [],
        allInActiveAdmins: []
    }
}

const adminStaticConfigReducer = createSlice({
    name: "adminStaticConfig",
    initialState: initialState,
    reducers: {
        loadAdminStaticData: (state, action) => {
            console.log("Action CAllled");
            console.log(action);
            state.data = {
                ...state.data,
                ...action.payload,
                loaded: true
            }
        }
    }
});

export const { loadAdminStaticData } = adminStaticConfigReducer.actions;
export default adminStaticConfigReducer.reducer;