import React, { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardContent , Button } from '@material-ui/core';
import moment from 'moment';
import { sendHttpCall } from 'shareService/httpService';
import DateRangePicker from 'components/DatePickerDialog/DatePickerDialog';
import RefreshIcon from '@material-ui/icons/Refresh';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  card: {
    cursor: 'pointer',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', 
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.25)',
    },
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins, sans-serif', 
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  dataText: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 700,
    fontSize: '2rem',
    color: '#333',
    textAlign: 'center',
  },
  cardContent: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  shimmer: {
    borderRadius: '12px', 
    animation: '$shimmerEffect 1.5s ease-in-out infinite',
    background: 'linear-gradient(90deg, rgba(240, 240, 240, 0.3) 25%, rgba(245, 245, 245, 1) 50%, rgba(240, 240, 240, 0.3) 75%)',
    backgroundSize: '200% 100%',
  },
  '@keyframes shimmerEffect': {
    '0%': { backgroundPosition: '-200% 0' },
    '100%': { backgroundPosition: '200% 0' },
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [currentTypeIndex, setCurrentTypeIndex] = useState(3);
  const [initialFetched, setInitialFetched] = useState(false);

  const [disableScrollFetch, setDisableScrollFetch] = useState(false);

  const handleDateChange = (newDateRange) => {
    setSelectedStartDate(newDateRange.startDate);
    setSelectedEndDate(newDateRange.endDate);
    setData({});
    setCurrentTypeIndex(3);
    setInitialFetched(false);
    setDisableScrollFetch(false);
  };

  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('period') ? localStorage.getItem('period') : 'Today'
  );

  const types = [
    'new_user_signup',
    'status_2_users',
    'status_2_hirer',
    'social_data',
    'status_3_hirer',
    'feedback_data',
    'hire_now_data',
    'job_post_data',
    'job_applications_data',
    'auto_interview_data',
    'blood_donor_requests',
    'premium_members_data'
  ];

  const initialCategoryLoading = types.reduce((acc, type) => {
    acc[type] = false;
    return acc;
  }, {});

  const [categoryLoading, setCategoryLoading] = useState(initialCategoryLoading);

  const fetchCompleteData = async (typeIndex) => {
    if (loading) return;
  
    const type = types[typeIndex];

    if (data[type]) {
      return;
    }
  
    // Turn on shimmer only for this category
    setCategoryLoading((prev) => ({
      ...prev,
      [type]: true,
    }));
  
    try {
      const additionalQuery = `?start_date=${selectedStartDate}&end_date=${selectedEndDate}&type=${type}`;
      const response = await sendHttpCall(
        'GET',
        'dashboard' + additionalQuery,
        null,
        props
      );
  
      if (response && response.status) {
        setData((prevData) => ({
          ...prevData,
          [type]: response.data,
        }));
      }
  
      // Once it is fetched for the first time, mark it:
      setInitialFetched(true);
    } catch (error) {
      console.error(error);
    } finally {
      // Turn off shimmer for this category
      setCategoryLoading((prev) => ({
        ...prev,
        [type]: false,
      }));
    }
  };
  


  const refreshAll = async () => {
    try {
      let allLoading = {};
      types.forEach((cat) => {
        allLoading[cat] = true;
      });
      setCategoryLoading(allLoading);

      setDisableScrollFetch(true);

      let newData = { ...data };

      const calls = types.map(async (type) => {
        const additionalQuery = `?start_date=${selectedStartDate}&end_date=${selectedEndDate}&type=${type}`;
        const response = await sendHttpCall('GET', 'dashboard' + additionalQuery, null, props);

        if (response && response.status) {
          newData[type] = response.data;
        }
      });

      await Promise.all(calls);

      setData(newData);
      setInitialFetched(true);
    } catch (error) {
      console.error('Error in refreshAll:', error);
    } finally {
      let allNotLoading = {};
      types.forEach((cat) => {
        allNotLoading[cat] = false;
      });
      setCategoryLoading(allNotLoading);
    }
  };

  const refreshCategory = async (cat) => {
    setCategoryLoading((prev) => ({
      ...prev,
      [cat]: true
    }));

    try {
      const additionalQuery = `?start_date=${selectedStartDate}&end_date=${selectedEndDate}&type=${cat}`;
      const response = await sendHttpCall('GET', 'dashboard' + additionalQuery, null, props);

      if (response && response.status) {
        setData((prev) => ({
          ...prev,
          [cat]: response.data
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCategoryLoading((prev) => ({
        ...prev,
        [cat]: false
      }));
    }
  };

  useEffect(() => {
    if (!initialFetched) {
      fetchCompleteData(0);
      fetchCompleteData(1);
      fetchCompleteData(2);
      setInitialFetched(true);
    }
  }, [initialFetched]);
  const handleScroll = () => {
    if (
      !disableScrollFetch &&
      !loading &&
      currentTypeIndex < types.length &&
      initialFetched
    ) {
      // Indicate we are about to fetch so we don't fetch again on the next tiny scroll
      setLoading(true);
  
      fetchCompleteData(currentTypeIndex).then(() => {
        setCurrentTypeIndex((prevIndex) => prevIndex + 1);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, initialFetched]);

  const newUserSignUpData = [
    {
      heading: 'ACTIVE MEMBERS',
      data: data.new_user_signup?.status3,
      link: `/data/active_member/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'STATUS 4 USERS',
      data: data.new_user_signup?.status4,
      link: `/data/status4/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'SOCIAL USERS',
      data: data.new_user_signup?.activeSocialProfile,
      link: `/search-social-user`
    },
    {
      heading: 'STATUS 1 USERS',
      data: data.new_user_signup?.status1,
      link: `/data/status1/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'BLOCKED USERS',
      data: data.new_user_signup?.blockedUsers,
      link: `/data/blocked/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'DEACTIVATED USERS',
      data: data.new_user_signup?.deactivatedUsers,
      link: `/data/deactivated/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'RESUME DOWNLOADED',
      data: data.new_user_signup?.resumeDownloaded,
      link: `/data/resume-downloaded/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    // {
    //   heading: 'APP FEEDBACKS',
    //   data: data.new_user_signup.appFeedback,
    //   link: `/data/app-feedbacks/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    // },
    {
      heading: 'AUDIO UPLOADED',
      data: data.new_user_signup?.audioUploaded,
      link: `/data/resume-audio-uploaded/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    // {
    //   heading: 'AUTO RECRUITER DEMO',
    //   data: data.new_user_signup.demoInterviews,
    //   link: `/auto-interview/demo/${selectedStartDate}/${selectedEndDate}`
    // },
    {
      heading: 'CONTACT REQUEST FOR DEMO',
      data: data.new_user_signup?.contactRequestCount,
      link: `/data/contactRequestForDemo/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: "HIRER DEMO INTERVIEW",
      data: data.new_user_signup?.DemoInterviews,
      link: `/data/demo_interview/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: "DEMO INTERVIEW FEEDBACKS",
      data: data.new_user_signup?.interviewFeedback,
      link: `/data/demo_interview_feedback/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const status2UsersData = [
    {
      heading: 'ALL STATUS 2 USERS',
      data: data.status_2_users?.status2,
      link: `/data/status2/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRER STATUS 2',
      data: data.status_2_users?.hirerStatus2,
      link: `/data/status2Hirer/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'JOB SEEKER STATUS 2',
      data: data.status_2_users?.memberStatus2,
      link: `/data/status2Member/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'SOCIAL STATUS 2',
      data: data.status_2_users?.onboardingStarted,
      link: `/incomplete-social-user`
    }
  ];

  const status2HirersData = [
    {
      heading: 'TOTAL HIRER',
      data: data.status_2_hirer?.hirerStatus2,
      link: `/data/status2Hirer/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'VERIFIED HIRER',
      data: data.status_2_hirer?.verifiedHirerCount,
      link: `/data/status2HirerVerified/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'REJECTED HIRER',
      data: data.status_2_hirer?.rejectedHirerCount,
      link: `/data/status2HirerRejected/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'UNVERIFIED HIRER',
      data: data.status_2_hirer?.unVerifiedHirerCount,
      link: `/data/status2HirerUnVerified/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const socialData = [
    {
      heading: 'CONNECTION REQUESTS',
      data: data.social_data?.connectionsSent,
      link: `/data/social_connect_requests/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'CONNECTION ACCEPTED',
      data: data.social_data?.connectAccepted,
      link: `/data/social_connect_requests_accepted/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'CONNECTION REJECTED',
      data: data.social_data?.connectRejected,
      link: `/data/social_connect_requests_rejected/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const status3HirersData = [
    {
      heading: 'TOTAL HIRERS',
      data: data.status_3_hirer?.totalHirer,
      link: `/data/active_hirers/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'VERIFIED HIRERS',
      data: data.status_3_hirer?.verifiedHirer3Count,
      link: `/data/status3hirersVerified/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'UNVERIFIED HIRERS',
      data: data.status_3_hirer?.unVerifiedHirer3Count,
      link: `/data/status3hirersUnverified/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'REJECTED HIRERS',
      data: data.status_3_hirer?.rejectedHirer3Count,
      link: `/data/status3hirersRejected/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRERS WHO POSTED JOBS',
      data: data.status_3_hirer?.JobPostshirers3Count,
      link: `/data/status3hirersJobPosts/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRER WHO DOWNLOADED RESUMES',
      data: data.status_3_hirer?.hirerResumeDownload,
      link: `/data/hirer-resume-downloaded/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'UNASSIGNED HIRERS',
      data: data.status_3_hirer?.unassignedHirersStatus3,
      link: `/data/hirer-unassigned-sales-stage/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const feedbackData = [
    {
      heading: 'TOTAL FEEDBACK',
      data: data.feedback_data?.totalFeedbacks,
      link: `/data/feedback/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'TEMP MEMBERS',
      data: data.feedback_data?.tempMembers,
      link: ``
    },
    {
      heading: 'TOTAL FEEDBACK REQUESTS',
      data: data.feedback_data?.feedbackRequests,
      link: `/data/feedback-requests/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'TOTAL FEEDBACK CONVERSIONS',
      data: data.feedback_data?.feedbackConversions,
      link: `/data/feedback_conversions/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const hireNowData = [
    {
      heading: 'NUMBER OF SEARCH',
      data: data.hire_now_data?.totalSearches,
      link: ``
    },
    {
      heading: 'HIRE NOW REQUESTS',
      data: data.hire_now_data?.hireRequest,
      link: `/data/hire-request/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRE NOW CLICKS',
      data: data.hire_now_data?.totalHires,
      link: `/hire/click/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRE NOW ACCEPTED',
      data: data.hire_now_data?.hireNowAccepted,
      link: `/hire/accepted/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRE NOW REJECTED',
      data: data.hire_now_data?.hireNowRejected,
      link: `/hire/rejected/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'HIRE NOW PENDING',
      data: data.hire_now_data?.hireNowPending,
      link: `/hire/requested/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'CONNECT CLICK COUNT',
      data: data.hire_now_data?.connectClickCount,
      link: ``
    }
  ];

  const jobPostsData = [
    {
      heading: 'TOTAL JOB POSTS',
      data: data.job_post_data?.totalPosted,
      link: `/jobs/click/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'VERIFIED JOB POSTS',
      data: data.job_post_data?.approvedJobs,
      link: `/data/verifiedJobs/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'UNDER REVIEW JOB POSTS',
      data: data.job_post_data?.underReview,
      link: `/jobs-under-review/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'REJECTED JOB POSTS',
      data: data.job_post_data?.rejectedJobs,
      link: `/data/rejectedJobs/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'CLOSED JOB POSTS',
      data: data.job_post_data?.closedJobs,
      link: `/data/closedJobs/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'JOBS ON HOLD',
      data: data.job_post_data?.jobsOnHold,
      link: `/jobs-on-hold/`
    },
    {
      heading: 'JOB POST REPORTS',
      data: data.job_post_data?.reportedJobs,
      link: `/data/reportedJobs/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const jobApplicationsData = [
    {
      heading: 'JOB APPLICATIONS',
      data: data.job_applications_data?.totalApplications,
      link: `/job-application/click/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'REDIRECT JOB APPLY CLICKS',
      data: data.job_applications_data?.redirectJobClicks,
      link: `/data/redirect_job_apply_clicks/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'JOB APPLICATIONS ACCEPTED',
      data: data.job_applications_data?.applicationsAccepted,
      link: `/job-application/accepted/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'JOB APPLICATIONS REJECTED',
      data: data.job_applications_data?.applicationsRejected,
      link: `/job-application/rejected/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'JOB APPLICATIONS PENDING',
      data: data.job_applications_data?.pendingApplications,
      link: `/job-application/applied/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'PROFILES VIEWED',
      data: data.job_applications_data?.profileViewed,
      link: ``
    }
  ];

  const autoInterviewData = [
    {
      heading: 'INTERVIEWS OFFERED',
      data: data.auto_interview_data?.interviewsOffered,
      link: `/recommended-candidates/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'STUCK ON INSTRUCTION PAGE',
      data: data.auto_interview_data?.stuckOnInstructionsInterviews,
      link: `/stuck-interviews/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'INTERVIEWS STARTED',
      data: data.auto_interview_data?.startedInterviews,
      link: `/started-interviews/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'INTERVIEWS FINISHED',
      data: data.auto_interview_data?.finishedInterviews,
      link: `/finished-interviews/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'ROHAN BOT INTERACTIONS',
      data: data.auto_interview_data?.RohanInteractionscount,
      link: `/rohan-chatbot-logs/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'SAKSHI BOT INTERACTIONS',
      data: data.auto_interview_data?.SakshiInteractionscount,
      link: `/sakshi-chatbot-logs/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'INTERVIEW FEEDBACKS',
      data: data.auto_interview_data?.interviewFeedback,
      link: `/data/interview_feedback/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const bloodDonorRequestsData = [
    {
      heading: 'BLOOD DONOR REQUESTS',
      data: data.blood_donor_requests?.bloodDonorRequests,
      link: `/data/blood-donor-requests/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'BLOOD DONOR ADMIN REQUESTS',
      data: data.blood_donor_requests?.bloodDonorRequestSentToAdmin,
      link: `/data/blood-donor-requests-admin/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'BLOOD DONOR REQUESTS ACCEPTED',
      data: data.blood_donor_requests?.bloodDonorRequestAccepted,
      link: `/data/blood-donor-requests-accepted/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'BLOOD DONOR REQUESTS REJECTED',
      data: data.blood_donor_requests?.bloodDonorRequestRejected,
      link: `/data/blood-donor-requests-rejected/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    }
  ];

  const premiumMembersData = [
    {
      heading: 'TOTAL TRANSACTIONS',
      data: data.premium_members_data?.totalTransactions,
      link: `/data/transactions/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'FAILED TRANSACTIONS',
      data: data.premium_members_data?.failedTransactions,
      link: `/data/failed-transactions/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'CREATED ORDERS',
      data: data.premium_members_data?.createdOrders,
      link: `/data/created-orders/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'TOTAL REVENUE',
      data: data.premium_members_data?.totalRevenue,
      link: `/data/transactions/${selectedPeriod}/${selectedStartDate}/${selectedEndDate}`
    },
    {
      heading: 'TOTAL JOB SEEKER PREMIUM USERS',
      data: data.premium_members_data?.totalJobSeekerPremiumUsers,
      link: ``
    }
  ];

  return (
    <div className={classes.root}>
      <Grid spacing={2} style={{ marginBottom: '16px' }}>
        <Grid item style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <DateRangePicker onDateChange={handleDateChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={refreshAll}
          >
            REFRESH ALL
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
            <Typography variant="h5" gutterBottom>
            NEW USER SIGN UPS
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('new_user_signup')}/>
          </Grid>
          <Grid container spacing={2}>
            {newUserSignUpData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                props={props}
                // loading={loading}
                isCategoryLoading={categoryLoading['new_user_signup']}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            STATUS 2 USERS
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('status_2_users')}/>
          </Grid>
          <Grid container spacing={2}>
            {status2UsersData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['status_2_users']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            STATUS 2 HIRERS
          </Typography>
             <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('status_2_hirer')}/>
          </Grid>
          <Grid container spacing={2}>
            {status2HirersData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['status_2_hirer']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            SOCIAL DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('social_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {socialData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['social_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            STATUS 3 HIRERS DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('status_3_hirer')}/>
          </Grid>
          <Grid container spacing={2}>
            {status3HirersData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['status_3_hirer']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            FEEDBACK DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('feedback_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {feedbackData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['feedback_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            HIRE NOW DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('hire_now_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {hireNowData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['hire_now_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            JOB POSTS DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('job_post_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {jobPostsData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['job_post_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            JOB APPLICATIONS DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('job_applications_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {jobApplicationsData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['job_applications_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            AUTO INTERVIEW DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('auto_interview_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {autoInterviewData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['auto_interview_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            BLOOD DONOR REQUESTS
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('blood_donor_requests')}/>
          </Grid>
          <Grid container spacing={2}>
            {bloodDonorRequestsData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['blood_donor_requests']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap:'10px', marginBottom: '8px' }}>
          <Typography variant="h5" gutterBottom>
            PREMIUM MEMBERS DATA
          </Typography>
            <RefreshIcon style={{marginTop: '-3px', cursor: 'pointer'}} onClick={() => refreshCategory('premium_members_data')}/>
          </Grid>
          <Grid container spacing={2}>
            {premiumMembersData.map((item, index) => (
              <DashboardGridItem
                key={index}
                heading={item.heading}
                data={item.data}
                link={item.link}
                selectedPeriod={selectedPeriod}
                // loading={loading}
                isCategoryLoading={categoryLoading['premium_members_data']}
                props={props}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const DashboardGridItem = ({
  heading,
  data,
  link,
  selectedPeriod,
  props,
  loading,
  isCategoryLoading
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (link) {
      localStorage.setItem('period', selectedPeriod);
      localStorage.setItem('selectedPeriodUserSearch', selectedPeriod);
      props.history.push(link);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        style={{ cursor: link ? 'pointer' : 'default' }}
        onClick={link ? handleClick : null}>
        <CardContent>
          <Typography
            className={classes.cardTitle}
            variant="subtitle1"
            color="textSecondary">
            {heading}
          </Typography>
          <div style={{height: '24px'}}>
          {(isCategoryLoading || loading) ? (
            <Skeleton variant="text" height={24} />
          ) : (
            <Typography variant="h4">{data}</Typography>
          )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Dashboard;
