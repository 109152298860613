import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'react-bootstrap';
import { sendHttpCall } from 'shareService/httpService';
import Loader from 'components/Loader';
import styles from './styles.module.scss';
import moment from 'moment';

const AssessmentDetails = ({ offerId, roundNumber = 1 }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalCount: 0,
    totalPages: 1,
    currentPage: 1,
    limit: 10,
  });

  const fetchAssessmentDetails = async (page = 1) => {
    setLoading(true);
    try {
      const response = await sendHttpCall(
        'GET',
        `interview/assessment?offerId=${offerId}&roundNumber=${roundNumber}&limit=${pagination.limit}&page=${page}`,
        null,
        null
      );

      if (response && response.data) {
        setData(response.data || []);
        setPagination({
          totalCount: response.totalCount || 0,
          totalPages: response.totalPages || 1,
          currentPage: page,
          limit: pagination.limit,
        });
      } else {
        setData([]);
        setPagination({
          totalCount: 0,
          totalPages: 1,
          currentPage: page,
          limit: pagination.limit,
        });
      }
    } catch (error) {
      console.error('Error fetching assessment details:', error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offerId) fetchAssessmentDetails(1);
  }, [offerId]);

  const handlePageChange = (event, value) => {
    fetchAssessmentDetails(value);
  };

  const openUserPage = (userId) => {
    window.open(`/dashboard#/account/${userId}`, '_blank');
  };

  const openReportPage = (offerId, userId) => {
    window.open(`/#/auto-interview/interview-by-details/${userId}/${offerId}/1`, '_blank');
  };

  return (
    <div className={styles.assessmentContainer}>
      <h2 className={styles.title}>Assessment Details</h2>
      {loading ? (
        <Loader />
      ) : data.length > 0 ? (
        <>
          <table className={styles.assessmentTable}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Score</th>
                <th>Status</th>
                <th>Cheating Detected</th>
                <th>Time Taken</th>
                <th>Started At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.userId}>
                  <td>
                    <span
                      className={styles.userLink}
                      onClick={() => openUserPage(item.userId)}
                    >
                      {item.name}
                    </span>
                  </td>
                  <td>{item.overallScore || 'N/A'}</td>
                  <td>{item.interviewStatus}</td>
                  <td>{item.cheatingDetected ? 'Yes' : 'No'}</td>
                  <td>{item.timeTaken || 'N/A'}</td>
                  <td>{moment(item.startedAt).format('DD/MM/YYYY h:mm:ss A')}</td>
                  <td>
                    {item.interviewStatus === 'COMPLETED' && (
                      <Button
                        variant="outline-primary"
                        className={styles.viewReportButton}
                        onClick={() => openReportPage(offerId, item.userId)}
                      >
                        View Report
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={handlePageChange}
              color="primary"
            />
            <Box display="flex" alignItems="center" gap={2}>
              Total Records: {pagination.totalCount}
            </Box>
          </div>
        </>
      ) : (
        <p className={styles.noData}>No assessment details available.</p>
      )}
    </div>
  );
};

export default AssessmentDetails;
