import React, { useState, useEffect } from 'react';
import styles from './OfferDetail.module.scss';
import { sendHttpCall } from 'shareService/httpService';
import Loader from 'components/Loader';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import AssessmentDetails from 'views/AiInterviewer/components/AssessmentDetails';

const OfferDetail = (props) => {
  const offerId = props.match.params.offerId;
  const [offerData, setOfferData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentRemark, setCurrentRemark] = useState('');
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [noFollowUpNeeded, setNoFollowUpNeeded] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    fetchOfferDetails(offerId);
  }, [offerId]);

  const fetchOfferDetails = (offerId) => {
    setLoading(true);
    let url = `/jobs/preview?offerId=${offerId}`;
    sendHttpCall('GET', url, null, {}).then((response) => {
      if (response) {
        setOfferData(response);
        setLoading(false);
      }
    });
  };
  const getLocaleTime = (time) => {
    if (time) return moment(new Date(time)).format('DD/MM/YYYY h:mm A');
  };

  const addRemarkToOffer = () => {
    const now = new Date();
    const minFollowUpTime = new Date(now.getTime() + 15 * 60000);

    if (!currentRemark.trim()) {
      showToast('Remark should not be empty.', 'error');
      return;
    }

    if (
      !noFollowUpNeeded &&
      (!followUpDate || followUpDate <= minFollowUpTime)
    ) {
      showToast(
        'Please select a follow-up date and time at least 15 minutes from now.',
        'error'
      );
      return;
    }

    let url = 'cs/offer_remark';
    sendHttpCall(
      'POST',
      url,
      {
        offerId: offerId,
        remark: currentRemark,
        nextFollowUpTime: noFollowUpNeeded ? null : followUpDate
      },
      props
    )
      .then((response) => {
        setCurrentRemark('');
        setFollowUpDate(new Date());
        setNoFollowUpNeeded(false);
        showToast('Remark saved successfully!', 'success');
        fetchOfferDetails(offerId);
      })
      .catch((error) => {
        showToast('Failed to save remark', 'error');
      });
  };

  const showToast = (msg, type = 'success') => {
    setToastMessage({ msg, type });
    setTimeout(() => setToastMessage(''), 2000);
  };

  const renderRemarks = () => {
    if (!offerData || !offerData.offer_remarks.length) return null;

    return (
      <div className="remarks__container">
        <div className="remarks-header">Conversation History</div>
        {offerData.offer_remarks
          .filter((remark) => remark.remark)
          .map((remark, index) => (
            <div key={index} className="remark__item">
              <div className="remark__timestamp">
                {getLocaleTime(remark.date)}
              </div>
              <div className="remark__admin-name">
                {remark.adminName ? (
                  <a href={`/dashboard#/admin-account/${remark.admin_id}`} className="admin-link">
                    {remark.adminName}
                  </a>
                ) : (
                  'Sales Team'
                )}
              </div>
              <div className="remark__text">{remark.remark}</div>
              {remark.nextFollowUpAt && (
                <div className="next-follow-up">
                  Next Follow-up on:{' '}
                  <span className="next-follow-up-date">
                    {getLocaleTime(remark.nextFollowUpAt)}
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      {loading && (
        <div className="active_offers__posts-loading">
          <Loader />
        </div>
      )}

      {!loading && offerData && (
        <div className={styles.container}>
          <h1 className={styles.title}>{offerData.offerType} Offer Details</h1>
          <div className={styles.headerSection}>
            <div className={styles.pfp}>
              <img
                src={offerData.userInfo.image_id}
                alt={offerData.userInfo.name}
              />
            </div>
            <div className={styles.userInfo}>
              <p className={styles.detail}>
                <strong>Posted By: </strong>
                <a href={`/dashboard#/account/${offerData.userId}`} className="admin-link">
                  {offerData.userInfo.name}
                </a>
              </p>
              <p className={styles.detail}>
                <strong>Company: </strong> {offerData.companyName}
              </p>
              {offerData.offerTypeId != 4 && (
                <p className={styles.detail}>
                  <strong>Compensation: </strong> {offerData.displayCompensation}
                </p>
              )}
              {offerData.location_city && (
                <p className={styles.detail}>
                  <strong>Location: </strong> {offerData.location_city}
                </p>
              )}
              {offerData.offerTypeId != 4 && (
                <p className={styles.detail}>
                  <strong>Work Experience: </strong> {offerData.lowerworkex}-
                  {offerData.upperworkex} yrs
                </p>
              )}
              {offerData.offerTypeId != 4 && (
                <p className={styles.detail}>
                  <strong>Type of Work: </strong> {offerData.offer_type}
                </p>
              )}
            </div>
          </div>
          {offerData.skills.length > 0 && (
            <div className={styles.skillCards}>
              <strong>Skills:</strong>
              {offerData.skills?.map((item, index) => (
                <div key={index} className={styles.skillCard}>
                  {item.skill}
                </div>
              ))}
            </div>
          )}
          {offerData.offerTypeId != 4 && (
            <div className={styles.detail}>
              <strong>Description:</strong>
              <div className={styles.value}>{offerData.description}</div>
            </div>
          )}

          {offerData.offerTypeId != 4 && (
            <div className={styles.detail}>
              <strong>Qualification Required: </strong>
              {offerData.highest_qualification_required}
            </div>
          )}
           {offerData?.screeningQuestions.length > 0 && (
            <div className={styles.detail}>
              <strong>Screening Questions:</strong>
              <div className={styles.questions} >
              {offerData.screeningQuestions?.map((questionObj, index) => (
                <div key={index} className={styles.question}>
                  {questionObj.question}
                  {questionObj.isRequired === 1 && (
                    <span className={styles.required}> (Required)</span>
                  )}
                </div>
              ))}
              </div>
            </div>
          )}
          {offerData.offerTypeId === 4 && offerData.interviewAnalytics && (
            <div className={styles.analyticsContainer}>
              <h2 className={styles.analyticsTitle}>Interview Analytics</h2>
              <div className={styles.analyticsMetrics}>
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Total Interviews</span>
                  <span className={styles.metricValue}>
                    {offerData.interviewAnalytics.totalCount}
                  </span>
                </div>
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>Qualified Count</span>
                  <span className={styles.metricValue}>
                    {offerData.interviewAnalytics.qualifiedCount}
                  </span>
                </div>
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>
                    Cheating Detected Count
                  </span>
                  <span className={styles.metricValue}>
                    {offerData.interviewAnalytics.cheatingDetectedCount}
                  </span>
                </div>
                <div className={styles.metricItem}>
                  <span className={styles.metricLabel}>
                    Qualified Percentage
                  </span>
                  <span className={styles.metricValue}>
                    {offerData.interviewAnalytics.qualifiedPercentage}%
                  </span>
                </div>
              </div>
              <div className={styles.statusGroup}>
                <h3>LATEST STATUS</h3>
                <div className={styles.statusList}>
                  {Object.entries(
                    offerData.interviewAnalytics.statusGroup
                  ).map(([status, count]) => (
                    <div key={status} className={styles.statusItem}>
                      <span className={styles.statusLabel}>{status}</span>
                      <span className={styles.statusCount}>{count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {offerData.offerTypeId === 4 && offerData.addOns.length > 0 && (
            <div className={styles.skillCards}>
              <strong>Interview Add-ons:</strong>
              {offerData.addOns?.map((item, index) => (
                <div key={index} className={styles.skillCard}>
                  {item.displayName}
                </div>
              ))}
            </div>
          )}
           {offerData.offerTypeId === 4  && (
            <AssessmentDetails offerId={offerId}/>
           )}
          {renderRemarks()}
          <div className="remark-input-container">
            <label className="remark__admin-name" htmlFor="remarkInput">
              {localStorage.getItem('user_name')} (
              {localStorage.getItem('user_email')})
            </label>
            <div className="remark-input-box">
              <textarea
                id="remarkInput"
                name="remark"
                value={currentRemark}
                onChange={(e) => setCurrentRemark(e.target.value)}
                className="remark-input"
              />
              <button onClick={addRemarkToOffer} className="send-button">
                <SendIcon style={{ color: '#297bca' }} />
              </button>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="follow-up-date-picker"
                label="Next Follow-Up Date & Time"
                format="MM/dd/yyyy HH:mm"
                value={followUpDate}
                onChange={(date) => setFollowUpDate(date)}
                disabled={noFollowUpNeeded}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                InputLabelProps={{ className: 'large-label' }}
              />
            </MuiPickersUtilsProvider>
            <div className="no-follow-up-checkbox">
              <input
                type="checkbox"
                id="noFollowUpNeeded"
                checked={noFollowUpNeeded}
                onChange={(e) => setNoFollowUpNeeded(e.target.checked)}
              />
              <label htmlFor="noFollowUpNeeded">No follow-up needed</label>
            </div>
          </div>
        </div>
      )}

      {toastMessage && (
        <div className={`toast ${toastMessage.type}`}>{toastMessage.msg}</div>
      )}
    </>
  );
};

export default OfferDetail;
