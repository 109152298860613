import React, { useEffect, useState } from 'react';
import {
    Button,
    Slider,
    Typography,
    Collapse,
    Grid,
    TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';
import { sendHttpCall } from 'shareService/httpService';
import CustomSelectWrapper from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/CustomSelectWrapper';
import MaterialTable from 'material-table';

import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const InventoryManagment = () => {

    const [designationData, setDesignationData] = useState('');
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [location, setLocation] = useState('');
    const [keyword, setKeyword] = useState([]);
    const [workExperience, setWorkExperience] = useState(null);
    const [qualification, setQualifications] = useState([]);
    const [compensation, setCompensation] = useState(null);
    const [radius, setRadius] = useState(60);
    const [toastMessage, setToastMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageLoaded, setPageLoaded] = useState(false);

    const [showRecords, setShowRecords] = useState(false);

    const [extraData, setExtraData] = useState(0);
    const [data, setData] = useState([]);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    const [suggestedSkills, setSuggestedSkills] = useState([]);
    const [compensationOptions, setCompensationOptions] = useState({ yearly: [] });

    const [allWorkExperience, setAllWorkExperience] = useState([]);

    const staticConfig = useSelector((state) => state.staticConfig.data);

    const showToast = (msg) => {
        setToastMessage(msg);
    };
    const hideToast = () => {
        setToastMessage('');
    };
    useEffect(() => {
        if (toastMessage !== '') {
            setTimeout(hideToast, 2000);
        }
    }, [toastMessage]);

    useEffect(() => {
        fetchFilteredData();
    }, []);

    const convertToYearly = (monthlyVal) => {
        switch (monthlyVal) {
          case '₹ 10k - 25k':
          case '₹10k - 25k':
            return '₹ 1.2L - 3L';
          case '₹ 25k - 50k':
          case '₹25k - 50k':
            return '₹ 3L - 6L';
          case '₹ 50k - 75k':
            return '₹ 6L - 9L';
          case '₹ 75k - 100k':
            return '₹ 9L - 12L';
          case '₹ 100k - 150k':
            return '₹ 12L - 18L';
          case '₹ 150k - 200k':
            return '₹ 18L - 24L';
          case '₹ 200k - 300k':
            return '₹ 24L - 36L';
          case '₹ 300k - 500k':
            return '₹ 36L - 60L';
          case '>  ₹ 500k':
            return '> ₹ 60L';
          default:
            return monthlyVal;
        }
    };

    const getIdFromCompensation = (value) => {
        switch (value) {
          case '₹ 1.2L - 3L':
            return 1;
          case '₹ 3L - 6L':
            return 2;
          case '₹ 6L - 9L':
            return 3;
          case '₹ 9L - 12L':
            return 4;
          case '₹ 12L - 18L':
            return 5;
          case '₹ 18L - 24L':
            return 6;
          case '₹ 24L - 36L':
            return 7;
          case '₹ 36L - 60L':
            return 8;
          case '> ₹ 60L':
            return 9;
          case '₹ 100 - 200':
            return 10;
          case '₹ 200 - 350':
            return 11;
          case '₹ 350 - 500':
            return 12;
          case '₹ 500 - 700':
            return 13;
          case '₹ 700 - 1000':
            return 14;
          case '₹ 1k - 1.5k':
            return 15;
          case '₹ 1.5k - 2k':
            return 16;
          case '₹ 2k - 3k':
            return 17;
          case '₹ 3k - 5k':
            return 18;
          case '> ₹ 5k':
            return 19;
          default:
            return null;
        }
    };

    const getCompensationFromIdNew = (id) => {
        if (typeof id !== 'number') {
          // eslint-disable-next-line no-param-reassign
          id = getIdFromCompensation(convertToYearly(id));
        }
        switch (id) {
          case 1:
            return '1.2-3 LPA';
          case 2:
            return '3-6 LPA';
          case 3:
            return '6-9 LPA';
          case 4:
            return '9-12 LPA';
          case 5:
            return '12-18 LPA';
          case 6:
            return '18-24 LPA';
          case 7:
            return '24-36 LPA';
          case 8:
            return '36-60 LPA';
          case 9:
            return '>60 LPA';
          case 10:
            return '₹ 100 - 200';
          case 11:
            return '₹ 200 - 350';
          case 12:
            return '₹ 350 - 500';
          case 13:
            return '₹ 500 - 700';
          case 14:
            return '₹ 700 - 1000';
          case 15:
            return '₹ 1k - 1.5k';
          case 16:
            return '₹ 1.5k - 2k';
          case 17:
            return '₹ 2k - 3k';
          case 18:
            return '₹ 3k - 5k';
          case 19:
            return '> ₹ 5k';
          default:
            return '';
        }
    };

    useEffect(() => {
        if(staticConfig.loaded) {
            const yearly = staticConfig?.monthlyMaster.map((a) => ({
                label: getCompensationFromIdNew(a.id),
                value: a.id
            }));
            const hourly = staticConfig?.hourlyMaster.map((a) => ({
                label: a.value,
                value: a.id
            }));
            const yearlyUsd = staticConfig?.monthlyMaster.map((a) => ({
                label: a.value_usd,
                value: a.id
            }));
            setCompensationOptions({ yearly, hourly, yearlyUsd });
        
            setAllWorkExperience(staticConfig.workExperienceRange.map((a) => ({ ...a, label: a.upperworkex >= 60 ? `More than ${a.upperworkex} Years` : `${a.lowerworkex}-${a.upperworkex} Years` })))
        
        }
    }, [staticConfig]);

    const fetchFilteredData = () => {
        const data1 = {
            completeDB:true,
            disableChecks:true,
            pagenumber: page,
            size: limit,
            disableOmitCheck: true,
        }

        if(location) {
            data1.latitude = location.latitude;
            data1.longitude = location.longitude;
            data1.city = location.label;
            data1.maxDistance = radius*1000;
        }
        if(selectedSkills && selectedSkills.length > 0) {
            const skillIdArray = selectedSkills.map((a) => a.value);
            data1.skills = skillIdArray;
        }

        if(designationData) {
            data1.jobtype = [designationData?.value]
        }

        if(keyword.length > 0) {
            data1.designations = keyword
        }

        if(workExperience) {
            data1.lowerworkex = workExperience.lowerworkex;
            data1.upperworkex = workExperience.upperworkex;
        }

        if(qualification.length > 0) {
            data1.highestQualificationIds = qualification.map((a) => a.id);
        }

        if(compensation) {
            data1.monthlyCompensation = compensation.value
        }


        setLoading(true);
        sendHttpCall("POST", "inventory/filtered", data1)
            .then((res) => {
                setExtraData(res?.extraData?.data);
                if(res?.extraData?.data === 0 && pageLoaded) {
                    showToast("No Records Found!!!");
                }
                setPageLoaded(true);
                setData(res?.data);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
            })
    }

    const addSkill = (item) => {
        console.log(item);
        const selectedSkills_ = [...selectedSkills];
        const index = selectedSkills_.findIndex((a) => a.value === item.value);
        if (index < 0)
          setSelectedSkills((a) => [
            { ...item, selected: true, popular: false },
            ...a
          ]);
        else {
          selectedSkills_[index] = { ...selectedSkills_[index], selected: true };
          setSelectedSkills(selectedSkills_);
        }
    };

    const removeSkill = (item) => {
        const selectedSkills_ = [...selectedSkills];
        const index = selectedSkills_.findIndex((a) => a.value === item.value);
        if (index < 0) return;
        if (selectedSkills_[index].popular) {
            selectedSkills_[index] = { ...selectedSkills_[index], selected: false };
            setSelectedSkills(selectedSkills_);
        } else setSelectedSkills((a) => a.filter((b) => b.value !== item.value));
    };

    const handleChangeRange = (event, newVal) => {
        setRadius(newVal);
    }

    useEffect(() => {
        fetchFilteredData();
    }, [page, limit]);

    useEffect(() => {
        setPage(1);
    }, [location, selectedSkills, designationData, radius]);
    
    const handleClearFilter = () => {
        setLocation('');
        setSelectedSkills([]);
        setDesignationData('');
        setWorkExperience(null);
        setQualifications([]);
        setCompensation(null);
        setKeyword([]);
        setPage(1);
        setShowRecords(false);
    }

    const getAllSuggestedSkills = () => {
        sendHttpCall("GET", `designations/related/${designationData.value}`)
            .then((res) => {
                setSuggestedSkills(res.data);
            })
    }

    const [inputValue, setInputValue] = useState('');

    const handleAddKeyword = (event) => {
        if (event.key === 'Enter') {
            let flag = false;
            for(let i = 0; i < keyword.length; i += 1) {
                if(keyword[i] === inputValue) {
                    flag = true;
                    break;
                }
            }
            if(!flag) {
                setKeyword(prev => [...prev, inputValue]);
            }
            setInputValue('');
        }
    };

    const handleRemoveKeyWord = (keyw) => {
        setKeyword(prev => prev.filter((a) => a !== keyw));
    }

    useEffect(() => {
        if(designationData) getAllSuggestedSkills();
    }, [designationData])

    const addQualification = (qual) => {
        let flag = false;
        for(let i = 0; i < qualification.length; i+=1) {
            if(qualification[i].id === qual.id) {
                flag = true;
                break;
            }
        }
        if(!flag) {
            setQualifications(prev => [...prev, qual]);
        }
    }

    const removeQualification = (qualID) => {
        setQualifications(prev => prev.filter((option) => option.id !== qualID));
    }

    return (
        <div className={styles.InventoryManagment}>
            <Typography variant="h1" className={styles.header}>
                GoodDex
            </Typography>
            <div className={styles.mainContent}>
                <div className={styles.filterDivs}>
                    <div className={styles.FilterField}>
                        <Typography variant='h6' className={styles.topCont}>
                            Job Title
                            <Button
                                variant="contained"
                                color="default"
                                onClick={handleClearFilter}
                            >Clear Filter</Button>
                        </Typography>
                        <CustomSelectWrapper
                            theme="INVENTORY"
                            type="designation"
                            value={designationData}
                            className={styles.select}
                            onChange={(e) => {
                                setDesignationData(e);
                            }}
                            placeholder="eg Full Stack Developer"
                        />
                    </div>
                    { (suggestedSkills.length > 0 && designationData) && (
                        <div className={styles.FilterField}>
                            <Typography variant='h6' className={styles.topCont}>
                                Suggested Skills
                            </Typography>
                            <div className={styles.skillList}>
                                {suggestedSkills.map((item) => (
                                    <div
                                        key={item.skill_id}
                                        className={`${styles.formCard} ${
                                            item.selected ? styles.formCardActive : ''
                                        }`}
                                        onClick={() => {
                                            // if (item.selected) removeSkill(item);
                                            addSkill({ value: item.skill_id, label: item.skill });
                                        }}
                                    >
                                        {item.skill}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className={styles.FilterField}>
                        <Typography variant='h6'>
                            Skill
                        </Typography>
                        { selectedSkills.length > 0 && (
                            <div className={styles.formCards}>
                                {selectedSkills.map((item) => (
                                    <div
                                        className={`${styles.formCard} ${
                                            item.selected ? styles.formCardActive : ''
                                        }`}
                                        onClick={() => {
                                            if (item.selected) removeSkill(item);
                                            else addSkill(item);
                                        }}
                                    >
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                        )}
                        <CustomSelectWrapper
                            theme="INVENTORY"
                            type="skill"
                            className={styles.select}
                            onChange={(e) => {
                                addSkill(e);
                            }}
                            placeholder="eg Java"
                        />
                    </div>
                    <div className={styles.FilterField}>
                        <Typography variant='h6'>
                            Location
                        </Typography>
                        <CustomSelectWrapper
                            placeholder="eg Delhi"
                            async
                            theme="INVENTORY"
                            onChange={(e) => {
                                setLocation(e);
                                // handleTimeChange({ target: { value: 'All time' } });
                            }}
                            value={location}
                            type="location"
                        />
                    </div>
                    { location && (
                        <div>
                            <Typography variant='h6'>
                                Location range (km)
                            </Typography>
                            <div style={{ marginTop: '30px' }}>
                                <Slider min={50} max={100} step={10} value={radius} onChange={handleChangeRange} valueLabelDisplay="on" />
                            </div>
                        </div>
                    )}
                    <div>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowAdvancedFilters(prev => !prev)}
                        startIcon={showAdvancedFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        Advanced Filters
                    </Button>
                    </div>
                    <Collapse in={showAdvancedFilters}>
                        <Grid container spacing={2} alignItems="flex-end" className={styles.filterDivs}>
                            <Grid className={styles.FilterField} item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant='h6'>
                                    Boolean Search
                                </Typography>
                                {keyword.length > 0 &&
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        { keyword.map((a) => (
                                            <div
                                                className={`${styles.formCard} ${styles.formCardActive}`}
                                                onClick={() => handleRemoveKeyWord(a)}
                                            >
                                                {a}
                                            </div>
                                        ))} 
                                    </div>
                                }
                                <input
                                    placeholder="Enter Keywords"
                                    value={inputValue}
                                    className={styles.inputFields}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleAddKeyword}
                                />
                            </Grid>
                            <Grid className={styles.FilterField} item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant='h6'>
                                    Work Experience
                                </Typography>
                                <Select
                                    options={allWorkExperience}
                                    formatOptionLabel={(option) => (
                                        <div  style={{ display: 'flex', alignItems: 'center' }}>
                                            {option.label}
                                        </div>
                                    )}
                                    value={workExperience}
                                    onChange={(option) => setWorkExperience(option)}
                                    isSearchable={false}
                                    className={styles.select}
                                    placeholder="Select Work Experience"
                                />
                            </Grid>
                            <Grid className={styles.FilterField} item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant='h6'>
                                    Annual Compensation
                                </Typography>
                                <Select
                                    options={compensationOptions.yearly}
                                    formatOptionLabel={(option) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            { option.label }
                                        </div>
                                    )}
                                    value={compensation}
                                    onChange={(option) => {
                                        setCompensation(option)
                                    }}
                                    isSearchable={false}
                                    className={styles.select}
                                    placeholder="Select Compensation"
                                />
                            </Grid>
                            <Grid className={styles.FilterField} item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant='h6'>
                                    Qualification
                                </Typography>
                                <Select
                                    options={staticConfig.highestQualification}
                                    formatOptionLabel={(option) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            { option.value }
                                        </div>
                                    )}
                                    value={''}
                                    onChange={(option) => addQualification(option)}
                                    isSearchable={false}
                                    className={styles.select}
                                    placeholder="Select Qualification"
                                />
                                <div style={{ display: 'flex', flexWrap: "wrap", gap: '10px' }}>
                                    { qualification.map((a) => (
                                        <div className={`${styles.formCard} ${styles.formCardActive}`} onClick={() => removeQualification(a.id)}>{a.value} <CloseIcon style={{ height: '15px' }} /></div>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Collapse>
                    <div className={styles.buttonGrp}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={fetchFilteredData}
                            fullWidth
                            className={styles.goButton}
                            disabled={!location && selectedSkills.length === 0 && !designationData && !workExperience && !compensation && qualification.length === 0 && keyword.length === 0}
                        >
                            Search
                        </Button>
                    </div>
                    { extraData !== 0 && (
                        <div className={styles.TotalEntriesInfo} onClick={() => setShowRecords(prev => !prev)}>
                            Count: {(extraData || 0).toLocaleString()}
                        </div>
                    )}
                </div>
                { showRecords && (
                    <MaterialTable
                        isLoading={loading}
                        columns={[
                            {
                                title: 'S No.',
                                cellStyle: {
                                position: 'sticky',
                                left: 0,
                                backgroundColor: '#FFF'
                                },
                                headerStyle: {
                                position: 'sticky',
                                left: 0,
                                backgroundColor: '#FFF'
                                },
                                render: (rowData) => (page-1) * limit + rowData.tableData.id + 1
                            },
                            {
                                title: 'Name',
                                field: 'name',
                                cellStyle: {
                                    position: 'sticky',
                                    left: 40,
                                    backgroundColor: '#FFF'
                                },
                                headerStyle: {
                                    position: 'sticky',
                                    left: 40,
                                    backgroundColor: '#FFF'
                                },
                                render: (rowData) => (
                                    <Link
                                        style={{
                                            color: '#297bca',
                                            fontWeight: '500',
                                            cursor: 'pointer',
                                        }}
                                        to={`/account/${rowData.user_id}?type=SOCIAL`}
                                        target="_blank"                                        
                                    >
                                      {rowData.name}
                                    </Link>
                                )
                            },
                            {
                                title: "Location",
                               field: "location_city" 
                            }
                        ]}
                        data={data}
                        totalCount={extraData}
                        page={page-1}
                        title=""
                        onChangePage={(e) => setPage(e+1)}
                        onChangeRowsPerPage={(e) => setLimit(e)}
                        options={{
                            search: true,
                            actionsColumnIndex: -1,
                            pageSize: limit,
                            pageSizeOptions: [10, 20, 30, 40, 50],
                            sorting: true,
                            columnsButton: true,
                            showFirstLastPageButtons: false
                        //  exportButton: localStorage.getItem('user_type') === 'SALES' ? false : true
                        }}
                    />
                )}
                {toastMessage && (
                    <div className="jobs_under_review__toast">{toastMessage}</div>
                )}
            </div>
        </div>
    )
}

export default InventoryManagment