const buildNavigation = (routes, allowedRouteIds) => {
    const authorizedRoutes = routes.filter(route => 
      allowedRouteIds.includes(route.routeId) || route.public
    );

    const groupedRoutes = authorizedRoutes.reduce((acc, route) => {
      if (route.parentGroupId) {
        if (!acc[route.parentGroupId]) {
          acc[route.parentGroupId] = [];
        }
        acc[route.parentGroupId].push({
          id: route.routeId,
          name: route.name || '',
          path: route.path,
          icon: route.icon || '',
          meta: route.meta || null,
          component: route.component
        });
      }
      return acc;
    }, {});
  
    const groups = {
      offer: { id: 'offers', name: "Offers", icon: 'Dashboard' },
      main: { id: 'group_main', name: 'Main Pages', icon: 'Dashboard' },
      sales: { id: 'sales_main', name: 'Sales CRM', icon: 'Dashboard' },
      marketing: { id: 'marketing_main', name: 'Marketing', icon: 'Dashboard' },
      payment: { id: 'payment_main', name: 'Payment Services', icon: 'Dashboard' },
      social: { id: 'social_main', name: 'Social Experiences Posts', icon: 'Dashboard' },
      jobs: { id: 'job_main', name: 'Job Posts', icon: 'Dashboard' },
      pitch: { id: 'pitch_main', name: 'Pitch', icon: 'Dashboard' },
      intro: { id: 'intro_main', name: 'Intro', icon: 'Dashboard' },
      promo: { id: 'promo_main', name: 'Promo Code', icon: 'Dashboard' },
      cards: { id: 'cards_main', name: 'Cards', icon: 'ViewAgenda' },
      users: { id: 'users_main', name: 'Users', icon: 'People' }
    };
  
    const navigation = Object.entries(groups).reduce((acc, [key, group]) => {
      if (groupedRoutes[group.id]?.length > 0) {
        acc[key] = {
          ...group,
          items: groupedRoutes[group.id] || []
        };
      }
      return acc;
    }, {});
  
    const publicRoutes = authorizedRoutes.filter(route => route.public);
    const standaloneRoutes = authorizedRoutes.filter(route => !route.parentGroupId && !route.public);
  
    return {
      navigation,
      publicRoutes,
      standaloneRoutes
    };
};
  
export default buildNavigation;