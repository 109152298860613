import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import MaterialTable from 'material-table';
import ReactModal from 'react-modal';
import { sendHttpCall } from 'shareService/httpService';

const ManageRoutes = () => {
    const [addRoute, setAddRoute] = useState(false);
    const [currentRoutes, setCurrentRoutes] = useState([]);
    const [page, setPage] = useState(0);

    const [addRouteData, setAddRouteData] = useState({
        type: '',
        routeName: '',
        routePath: '',
        parentComponent: '',
        routeId: "",
    });

    const handleClose = () => {
        setAddRouteData({
            type: '',
            routeName: '',
            routePath: '',
            parentComponent: '',
            routeId: "",
        });
        setAddRoute(false);
    }

    const getRoutesData = async () => {
        const response = await sendHttpCall(
            'GET',
            'routes',
            null,
            null
        );

        setCurrentRoutes(response.data);    
    }

    useEffect(() => {
        getRoutesData();
    }, []);

    const handleAddRoute = async () => {
        handleClose();
        await sendHttpCall(
            'POST',
            'routes/addRoute',
            {  
                type: addRouteData.type,
                routeId: addRouteData.routeId,
                name: addRouteData.routeName,
                href: addRouteData.routePath,
                parentGroupId: addRouteData.parentComponent
            },
            null
        ).then((resp) => {
            console.log(resp);
            set
        })
        .catch((err) => {
            console.log(err);
        })

    }

    return (
        <>
            <div className={styles.MainContainer}>
                <div className={styles.header}>
                    <h1>Manage Routes</h1>
                    <Button startIcon={<Add />} color='primary' variant='contained' type='button' onClick={() => setAddRoute(true)}>
                        Create Route
                    </Button>
                </div>
                <MaterialTable
                    title='Active Admin Users'
                    columns={[
                        {
                            title: 'S No.',
                            cellStyle: {
                              position: 'sticky',
                              left: 0,
                              backgroundColor: '#FFF'
                            },
                            headerStyle: {
                              position: 'sticky',
                              left: 0,
                              backgroundColor: '#FFF'
                            },
                            render: (rowData) => page + rowData.tableData.id + 1
                        },
                        { title: 'Route Id', field: 'routeId', type: 'string', align: 'left' },
                        { title: 'Route Name', field: 'name', type: 'string', align: 'left' },
                        { title: 'Route Path', field: 'href', type: 'string', align: 'left' },
                        { 
                            title: 'Route Type', 
                            render: (rowData) => {
                                return (
                                    <div>
                                        <Chip label={rowData.type} color={rowData.type === "group" ? 'default' : 'secondary'} />
                                    </div>
                                );
                            }
                        },
                        { title: 'Parent Component', field: 'parentGroupId', type: 'string', align: 'left' },
                    ]}
                    data={currentRoutes}
                    page={page}
                    onChangePage={(e) => {
                      console.log('Changing page - ' + e);
                      setPage(e);
                    }}
                    onChangeRowsPerPage={(e) => setLimit(e)}
                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10],
                        sorting: false
                    }}
                />
            </div>
            <ReactModal
                isOpen={addRoute}
                onRequestClose={handleClose}
                className="jobs_under_review__modal-modal"
                overlayClassName="jobs_under_review__modal-overlay"
            >
                <div
                    className={styles.addRouteModal}
                >
                    <Typography variant='h4' className={styles.modalTitle}>
                        Add Route
                    </Typography>
                    <div className={styles.addRouteForm}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Route Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Route Type"
                                variant='standard'
                                MenuProps={{
                                    style: { zIndex: 9999, top: 50 },
                                }}
                                value={addRouteData.type}
                                onChange={(event) => setAddRouteData((prev) => ({ ...prev, type: event.target.value}))}
                            >
                                <MenuItem value="group">Group</MenuItem>
                                <MenuItem value="page">Page</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Route Id"
                                value={addRouteData.routeID}
                                onChange={(event) => setAddRouteData((prev) => ({ ...prev, routeId: event.target.value}))}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Route Name"
                                value={addRouteData.routeName}
                                onChange={(event) => setAddRouteData((prev) => ({ ...prev, routeName: event.target.value}))}
                            />
                        </FormControl>
                        { addRouteData.type === 'page' && (
                            <FormControl>
                                <TextField
                                    variant="outlined"
                                    label='Route Path'
                                    value={addRouteData.routePath}
                                    onChange={(event) => setAddRouteData((prev) => ({ ...prev, routePath: event.target.value}))}
                                />
                            </FormControl>
                        )}
                        { addRouteData.type === 'page' && (
                            <FormControl>
                                <TextField
                                    variant="outlined"
                                    label='Parent Compoent'
                                    value={addRouteData.parentComponent}
                                    onChange={(event) => setAddRouteData((prev) => ({ ...prev, parentComponent: event.target.value}))}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <Button color='primary' disabled={!addRouteData.type || !addRouteData.routeName || !addRouteData.routeId} variant='contained' type='button' onClick={handleAddRoute}>
                                Add Route
                            </Button>
                        </FormControl>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default ManageRoutes