import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Axios from 'axios';
import moment from 'moment';
import { sendHttpCall, sendHttpCallBlob } from 'shareService/httpService';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import MaterialTable, { MTablePagination } from 'material-table';
import ReactSelect from 'react-select';
import { selectYesterday } from '../../../../config';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { useParams } from 'react-router-dom';
import { getDate } from 'date-fns';
import CustomSelectWrapper from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/CustomSelectWrapper';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

let timeoutId;
const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY'; // prod

// let cache = useSelector((state) => state.staticConfig);
const UserSearch = (props) => {
  const mounted = useRef(false);
  // const [staticConfig, setStaticConfig] = useState(null);

  const staticConfig = useSelector((state) => state.staticConfig.data);

  // useEffect(() => {
  //   if(cache.loaded) setStaticConfig(cache);
  // }, [cache]);
  // const getStaticData = async () => {
  //   if (cache) setStaticConfig(cache);
  //   else {
  //     const data = await fetchSpecificConfigApi();
  //     setStaticConfig(data.data.data);
  //   }
  // };

  // const fetchSpecificConfigApi = async (url) => {
  //   let data = await Axios.get(
  //     'https://api.ourgoodspace.com/api/d2/config/staticConfig',
  //     {
  //       headers: {
  //         authorization: notLoggedInTokenProd
  //       }
  //     }
  //   );
  //   cache = data.data.data;
  //   return data;
  // };
  const start = new Date();
  const startInitial = new Date(2019, 1, 1);
  const end = new Date();
  var reqBody = {
    start_date: moment(new Date(start.setDate(start.getDate()))).format(
      'YYYY-MM-DD'
    ),
    end_date: moment(new Date(end.setDate(end.getDate()))).format('YYYY-MM-DD')
  };
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    localStorage.getItem('social-services-startDate')
      ? localStorage.getItem('social-services-startDate')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    localStorage.getItem('social-services-endDate')
      ? localStorage.getItem('social-services-endDate')
      : moment(new Date()).format('YYYY-MM-DD')
  );
  const [filterVal, setFilterVal] = useState(
    localStorage.getItem('social-services-filterVal')
      ? localStorage.getItem('social-services-filterVal')
      : null
  );
  const [okdate, setOkdate] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [seletedPeriod, setSeletedPeriod] = useState(
    localStorage.getItem('social-services-selectedPeriodUserSearch')
      ? localStorage.getItem('social-services-selectedPeriodUserSearch')
      : 'Today'
  );
  const [goToPageValue, setGoToPageValue] = useState('');
  const [filter, setFilter] = useState(
    localStorage.getItem('social-services-filter')
      ? localStorage.getItem('social-services-filter')
      : null
  );
  const [users, setUsers] = useState([]);
  const [filterByName, setFilterByName] = useState(
    localStorage.getItem('searchData') ? true : false
  );
  const [sortingData, setSortingData] = useState(
    localStorage.getItem('sortingData')
      ? localStorage.getItem('sortingData')
      : 'registrationDate'
  );
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearch] = useState(
    localStorage.getItem('searchData') ? localStorage.getItem('searchData') : ''
  );
  const [filterDefault, setFilterDefault] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(
    localStorage.getItem('social-services-pageNo')
      ? localStorage.getItem('social-services-pageNo')
      : 0
  );
  const [data, setData] = useState([]);
  const [ageFilter, setAgeFilter] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (seletedPeriod !== 'All time' && sortingData) {
      setSortingData('');
    }
  }, [seletedPeriod]);
  useEffect(() => {
    console.log(selectedStartDate);
    if (
      ('' + selectedStartDate).includes(' ') ||
      ('' + selectedEndDate).includes(' ')
    ) {
      return;
    }
    getData();
  }, [page, selectedStartDate, selectedEndDate, ageFilter, location?.value]);

  useEffect(() => {
    console.log(mounted.current);
    if (!mounted.current) return;
    if (page === 0) getData();
    else setPage(0);
  }, [filter]);

  useEffect(() => {
    mounted.current = true;
    // getStaticData();
  }, []);
  const goToPage = (val) => {
    setPage(val - 1);
  };

  const onPageChange = (i) => {
    // console.log("page changed")
    // localStorage.setItem('social-services-pageNo',i);
    setPage(i);
  };
  const getData = () => {
    // console.log("unsorted")
    let pageNum = page;
    setLoading(true);
    let url =
      'dashboard/dashboard_get_volunteer_data_by_type?' +
      'limit=' +
      20 +
      '&page=' +
      pageNum +
      `&type=${
        props.match.params.type
      }&start_date=${selectedStartDate.toString()}&end_date=${selectedEndDate.toString()}&filter=${
        filter ? filter : ''
      }&latitude=${location?.latitude || ''}&longitude=${
        location?.longitude || ''
      }
      `;
    sendHttpCall('GET', url, null, props).then((response) => {
      setData(response.data);
      setTotalCount(response.count);
      setLoading(false);
    });
  };

  const handleTimeChange = (event) => {
    let startdate = new Date();
    let enddate = new Date();
    if (event.target.value !== 'All time') setLocation(null);
    switch (event.target.value) {
      case 'All time':
        setSeletedPeriod('All time');
        setIsCustomDate(false);
        // reqBody.start_date = moment(new Date(start)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date()).format('YYYY-MM-DD');
        //   fetchAllData();
        //startInitial
        setSelectedStartDate(
          moment(new Date(startInitial)).format('YYYY-MM-DD')
        );
        setSelectedEndDate(moment(new Date()).format('YYYY-MM-DD'));

        break;
      case 'Last Year':
        setSeletedPeriod('Last Year');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 365);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //   fetchAllData();
        break;
      case 'Last 30 days':
        setSeletedPeriod('Last 30 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 30);
        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        //  fetchAllData();
        break;
      case 'Last 7 days':
        setSeletedPeriod('Last 7 days');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 7);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        // reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');
        // fetchAllData();
        break;
      case 'Yesterday':
        setSeletedPeriod('Yesterday');
        setIsCustomDate(false);
        startdate.setDate(startdate.getDate() - 1);
        enddate.setDate(enddate.getDate() - 1);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Today':
        setSeletedPeriod('Today');
        setIsCustomDate(false);
        reqBody.start_date = moment(new Date(startdate)).format('YYYY-MM-DD');
        reqBody.end_date = moment(new Date(enddate)).format('YYYY-MM-DD');

        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));

        // fetchAllData();
        break;
      case 'Custom Date':
        setSeletedPeriod('Custom Date');
        setIsCustomDate(true);
        setSelectedStartDate(moment(new Date(startdate)).format('YYYY-MM-DD'));
        setSelectedEndDate(moment(new Date(enddate)).format('YYYY-MM-DD'));
      //
    }
    setPage(0);
  };
  const handleChangeStartDate = (event) => {
    setSelectedStartDate(event);
    // var d = new Date(event);
    if (event < selectedEndDate) {
      setSelectedStartDate(event);
      setOkdate(true);
    }
  };

  const handleChageEndDate = (event) => {
    if (event < selectedStartDate) {
      // toast('You have selected date greater than End date');
      setOkdate(false);
    } else {
      setSelectedEndDate(event);
      setOkdate(true);
    }
  };

  const filterByDateRange = () => {
    setSelectedStartDate(
      moment(new Date(selectedStartDate)).format('YYYY-MM-DD')
    );
    setSelectedEndDate(moment(new Date(selectedEndDate)).format('YYYY-MM-DD'));
  };

  const getLocaleTime = (time) => {
    if (time) return new Date(time).toLocaleString();
    else return '';
  };
  return (
    <div className={classes.root}>
      <div style={{ justifyContent: 'flex-start' }} className="flex">
        <div>
          <div className="sm-width">
            <Typography variant="body2" color="textSecondary" component="p">
              SELECT PERIOD
            </Typography>
          </div>
          <div className="sm-width">
            <TextField
              fullWidth
              // label="Select Period"
              margin="dense"
              name="status"
              onChange={handleTimeChange}
              required
              select
              SelectProps={{ native: true }}
              value={seletedPeriod}
              variant="outlined"
              // placeholder="select period"
            >
              {/* <option value={""} > All time </option> */}
              {selectYesterday.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
        </div>

        {isCustomDate === true ? (
          <Grid container justify="space-around">
            <Grid xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="SELECT START DATE"
                      format="MM/dd/yyyy"
                      value={selectedStartDate}
                      onChange={handleChangeStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <KeyboardDatePicker
                      format="MM/dd/yyyy"
                      id="date-picker-dialog"
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      label="SELECT END DATE"
                      margin="normal"
                      onChange={handleChageEndDate}
                      value={selectedEndDate}
                    />
                  </Grid>
                  <Grid xs={2}>
                    {okdate === false ? null : (
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.srch_btn}
                        onClick={filterByDateRange}>
                        SEARCH
                      </Button>
                    )}
                  </Grid>
                  <Grid xs={2}></Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        ) : null}
        {!isCustomDate && (
          <div style={{ marginLeft: '1rem' }}>
            <div className="sm-width">
              <Typography variant="body2" color="textSecondary" component="p">
                SELECT FILTER
              </Typography>
            </div>
            <div className="sm-width">
              <TextField
                fullWidth
                // label="Select Period"
                margin="dense"
                name="status"
                onChange={(e) => {
                  console.log(e.target.value);
                  setFilterVal(e.target.value);
                  if (e.target.value === 'None') {
                    setFilter(null);
                    return;
                  }
                  const data =
                    props.match.params.type === 'donate-blood'
                      ? staticConfig?.bloodGroup
                      : props.match.params.type === 'donate-money'
                      ? staticConfig?.donationAmount
                      : staticConfig?.volunteerTime;

                  setFilter(
                    data.find((item) => item.value === e.target.value).id
                  );
                }}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={filterVal}
                variant="outlined"
                // placeholder="select period"
              >
                {/* <option value={""} > All time </option> */}
                <option value="None">None</option>
                {(props.match.params.type === 'donate-blood'
                  ? staticConfig?.bloodGroup
                  : props.match.params.type === 'donate-money'
                  ? staticConfig?.donationAmount
                  : staticConfig?.volunteerTime
                )?.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
        )}
        {!isCustomDate && (
          <div style={{ marginLeft: '1rem', width: '15rem' }}>
            <div>
              <Typography variant="body2" color="textSecondary" component="p">
                SELECT LOCATION
              </Typography>
            </div>
            <div style={{ fontFamily: 'Roboto' }}>
              <CustomSelectWrapper
                placeholder="eg Delhi"
                async
                theme="ONBOARDING_HIRER"
                onChange={(e) => {
                  setLocation(e);
                  handleTimeChange({ target: { value: 'All time' } });
                }}
                value={location}
                type="location"
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex">
        {isCustomDate === true && (
          <div style={{ marginTop: '1rem' }}>
            <div className="sm-width">
              <Typography variant="body2" color="textSecondary" component="p">
                SELECT FILTER
              </Typography>
            </div>
            <div className="sm-width">
              <TextField
                fullWidth
                // label="Select Period"
                margin="dense"
                name="status"
                onChange={(e) => {
                  console.log(e.target.value);
                  setFilterVal(e.target.value);
                  if (e.target.value === 'None') {
                    setFilter(null);
                    return;
                  }
                  const data =
                    props.match.params.type === 'donate-blood'
                      ? staticConfig?.bloodGroup
                      : props.match.params.type === 'donate-money'
                      ? staticConfig?.donationAmount
                      : staticConfig?.volunteerTime;

                  setFilter(
                    data.find((item) => item.value === e.target.value).id
                  );
                }}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={filterVal}
                variant="outlined"
                // placeholder="select period"
              >
                {/* <option value={""} > All time </option> */}
                <option value="None">None</option>
                {(props.match.params.type === 'donate-blood'
                  ? staticConfig?.bloodGroup
                  : props.match.params.type === 'donate-money'
                  ? staticConfig?.donationAmount
                  : staticConfig?.volunteerTime
                )?.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.value}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
        )}
      </div>
      <div className={classes.content}>
        <MaterialTable
          columns={[
            {
              title: 'S No.',
              cellStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              headerStyle: {
                position: 'sticky',
                left: 0,
                backgroundColor: '#FFF'
              },
              render: (rowData) => page * 20 + rowData.tableData.id + 1
            },
            {
              title: 'Name',
              field: 'name',
              cellStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },

              headerStyle: {
                position: 'sticky',
                left: 40,
                backgroundColor: '#FFF'
              },
              render: (rowData) => {
                return (
                  <div
                    style={{
                      cursor: 'pointer',
                      // fontWeight: 500,
                      textTransform: 'capitalize',
                      // color: '#297bca'
                      // fontSize: '.85rem',
                      fontFamily: 'Roboto',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => {
                      localStorage.setItem(
                        'social-services-startDate',
                        selectedStartDate
                      );
                      localStorage.setItem(
                        'social-services-endDate',
                        selectedEndDate
                      );
                      localStorage.setItem('social-services-filter', filter);
                      localStorage.setItem(
                        'social-services-filterVal',
                        filterVal
                      );
                      localStorage.setItem(
                        'social-services-selectedPeriodUserSearch',
                        seletedPeriod
                      );
                      // console.log(page)
                      localStorage.setItem('social-services-pageNo', page);
                      props.history.push(`/account/${rowData.user_id}`);
                    }}>
                    {rowData.name}
                  </div>
                );
              }
            },

            {
              title: 'Mobile Number',
              field: 'mobile_number'
            },

            {
              title: 'Email Id',
              field: 'email_id'
            },
            {
              title:
                props.match.params.type === 'donate-blood'
                  ? 'Blood Group'
                  : props.match.params.type === 'donate-money'
                  ? 'Donation Amount'
                  : 'Time they want to spend',
              field: 'value'
            },
            {
              title: 'Location',
              field: 'location_city'
            },
            {
              title: 'Joined On',
              field: 'joined_on'
            }
          ]}
          data={data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20],
            // pageSizeOptions: [5, 10, 20, 30],
            sorting: false
          }}
          title={
            props.match.params.type === 'donate-blood'
              ? 'Want to Donate Blood'
              : props.match.params.type === 'donate-money'
              ? 'Want to Donate Money'
              : 'Want to work for Charity'
          }
          page={page}
          isLoading={loading}
          onChangePage={setPage}
          totalCount={totalCount}
          style={{ zIndex: 0 }}
        />
        {/* <UsersTable users={users}/> */}
      </div>
    </div>
  );
};

export default UserSearch;

const GoToPageComponent = ({ goToPage }) => {
  const [val, setVal] = useState('');
  const goToPage_ = () => {
    if (!isNaN(parseInt(val)) && parseInt(val) !== 0) goToPage(parseInt(val));
  };
  return (
    <>
      {' '}
      <div className="table__pagination-goToPage-desc">Go To Page</div>
      <div className="table__pagination-goToPage">
        <input
          type="text"
          value={val}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              goToPage_();
            }
          }}
          onChange={(e) => setVal(e.target.value)}
        />
        <button className="table__pagination-goToPage-btn" onClick={goToPage_}>
          Go
        </button>
      </div>
    </>
  );
};
